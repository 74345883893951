import {JsonDeserializable} from '../../common/crud-common/crudItem';

@JsonDeserializable
export class SubsystemSelectionItem {
    id: number;
    name: string;
    subsystemExpirationDate?: Date;
    impersonationAllowed?: boolean;
    selected: boolean;

    static fromJSON(jsonObject: any): SubsystemSelectionItem {
        const subsystem = new SubsystemSelectionItem();
        subsystem.id = jsonObject.id;
        subsystem.name = jsonObject.name;
        if (jsonObject.subsystemExpirationDate != undefined) {
            subsystem.subsystemExpirationDate = new Date(jsonObject.subsystemExpirationDate);
        }
        subsystem.impersonationAllowed = jsonObject.impersonationAllowed;
        subsystem.selected = false;
        return subsystem;
    }
}
