import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {MultilanguageField, SupportedLanguages} from '../../../../supportedLanguages';
import {WindowSystemDefinition} from '../window-system-definition';

@Component({
    selector: 'app-window-system-definition-print-info-form',
    templateUrl: './window-system-definition-print-info-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionPrintInfoFormComponent implements OnChanges {

    @Input()
    windowSystem: WindowSystemDefinition;

    @Input()
    disabled: boolean;

    readonly supportedLanguages = SupportedLanguages.languages;

    optionalOfferPrintHeaderTabSelected = false;

    htmlText: MultilanguageField;

    ngOnChanges(changes: SimpleChanges) {
        const windowSystem = changes['windowSystem'];
        if (windowSystem != undefined) {
            this.htmlText = new MultilanguageField();
            for (let lang of this.supportedLanguages) {
                if (windowSystem.currentValue.optionalOfferPrintHeader[lang.code]) {
                    this.htmlText[lang.code] = this.convertToHTMLBody(windowSystem.currentValue.optionalOfferPrintHeader[lang.code]);
                }
            }
        }
    }

    handleTextChange(lang: keyof MultilanguageFieldInterface, text: string) {
        this.htmlText[lang] = text;
        this.windowSystem.optionalOfferPrintHeader[lang] = this.convertToXHTMLDocument(text);
    }

    private convertToHTMLBody(xhtml: string): string {
        const parser = new DOMParser();
        let doc: Document = parser.parseFromString(xhtml, 'application/xhtml+xml');
        if (!doc.querySelector('parsererror')) {
            const htmlDoc = document.implementation.createHTMLDocument();
            doc.body.childNodes.forEach(node => {
                htmlDoc.body.appendChild(htmlDoc.importNode(node, true));
            })
            doc = htmlDoc;
        } else {
            doc = parser.parseFromString(xhtml, 'text/html');
        }
        return doc.body.innerHTML;
    }

    private convertToXHTMLDocument(html: string): string {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        doc.body.style.fontFamily = 'Calibri';
        doc.body.style.fontSize = '9pt';
        return new XMLSerializer().serializeToString(doc);
    }
}
