<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.USER_SETTINGS' | translate }}</div>
        <app-simple-button (onClick)="handleGoOnHoliday()"
                           [size]="32" class="holiday-button" label="{{ (user.onHoliday ? 'USER-DETAILS.HOLIDAY.BACK_FROM_HOLIDAY' : 'USER-DETAILS.HOLIDAY.GO_ON_HOLIDAY') | translate }}" type="filter"></app-simple-button>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-600">
            <div class="new-form-row">
                <h3>{{ "USER-DETAILS.FORM.GENERAL" | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="firstName" [(ngModel)]="user.firstName"
                                label="{{ 'USER-DETAILS.FORM.FIRST_NAME' | translate }}" [maxlength]="50"
                                [disabled]="!permissions.canEditUserSelf()"
                                [(validationMessageKey)]="validationErrors['firstName']" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="surname" [(ngModel)]="user.lastName"
                                label="{{ 'USER-DETAILS.FORM.LAST_NAME' | translate }}" [maxlength]="50"
                                [disabled]="!permissions.canEditUserSelf()"
                                [(validationMessageKey)]="validationErrors['lastName']" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="email" [(ngModel)]="user.email" type="email"
                                label="{{ 'USER-DETAILS.FORM.EMAIL' | translate }}" [maxlength]="255"
                                [disabled]="!permissions.canEditUserSelf()"
                                [(validationMessageKey)]="validationErrors['email']" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="phone" label="{{ 'USER-DETAILS.FORM.PHONE' | translate }}"
                                [(validationMessageKey)]="validationErrors['phone']" [maxlength]="50"
                                [(ngModel)]="user.phone"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-select inputId="interfaceLanguage" *ngIf="permissions.canEditUserSelf()"
                            label="{{ 'USER-DETAILS.FORM.INTERFACE_LANG' | translate }}"
                            [(validationMessageKey)]="validationErrors['interfaceLanguage']"
                            [options]="supportedLanguages | async" [optionFormatter]="languageOptionFormatter"
                            [ngModel]="getInterfaceLanguage()" (ngModelChange)="changeUserInterfaceLanguage($event)"
                            [required]="true"></app-select>
            </div>

            <div class="new-form-row">
                <app-text-area inputId="otherInfo" [(ngModel)]="user.otherInfo"
                               label="{{ 'USER-DETAILS.FORM.OTHER_INFO' | translate }}" [maxlength]="512"
                               [disabled]="!permissions.canEditUserSelf()"
                               [(validationMessageKey)]="validationErrors['otherInfo']"></app-text-area>
            </div>

            <div class="new-form-row">
                <app-checkbox inputId="displaySideMenuLabels"
                              label="{{ 'USER-DETAILS.FORM.DISPLAY_SIDE_MENU_LABELS' | translate }}"
                              [(ngModel)]="user.displaySideMenuLabels"></app-checkbox>
            </div>
            <div *ngIf="permissions.isOperator()" class="new-form-row">
                <app-checkbox inputId="notifyOnOfferStatusChange"
                              label="{{ 'USER-DETAILS.FORM.NOTIFY_ON_OFFER_STATUS_CHANGE' | translate }}"
                              [(ngModel)]="user.notifyOnOfferStatusChange"></app-checkbox>
            </div>
            <div *ngIf="permissions.isKoordynator() || permissions.isOpiekun() || permissions.isOperator()" class="new-form-row">
                <app-checkbox inputId="enableOfferExportCheckbox"
                              label="{{ 'USER-DETAILS.FORM.ENABLE_OFFER_EXPORT' | translate }}"
                              [(ngModel)]="user.enableOfferExport"></app-checkbox>
            </div>
        </div>
        <div class="new-form-600">
            <div class="new-form-row">
                <!-- align both columns -->
                <h3>&nbsp;</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="username" [ngModel]="user.login" disabled autocomplete="username"
                                label="{{ 'USER-DETAILS.FORM.LOGIN' | translate }}" [required]="true"
                                [(validationMessageKey)]="validationErrors['login']"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-password inputId="password0" [(ngModel)]="user.password" autocomplete="new-password"
                                    label="{{ 'USER-DETAILS.FORM.PASSWORD' | translate }}" [maxlength]="256"
                                    [disabled]="!permissions.canEditUserSelf()"
                                    [(validationMessageKey)]="validationErrors['password']"></app-input-password>
            </div>

            <div class="new-form-row">
                <app-input-password inputId="repeatedPassword" [(ngModel)]="user.repeatedPassword" autocomplete="new-password"
                                    label="{{ 'USER-DETAILS.FORM.REPEAT_PASSWORD' | translate }}" [maxlength]="256"
                                    [disabled]="!permissions.canEditUserSelf()"
                                    [(validationMessageKey)]="validationErrors['repeatedPassword']"></app-input-password>
            </div>

            <div class="new-form-row">
                <app-file-upload inputId="image" [(ngModel)]="file" label="{{ 'USER-DETAILS.FORM.SIGNATURE' | translate }}"
                                 [required]="true" [maxSize]="150000" [maxWidth]="300" [maxHeight]="100"
                                 [addButtonLabel]="'USER-DETAILS.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'USER-DETAILS.FORM.CHANGE_FILE_UPLOAD'"
                                 [disabled]="!permissions.canEditUserSelf()"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </div>
        </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" icon="check" type="main-action"
                               [size]="40" [disabled]="!permissions.canEditUserSelf()"
                               (onClick)="submit()"></app-simple-button>
        </div>
    </div>
</div>

<p-dialog (onHide)="closeHolidayDialog()" [focusOnShow]="false" [modal]="true" [resizable]="false" [visible]="holidayDialogVisible">
    <p-header>
        <div class="p-dialog-title">
            {{ 'USER-DETAILS.HOLIDAY.CONFIRM' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <p>{{'USER-DETAILS.HOLIDAY.CONFIRM_DESCRIPTION' | translate}}</p>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button (onClick)="goOnHoliday()" [size]="40" buttonId="goOnHoliday"
                               label="{{ 'GENERAL.YES' | translate }}" type="main-action"></app-simple-button>
            <app-simple-button (onClick)="closeHolidayDialog()" [size]="40" label="{{ 'GENERAL.CANCEL' | translate }}"
                               type="cancel"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
