<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}
        </div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="displayShowAllButton" buttonId="toggle-mine-filter-button"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}"
                                   icon="filter_list" type="action" (onClick)="showAllSubsystems()"></app-simple-button>
                <app-simple-button *ngIf="!displayShowAllButton" buttonId="toggle-mine-filter-button"
                                   label="{{ 'GENERAL.FILTER_SHOW_MINE' | translate }}"
                                   icon="filter_list" type="action" (onClick)="showMySubsystems()"></app-simple-button>
                <app-simple-button buttonId="showReportWizard" label="{{ 'SUBSYSTEM-DETAILS.FORM.REPORT_FILTER' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="showReportWizard = true; changeDetector.markForCheck()"></app-simple-button>
                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.SUBSYSTEMS.LIST')"></app-simple-button>
                <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}" [displaySelectedLabel]="false"
                               [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"
                               id="subsystemsShownColumns"></p-multiSelect>
                <app-simple-button *ngIf="permissions.canCreateSubsystem()" buttonId="addSubsystem"
                                   label="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <!--Subsystems table-->
    <p-dataTable #dt [value]="items" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [seqNum]="2"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedSubsystem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="subsystemsTable">
		<p-column>
			<ng-template let-item="rowData" pTemplate="body">
				<div class="flex-row" style="min-width: 24px">
					<i *ngIf="hasNonGroupProfitMargins[item.id]"
					   class="fa font-awesome fas fa-exclamation-circle comment-icon-alignment warning-severity-color"
					   title="{{ 'SUBSYSTEM-DETAILS.FORM.HAS_PROFIT_MARGINS_WARNING' | translate }}"></i>
				</div>
			</ng-template>
		</p-column>
        <!--Kajecik column-->
        <p-column header="{{ 'SUBSYSTEM-DETAILS.FORM.ACTIONS_HEADER' | translate}}">
            <ng-template let-item="rowData" pTemplate="body">
                <div class="table-row-action-buttons min-width80">
                    <app-simple-button label="{{ 'SUBSYSTEM-DETAILS.FORM.NOTES_BUTTON' | translate }}"
                                       [inlineLabel]="false" icon="description" type="main-action"
                                       (onClick)="goToNotes(item.id)"></app-simple-button>
                </div>
            </ng-template>
        </p-column>
        <ng-container *ngFor="let column of columns">
            <p-column *ngIf="column.field === 'id'" [style]="{'width':'5%'}" [field]="column.field"
                      [header]="column.header | translate" [sortable]="column.sortable"
                      [filter]="column.filter" filterMatchMode="contains"
                      [defaultFilterValue]="column.defaultFilterValue"></p-column>
            <p-column *ngIf="column.field === 'active'" [field]="column.field" [header]="column.header | translate"
                      [sortable]="false" [filter]="column.filter"
                      [filterValues]="column.filterValues | selectItemTranslate"
                      [defaultFilterValue]="defaultActiveFilter">
                <ng-template let-subsystem="rowData" pTemplate="body">
                    {{( subsystem.active ? 'SUBSYSTEM-DETAILS.FORM.ACTIVE' : 'SUBSYSTEM-DETAILS.FORM.INACTIVE')
                    | translate }}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field === 'defaultCurrency'" [field]="column.field"
                      [header]="column.header | translate" [sortable]="false" [filter]="column.filter"
                      [filterValues]="filterCurrency | async" [defaultFilterValue]="column.defaultFilterValue"
                      sortField="defaultCurrency">
                <ng-template let-subsystem="rowData" pTemplate="body">
                    {{ subsystem.defaultCurrency }}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field === 'subsystemGroupName'"
                    [field]="column.field" [header]="column.header | translate" [sortable]="column.sortable"
                    [filter]="column.filter" filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue">
                <ng-template let-subsystem="rowData" pTemplate="body">
                    {{ subsystem.subsystemGroupName }}
                </ng-template>
            </p-column>
            <p-column *ngIf="column.field === 'subsystemExpirationDate'"
                      [field]="column.field" [header]="column.header | translate" [sortable]="column.sortable"
                      [filter]="column.filter" filterMatchMode="contains" [defaultFilterValue]="column.defaultFilterValue">
                <ng-template pTemplate="filter">
                    <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
                </ng-template>
                <ng-template let-subsystem="rowData" pTemplate="body">
                    {{ subsystem.subsystemExpirationDate | momentCurrentLang | amDateFormat: 'L' }}
                </ng-template>
            </p-column>
            <p-column
                    *ngIf="column.field !== 'active' && column.field !== 'defaultCurrency' && column.field !== 'id' && column.field !== 'subsystemGroupName' && column.field !== 'subsystemExpirationDate'"
                    [field]="column.field" [header]="column.header | translate" [sortable]="column.sortable"
                    [filter]="column.filter" filterMatchMode="contains"
                    [defaultFilterValue]="column.defaultFilterValue"></p-column>
        </ng-container>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-edit-subsystem *ngIf="displayDialog" [subsystemGroups]="subsystemGroups" [subsystem]="editedItem"
                    [addresses]="editedAddresses" [availableManagers]="clientManagers" [disabledFields]="disabledFields"
                    (onCancel)="cancel()" (onSave)="handleSave()"></app-edit-subsystem>
<p-dialog #reportParamsWizardDialog [resizable]="false" [(visible)]="showReportWizard">
    <p-header>{{ 'SUBSYSTEM-DETAILS.FORM.REPORT_FILTER' | translate }}</p-header>
    <div class="scrolled-dialog scrolled-dialog-60vw">
        <div class="new-form-60vw">
            <app-user-activity-report-wizard #reportParamsWizard [(filter)]="criteriaTreeFilter"
                                             (saved)="reportParamsWizardDialog.close($event); handleCustomFilterChange()"></app-user-activity-report-wizard>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" [label]="'GENERAL.SAVE' | translate" type="main-action" [size]="40"
                               (onClick)="reportParamsWizard.save($event)"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel" [size]="40"
                               (onClick)="reportParamsWizard.reset(); reportParamsWizardDialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

