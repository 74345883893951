export enum CatalogTab {
    LINKED_SYSTEMS = 'LINKED_SYSTEMS',
    DESCRIPTION = 'DESCRIPTION',
    GLAZING = 'GLAZING',
    SYSTEM_GROUP = 'SYSTEM_GROUP',
    POSITION = 'POSITION',
    COLOR_USAGE = 'COLOR_USAGE',
    ADDONS = 'ADDONS'
}

export enum AddonField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    CATEGORY = 'CATEGORY',
    PCN = 'PCN',
    SUPPLIER = 'SUPPLIER',
    QUANTITY_TYPE = 'QUANTITY_TYPE',
    ADDON_FOR = 'ADDON_FOR',
    DEFAULT_QUANTITY = 'DEFAULT_QUANTITY',
    REMOVABLE = 'REMOVABLE',
    QUANTITY_CHANGE = 'QUANTITY_CHANGE',
    AVAILABLE_IN = 'AVAILABLE_IN',
    PRICE = 'PRICE',
    VENSKA_SELL_NET_PRICE = 'VENSKA_SELL_NET_PRICE',
    PRICE_TYPE = 'PRICE_TYPE',
    COLOR_CHANGE = 'COLOR_CHANGE',
    SINGLE_POSITION_ADDON = 'SINGLE_POSITION_ADDON',
    SHOW_GATE_DISCLAIMER = 'SHOW_GATE_DISCLAIMER',
    PROFIT_MARGIN_WINDOW_SYSTEM = 'PROFIT_MARGIN_WINDOW_SYSTEM',
    CUSTOM_VALUE = 'CUSTOM_VALUE',
    CUSTOM_STRING_VALUE = 'CUSTOM_STRING_VALUE',
    COLORS_SELECTION = 'COLORS_SELECTION',
    PREVIEW_IMAGE = 'PREVIEW_IMAGE',
    MOTLAWA_INTEGRATION = 'MOTLAWA_INTEGRATION',
    PRINT_DATA = 'PRINT_DATA',
    UNIT_WEIGHT = 'UNIT_WEIGHT',
    AUTO_OPTION = 'AUTO_OPTION',
    TILE_DATA = 'TILE_DATA'
}

export enum AddonCategoryGroupField {
    NAME = 'NAME',
    SORT_INDEX = 'SORT_INDEX',
    CATEGORIES = 'CATEGORIES'
}

export enum AddonCategoryField {
    NAME = 'NAME',
    SYMBOL = 'SYMBOL',
    SORT_INDEX = 'SORT_INDEX',
    GROUP = 'GROUP',
    REQUIRED = 'REQUIRED',
    TILE_DIALOG = 'TILE_DIALOG',
    HIDE_VALUE_ON_PRINT = 'HIDE_VALUE_ON_PRINT',
    PRINT_VISIBILITY = 'PRINT_VISIBILITY',
    INFO = 'INFO'
}

export enum ConfigAddonField {
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    TYPE = 'TYPE',
    DEFINITION = 'DEFINITION',
    SUPPLIER = 'SUPPLIER',
    CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL = 'CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL',
    CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING = 'CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING',
    CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING = 'CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING',
    CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING = 'CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING',
    APPLICABLE = 'APPLICABLE',
    MESSAGES = 'MESSAGES',
    PREVIEW_IMAGE = 'PREVIEW_IMAGE',
    DESCRIPTION_IMAGE = 'DESCRIPTION_IMAGE',
    DESCRIPTION_IMAGE_LARGE = 'DESCRIPTION_IMAGE_LARGE',
    DESCRIPTION = 'DESCRIPTION',
    MOTLAWA_INTEGRATION = 'MOTLAWA_INTEGRATION'
}

export enum ColorField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    TYPE = 'TYPE',
    RAL_HEX = 'RAL_HEX',
    GROUP = 'GROUP',
    CORE = 'CORE',
    INSIDE = 'INSIDE',
    OUTSIDE = 'OUTSIDE',
    GRILL = 'GRILL',
    MUST_BE_COVERED = 'MUST_BE_COVERED',
    CANNOT_BE_COVERED = 'CANNOT_BE_COVERED',
    GRILL_MATCHING_COLOR = 'GRILL_MATCHING_COLOR',
    WEBSHOP = 'WEBSHOP',
    WEBSHOP_COLOR_OPTION = 'WEBSHOP_COLOR_OPTION',
    WEBSHOP_HEX = 'WEBSHOP_HEX',
    WEBSHOP_TEXTURE = 'WEBSHOP_TEXTURE',
    GATE_HEX = 'GATE_HEX',
    IMAGE_SECTION = 'IMAGE_SECTION'
}

export enum GlazingBeadField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    THICKNESS = 'THICKNESS',
    ACCEPTABLE_FILLING_WIDTH = 'ACCEPTABLE_FILLING_WIDTH',
    IMAGE_SECTION = 'IMAGE_SECTION'
}

export enum MaterialField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    GROUP = 'GROUP',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    IMAGE_SECTION = 'IMAGE_SECTION'
}

export enum WindowDependentOptionField {
    NAME = 'NAME',
    SORT_INDEX = 'SORT_INDEX',
    SYSTEM = 'SYSTEM',
    ACTIVE = 'ACTIVE',
}

export enum WindowDependentOptionTab {
    REQUIRED = 'REQUIRED',
    DEPENDENT = 'DEPENDENT'
}

export enum SystemGlazingPackageField {
    NAME = 'NAME',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    WEBSHOP_DESCRIPTION = 'WEBSHOP_DESCRIPTION',
    TAGS = 'TAGS',
    IMAGE_SECTION = 'IMAGE_SECTION',
}

export enum ProfileField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    TYPE = 'TYPE',
    UNIT_WEIGHT = 'UNIT_WEIGHT',
    WIDTH_EXTERNAL = 'WIDTH_EXTERNAL',
    IMAGE_SECTION = 'IMAGE_SECTION',
}

export enum DistanceFrameField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    THICKNESS = 'THICKNESS',
    GROUP = 'GROUP',
    TYPE = 'TYPE',
    WARM_ALTERNATIVE = 'WARM_ALTERNATIVE',
    WARM_FRAME = 'WARM_FRAME'
}

export enum WindowSystemBasicField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    PCN = 'PCN',
    SUPPLIER = 'SUPPLIER',
    MATERIAL = 'MATERIAL',
    POSSIBLE_OPENING = 'POSSIBLE_OPENING',
    DIMENSION_STEP = 'DIMENSION_STEP',
    TAGS = 'TAGS',
    UNIT_WEIGHT = 'UNIT_WEIGHT'
}

export enum WindowSystemField {
    SYSTEM_TYPE = 'SYSTEM_TYPE',
    COMBINE_BUSINESS_TYPES = 'COMBINE_BUSINESS_TYPES',
    VENTILATION_ALWAYS_ON_FRAME = 'VENTILATION_ALWAYS_ON_FRAME',
    SPLIT_CUT_BUSINESS_TYPES = 'SPLIT_CUT_BUSINESS_TYPES',
    ALLOW_ONLY_EQUAL_SUBWINDOWS = 'ALLOW_ONLY_EQUAL_SUBWINDOWS',
    ALLOW_MULLION_CROSSING = 'ALLOW_MULLION_CROSSING',
    ALLOW_FEST_AT_HINGES = 'ALLOW_FEST_AT_HINGES',
    CAN_HAVE_STANDALONE_GLAZING_PACKAGES = 'CAN_HAVE_STANDALONE_GLAZING_PACKAGES',
    AUTO_ALIGN = 'AUTO_ALIGN',
    PRICETABLE_SELECTION_MODE = 'PRICETABLE_SELECTION_MODE'
}

export enum WindowSystemTab {
    ANGLES = 'ANGLES',
    ASSEMBLY = 'ASSEMBLY',
    DRAWING_TOOLS = 'DRAWING_TOOLS',
    SPECIFICATION = 'SPECIFICATION',
    DESCRIPTION = 'DESCRIPTION',
    DESIGNER_DESCRIPTION = 'DESIGNER_DESCRIPTION',
    DIMENSION_PRICE = 'DIMENSION_PRICE',
    PRINT_INFO = 'PRINT_INFO',
    GLAMOUR_PRINT_INFO = 'GLAMOUR_PRINT_INFO',
    SHAPES = 'SHAPES',
    WEB_SHOP = 'WEB_SHOP'
}

export enum GrillField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    TYPE = 'TYPE',
    WIDTH = 'WIDTH',
    MAX_ALLOWED_SEGMENT_LENGTH = 'MAX_ALLOWED_SEGMENT_LENGTH',
    MIN_FRAME_WIDTH = 'MIN_FRAME_WIDTH',
    ANGLED = 'ANGLED',
    CAN_NOT_BE_COMBINED_WITH_OTHER_GRILLS = 'CAN_NOT_BE_COMBINED_WITH_OTHER_GRILLS',
    COLORS = 'COLORS',
    IMAGE_SECTION = 'IMAGE_SECTION',
}

export enum GlassField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    THICKNESS = 'THICKNESS',
    LAMINATED = 'LAMINATED',
    UNIT_WEIGHT = 'UNIT_WEIGHT',
    CONSTRUCT_THICKNESS = 'CONSTRUCT_THICKNESS',
    ESG = 'ESG',
    TMP = 'TMP',
    ORNAMENT = 'ORNAMENT',
    PREVIEW_IMAGE = 'PREVIEW_IMAGE',
    GLAMOUR_PRINT_ICON = 'GLAMOUR_PRINT_ICON',
}

export enum BusinessTypeField {
    NAME = 'NAME',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    WINDOW_TYPE_CODE = 'WINDOW_TYPE_CODE',
    DESCRIPTION = 'DESCRIPTION',
    MIN_WIDTH_IN_WEBSHOP = 'MIN_WIDTH_IN_WEBSHOP',
    MAX_WIDTH_IN_WEBSHOP = 'MAX_WIDTH_IN_WEBSHOP',
    MIN_HEIGHT_IN_WEBSHOP = 'MIN_HEIGHT_IN_WEBSHOP',
    MAX_HEIGHT_IN_WEBSHOP = 'MAX_HEIGHT_IN_WEBSHOP',
    NUMBER_OF_RAILS = 'NUMBER_OF_RAILS'
}

export enum SubwindowTypeField {
    NAME = 'NAME',
    TYPE = 'TYPE',
    FUNCTIONS_QUANTITY = 'FUNCTIONS_QUANTITY'
}

export enum SealField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
}

export enum OtherFillingField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    THICKNESS = 'THICKNESS',
    UNIT_WEIGHT = 'UNIT_WEIGHT',
    FOR_INDOOR_USE = 'FOR_INDOOR_USE',
    FOR_OUTDOOR_USE = 'FOR_OUTDOOR_USE',
    CORE_COLORS = 'CORE_COLORS',
    COLOR_SELECTION = 'COLOR_SELECTION'
}

export enum DecorativeFillingField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    THICKNESS = 'THICKNESS',
    UNIT_WEIGHT = 'UNIT_WEIGHT',
    GLAZING_WIDTHS = 'GLAZING_WIDTHS',
    GLAZING_SURFACE = 'GLAZING_SURFACE',
    MINIMAL_WIDTH = 'MINIMAL_WIDTH',
    MAXIMUM_WIDTH = 'MAXIMUM_WIDTH',
    MINIMAL_HEIGHT = 'MINIMAL_HEIGHT',
    MAXIMUM_HEIGHT = 'MAXIMUM_HEIGHT',
    CORE_COLORS = 'CORE_COLORS',
    COLOR_SELECTION = 'COLOR_SELECTION',
    TYPE_IMAGE = 'TYPE_IMAGE',
    IMAGE_SECTION = 'IMAGE_SECTION'
}

export enum RoofGlazingPackageField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    GLASSES = 'GLASSES',
    GLASSES_QUANTITY = 'GLASSES_QUANTITY',
    UG = 'UG',
    IMAGE_SECTION = 'IMAGE_SECTION'
}

export enum RoofSystemField {
    ROOF_SYSTEM_FUNCTION = 'ROOF_SYSTEM_FUNCTION',
    ROOF_SYSTEM_GLAZING_PACKAGE = 'ROOF_SYSTEM_GLAZING_PACKAGE',
    ROOF_SYSTEM_UW = 'ROOF_SYSTEM_UW',
    WINDOW_EDITOR_IMAGE = 'WINDOW_EDITOR_IMAGE',
    PRINTOUT_IMAGE = 'PRINTOUT_IMAGE'
}

export enum DimensionsField {
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    WIDTH = 'WIDTH',
    HEIGHT = 'HEIGHT',
    SURFACE = 'SURFACE'
}

export enum EntranceModelField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    GLAZING_PACKAGE = 'GLAZING_PACKAGE',
    UW = 'UW',
    IMAGE_SECTION = 'IMAGE_SECTION',
    IMAGE2_SECTION = 'IMAGE2_SECTION'
}

export enum GraspGlazingCategoryField {
    NAME = 'NAME',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
}

export enum GraspDistanceFrameCategoryField {
    NAME = 'NAME',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
}

export enum GraspGlazingPackageField {
    NAME = 'NAME',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    CATEGORY = 'CATEGORY',
    FRAME_CATEGORY = 'FRAME_CATEGORY'
}

export enum EntranceGlazingPackageField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    GLASSES_QUANTITY = 'GLASSES_QUANTITY',
    IMAGE_SECTION = 'IMAGE_SECTION'
}

export enum GateSystemField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    PCN = 'PCN',
    SUPPLIER = 'SUPPLIER',
    DIMENSION_STEP = 'DIMENSION_STEP',
    IMAGE_SECTION = 'IMAGE_SECTION',
    UNIT_WEIGHT = 'UNIT_WEIGHT'
}

export enum ConfigSystemField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    PCN = 'PCN',
    SUPPLIER = 'SUPPLIER',
    DIMENSION_STEP = 'DIMENSION_STEP',
}

export enum GateWallField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    LOW_RESOLUTION_PREVIEW_IMAGE = 'LOW_RESOLUTION_PREVIEW_IMAGE',
    HIGH_RESOLUTION_PREVIEW_IMAGE = 'HIGH_RESOLUTION_PREVIEW_IMAGE'
}

export enum ConfigDependentOptionField {
    NAME = 'NAME',
    SORT_INDEX = 'SORT_INDEX',
    SYSTEM = 'SYSTEM',
    ACTIVE = 'ACTIVE',
}

export enum GateDependentOptionField {
    NAME = 'NAME',
    SORT_INDEX = 'SORT_INDEX',
    SYSTEM = 'SYSTEM',
    ACTIVE = 'ACTIVE',
}

export enum ConfigDependentOptionTab {
    REQUIRED = 'REQUIRED',
    DEPENDENT = 'DEPENDENT'
}

export enum GateDependentOptionTab {
    REQUIRED = 'REQUIRED',
    DEPENDENT = 'DEPENDENT'
}

export enum GatePanelTypeField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    HEIGHT = 'HEIGHT',
    TYPE_IMAGE = 'TYPE_IMAGE',
    DESIGNER_COMMENT = 'DESIGNER_COMMENT',
    LOW_RESOLUTION_PREVIEW_IMAGE = 'LOW_RESOLUTION_PREVIEW_IMAGE',
    HIGH_RESOLUTION_PREVIEW_IMAGE = 'HIGH_RESOLUTION_PREVIEW_IMAGE',
    PRINT_DATA = 'PRINT_DATA'
}

export enum RailSystemField {
    NAME = 'NAME',
    SHORTCUT = 'SHORTCUT',
    SYMBOL = 'SYMBOL',
    ACTIVE = 'ACTIVE',
    SORT_INDEX = 'SORT_INDEX',
    IMAGE_SECTION = 'IMAGE_SECTION',
    DESIGNER_IMAGE_SECTION = 'DESIGNER_IMAGE_SECTION',
    PRINT_DATA = 'PRINT_DATA'
}

export enum RackField {
    NAME = 'NAME',
    ACTIVE = 'ACTIVE',
    WEIGHT = 'WEIGHT',
    NET_EURO_PRICE = 'NET_EURO_PRICE',
    SLOTS = 'SLOTS',
    USABLE_WIDTH = 'USABLE_WIDTH',
    USABLE_DEPTH = 'USABLE_DEPTH',
    USABLE_HEIGHT = 'USABLE_HEIGHT',
    TOTAL_WIDTH = 'TOTAL_WIDTH',
    TOTAL_DEPTH = 'TOTAL_DEPTH',
    TOTAL_HEIGHT = 'TOTAL_HEIGHT',
    ADDITIONALLY_CALCULATED = 'ADDITIONALLY_CALCULATED'
}
