import {ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, Input, Optional, Renderer2, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const INPUT_PASSWORD_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputPasswordComponent),
    multi: true
};

@Component({
    selector: 'app-input-password',
    templateUrl: './input-password.component.html',
    providers: [INPUT_PASSWORD_VALUE_ACCESSOR]
})
export class InputPasswordComponent extends AbstractInputComponent {

    @Input()
    required: boolean;

    @Input()
    maxlength: number;

    @Input()
    autocomplete: string;

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }
}
