import {ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {OpeningOption, WindowSystemType, WindowSystemTypeEnum} from '../../../../window-designer/catalog-data/window-system-interface';
import {FittingTerraceLockLocation} from '../../../../window-designer/drawing-data/FittingTerraceLockLocation';
import {TerraceHandleLayout} from '../../../../window-designer/drawing-data/TerraceHandleLayout';
import {WindowView} from '../../../../window-designer/drawing-data/WindowView';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {WindowDependentOptionTab} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ConfigurableAddonsService} from '../../offer/offers/position/config-addons.service';
import {WindowEditorField} from '../../offer/window-editor/window-editor-field';
import {
    WINDOW_EDITOR_FIELD_CONTENT_PROVIDER_SERVICES,
    WindowEditorFieldContentProvider
} from '../../offer/window-editor/window-editor-field-content-provider';
import {WindowDependentOptionFieldUsage} from "../catalog-field-usage";
import {WindowDependentOptionHelperInterface} from "../catalog-field-usage-helper-interfaces";
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {WindowDesignerCatalogDependentOption} from './data-form/window-designer-catalog-dependent-option';
import {
    WindowDesignerCatalogDependentOptionFormDependencyComponent
} from './data-form/window-designer-catalog-dependent-option-form-dependency.component';
import {WindowDesignerCatalogDependentOptionService} from './data-form/window-designer-catalog-dependent-option.service';
import {WindowDesignerCatalogDependentOptionsSet} from './window-designer-catalog-dependent-options-set';
import {WindowDesignerCatalogDependentOptionsSetService} from './window-designer-catalog-dependent-options-set.service';

export interface WindowDesignerCatalogDependentOptionsSetComponentConfiguration {
    tableHeader: string;
    systemTypes: WindowSystemTypeEnum[];
    allowedWindowEditorRequiredFields: WindowEditorField[];
    allowedWindowEditorDependentFields: WindowEditorField[];
    loadCatalogData(provider: WindowEditorFieldContentProvider, windowSystemId: number): any;
    storeCatalogData(provider: WindowEditorFieldContentProvider, catlogData: any): void;
}

export const DEPENDENT_OPTIONS_WINDOW_SYSTEM_DATA: WindowDesignerCatalogDependentOptionsSetComponentConfiguration = {
    tableHeader: 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.LIST',
    systemTypes: WindowSystemType.getWindowSystemTypes().map(td => td.type),
    allowedWindowEditorRequiredFields: [
        WindowEditorField.PROFILE,
        WindowEditorField.WELD_TYPE,
        WindowEditorField.FRAME_ENHANCEMENT,
        WindowEditorField.VIEW,
        WindowEditorField.WINDOW_FUNCTION,
        WindowEditorField.COVERS,
        WindowEditorField.MILLINGS,
        WindowEditorField.MILLINGS_NORWEGIAN,
        WindowEditorField.UNDER_WINDOW_BEAD,
        WindowEditorField.HANDLES,
        WindowEditorField.UNDERWINDOW_PROFILE,
        WindowEditorField.DOORSTEP,
        WindowEditorField.OPENING,
        WindowEditorField.SEALS_INTERNAL,
        WindowEditorField.SEALS_EXTERNAL,
        WindowEditorField.CORE_COLOR,
        WindowEditorField.EXTERNAL_COLOR,
        WindowEditorField.INTERNAL_COLOR,
        WindowEditorField.FITTING_BRAKE,
        WindowEditorField.FITTING_SLIDING,
        WindowEditorField.FITTING_TYPE,
        WindowEditorField.FITTING_ESPAGNOLETTE_TYPE,
        WindowEditorField.FITTING_VERANDA,
        WindowEditorField.FITTING_INSERTION,
        WindowEditorField.FITTING_MAIN_INSERTION,
        WindowEditorField.FITTING_ADDITIONAL_INSERTION,
        WindowEditorField.FITTING_LOCK,
        WindowEditorField.FITTING_LOCK_TERRACE,
        WindowEditorField.FITTING_AUTOMATIC_DRIVE,
        WindowEditorField.WIDTH_GREATER_THAN,
        WindowEditorField.WIDTH_LESS_THAN,
        WindowEditorField.HEIGHT_GREATER_THAN,
        WindowEditorField.HEIGHT_LESS_THAN,
        WindowEditorField.TERRACE_HANDLE,
        WindowEditorField.TERRACE_HANDLE_LAYOUT,
        WindowEditorField.BUSINESS_TYPE
    ],
    allowedWindowEditorDependentFields: [
        WindowEditorField.MULLION,
        WindowEditorField.PROFILE,
        WindowEditorField.WELD_TYPE,
        WindowEditorField.FRAME_ENHANCEMENT,
        WindowEditorField.VIEW,
        WindowEditorField.WINDOW_FUNCTION,
        WindowEditorField.COVERS,
        WindowEditorField.MILLINGS,
        WindowEditorField.MILLINGS_NORWEGIAN,
        WindowEditorField.UNDER_WINDOW_BEAD,
        WindowEditorField.HANDLES,
        WindowEditorField.UNDERWINDOW_PROFILE,
        WindowEditorField.DOORSTEP,
        WindowEditorField.OPENING,
        WindowEditorField.SEALS_INTERNAL,
        WindowEditorField.SEALS_EXTERNAL,
        WindowEditorField.CORE_COLOR,
        WindowEditorField.EXTERNAL_COLOR,
        WindowEditorField.INTERNAL_COLOR,
        WindowEditorField.FITTING_BRAKE,
        WindowEditorField.FITTING_SLIDING,
        WindowEditorField.FITTING_TYPE,
        WindowEditorField.FITTING_ESPAGNOLETTE_TYPE,
        WindowEditorField.FITTING_VERANDA,
        WindowEditorField.FITTING_INSERTION,
        WindowEditorField.FITTING_MAIN_INSERTION,
        WindowEditorField.FITTING_ADDITIONAL_INSERTION,
        WindowEditorField.FITTING_LOCK,
        WindowEditorField.FITTING_LOCK_TERRACE,
        WindowEditorField.FITTING_LOCK_TERRACE_LOCATION,
        WindowEditorField.FITTING_AUTOMATIC_DRIVE,
        WindowEditorField.TERRACE_GLAZING_PACKAGE,
        WindowEditorField.TERRACE_HANDLE,
        WindowEditorField.TERRACE_HANDLE_LAYOUT
    ],
    loadCatalogData(provider: WindowEditorFieldContentProvider, windowSystemId: number): any {
        return provider.getCatalogForWindowSystemDataSources(windowSystemId, undefined, false, undefined);
    },
    storeCatalogData(provider: WindowEditorFieldContentProvider, catlogData: any) {
        provider.initCatalog(catlogData);
    }
};

export const DEPENDENT_OPTIONS_ENTRANCE_DOOR_DATA: WindowDesignerCatalogDependentOptionsSetComponentConfiguration = {
    tableHeader: 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ENTRANCE_DESIGNER_CATALOG_DEPENDENT_OPTION.LIST',
    systemTypes: [WindowSystemTypeEnum.ENTRANCE],
    allowedWindowEditorRequiredFields: [
        WindowEditorField.MODEL,
        WindowEditorField.PROFILE,
        WindowEditorField.OPENING,
        WindowEditorField.GLAZING_PACKAGE,
        WindowEditorField.EXTERNAL_COLOR,
        WindowEditorField.INTERNAL_COLOR,
        WindowEditorField.ENTRANCE_DOOR_FITTING,
        WindowEditorField.DIMENSIONS,
        WindowEditorField.WIDTH_GREATER_THAN,
        WindowEditorField.WIDTH_LESS_THAN,
        WindowEditorField.HEIGHT_GREATER_THAN,
        WindowEditorField.HEIGHT_LESS_THAN
    ],
    allowedWindowEditorDependentFields: [
        WindowEditorField.PROFILE,
        WindowEditorField.OPENING,
        WindowEditorField.GLAZING_PACKAGE,
        WindowEditorField.EXTERNAL_COLOR,
        WindowEditorField.INTERNAL_COLOR,
        WindowEditorField.ENTRANCE_DOOR_FITTING
    ],
    loadCatalogData(provider: WindowEditorFieldContentProvider, windowSystemId: number): any {
        return provider.getCatalogForEntranceDoorSystemDataSources(windowSystemId, undefined, false);
    },
    storeCatalogData(provider: WindowEditorFieldContentProvider, catlogData: any) {
        provider.initEntranceDoorCatalog(catlogData);
    }
};

@Component({
    selector: 'app-window-designer-catalog-dependent-options-set',
    templateUrl: './window-designer-catalog-dependent-options-set.component.html',
    providers: [WindowDesignerCatalogDependentOptionsSetService, TranslatedSelectItemService,
        WindowEditorFieldContentProvider, ...WINDOW_EDITOR_FIELD_CONTENT_PROVIDER_SERVICES, ConfigurableAddonsService,]
})
export class WindowDesignerCatalogDependentOptionsSetComponent
    extends CrudCommonComponent<WindowDesignerCatalogDependentOptionsSet, WindowDesignerCatalogDependentOptionsSetService>
    implements OnInit, WindowDependentOptionHelperInterface {

    @Input()
    configuration: WindowDesignerCatalogDependentOptionsSetComponentConfiguration;

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState: boolean;

    private readonly windowDesignerCatalogDependentOptionService: WindowDesignerCatalogDependentOptionService;
    private readonly windowSystemDefinitionService: WindowSystemDefinitionService;
    private readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider;
    allWindowEditorRequiredField: Observable<SelectItem[]>;
    allWindowEditorDependentField: Observable<SelectItem[]>;
    inputWindowEditorRequiredField: Observable<SelectItem[]>;
    inputWindowEditorDependentField: Observable<SelectItem[]>;
    requiredInputSelectedMap: Map<string, string[]>;
    dependentInputSelectedMap: Map<string, string[]>;

    windowSystems: Observable<CatalogItemName[]>;
    optionsSet: WindowDesignerCatalogDependentOption[] = [];

    @ViewChild('table')
    table: Table;

    readonly STEPS = {
        DATA: 'DATA',
        REQUIRED: 'REQUIRED',
        DEPENDENT: 'DEPENDENT'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateData(),
        REQUIRED: () => this.validateRequired(),
        DEPENDENT: () => this.validateDependent()
    };

    editPermits: FieldLimitation[] = [];
    fieldUsage: WindowDependentOptionFieldUsage;
    WindowDependentOptionTab = WindowDependentOptionTab;
    nextStepsFilled: boolean;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, false, WindowDesignerCatalogDependentOptionsSetService, 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION',
            'WindowDesignerCatalogDependentOption');
        this.windowDesignerCatalogDependentOptionService = injector.get(WindowDesignerCatalogDependentOptionService);
        this.windowSystemDefinitionService = injector.get(WindowSystemDefinitionService);
        this.windowEditorFieldContentProvider = injector.get(WindowEditorFieldContentProvider);
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.fieldUsage = new WindowDependentOptionFieldUsage(this);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.allWindowEditorRequiredField = this.translatedSelectItemService
            .buildSortedDropdown(this.configuration.allowedWindowEditorRequiredFields, 'WINDOW_DESIGNER_FIELD.', '');
        this.allWindowEditorDependentField = this.translatedSelectItemService
            .buildSortedDropdown(this.configuration.allowedWindowEditorDependentFields, 'WINDOW_DESIGNER_FIELD.', '');
        this.inputWindowEditorRequiredField = this.allWindowEditorRequiredField.pipe(
            map(selectItems => selectItems.filter(selectItem => selectItem.label != undefined && selectItem.label.length > 0))
        );
        this.inputWindowEditorDependentField = this.allWindowEditorDependentField.pipe(
            map(selectItems => selectItems.filter(selectItem => selectItem.label != undefined && selectItem.label.length > 0))
        );

        this.windowSystems = this.windowSystemDefinitionService.getItemNames(true, this.configuration.systemTypes).pipe(
            shareReplay(1));

        this.windowEditorFieldContentProvider.storeWindowFunctions(false);
        this.windowEditorFieldContentProvider.storeTerraceLockLocations(Object.keys(FittingTerraceLockLocation)
            .map(k => FittingTerraceLockLocation[k]), false);
        this.windowEditorFieldContentProvider.storeWindowViews([WindowView.INSIDE, WindowView.OUTSIDE], false);
        this.windowEditorFieldContentProvider.storeOpeningOptions([OpeningOption.INSIDE, OpeningOption.OUTSIDE], false);
        this.windowEditorFieldContentProvider.storeTerraceHandleLayouts(Object.keys(TerraceHandleLayout)
            .map(k => TerraceHandleLayout[k]), false);
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.WINDOW_DEPENDENT_OPTIONS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    loadItemsLazy(event: LazyLoadEvent) {
        event.filters['systemTypes'] = { value: this.configuration.systemTypes.join(',') }
        super.loadItemsLazy(event);
    }

    getNewItem(): WindowDesignerCatalogDependentOptionsSet {
        this.requiredInputSelectedMap = new Map<string, string[]>();
        this.dependentInputSelectedMap = new Map<string, string[]>();
        this.optionsSet = [];
        return new WindowDesignerCatalogDependentOptionsSet();
    }

    loadEditedItem(event: { data: WindowDesignerCatalogDependentOptionsSet }): Observable<WindowDesignerCatalogDependentOptionsSet> {
        return this.itemService.getItem(event.data.id).pipe(
            mergeMap(item => forkJoin({
                initWindowSystems: this.windowSystems,
                ...this.configuration.loadCatalogData(this.windowEditorFieldContentProvider, item.windowSystemId)
            }).pipe(map(catalogData => {
                this.configuration.storeCatalogData(this.windowEditorFieldContentProvider, catalogData);
                return item;
            })))
        );
    }

    doOnRowSelect(event) {
        this.requiredInputSelectedMap = new Map<string, string[]>();
        this.dependentInputSelectedMap = new Map<string, string[]>();
        this.windowDesignerCatalogDependentOptionService.getItemsFromSet(event.data.id).subscribe(
            options => this.optionsSet = options
        );
        super.doOnRowSelect(event);
    }

    prepareItemForRequest() {
        let options = [];
        for (const requiredMapKey of this.requiredInputSelectedMap.keys()) {
            let {inputId: requiredInputId} = WindowDesignerCatalogDependentOptionFormDependencyComponent.decodeMapKey(requiredMapKey);
            let requiredValues = this.requiredInputSelectedMap.get(requiredMapKey);

            for (const requiredValue of requiredValues) {
                for (const dependentMapKey of this.dependentInputSelectedMap.keys()) {
                    let {inputId: dependentInputId, whenMatched} = WindowDesignerCatalogDependentOptionFormDependencyComponent.decodeMapKey(dependentMapKey);
                    let dependentValues = this.dependentInputSelectedMap.get(dependentMapKey);
                    for (const dependentValue of dependentValues) {
                        let option = new WindowDesignerCatalogDependentOption();
                        option.windowSystemId = this.item.windowSystemId;
                        option.requiredInputId = requiredInputId;
                        option.requiredInputValue = requiredValue;
                        option.dependentInputId = dependentInputId;
                        option.dependentInputValue = dependentValue;
                        option.whenMatched = whenMatched;
                        options.push(option);
                    }
                }
            }
        }

        this.item.options = options;
        return this.item;
    }

    deleteItem(item: WindowDesignerCatalogDependentOptionsSet): void {
        this.itemService.deleteItem(item.id).subscribe(
            () => {
                this.reloadDatatable();
                this.growlMessageController.info(`${this.translationKey}.${this.translationKey}_DELETED`);
            }
        );
    }

    validateData(): Observable<boolean> {
        return this.itemService.validateGeneralData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    validateRequired(): Observable<boolean> {
        const validationErrors = {};

        if (this.requiredInputSelectedMap == null || this.requiredInputSelectedMap.size === 0) {
            validationErrors[`required`] = `error.windowDesignerCatalogDependentOptionsSetDto.required.not_empty`;
        } else if (Array.from(this.requiredInputSelectedMap.values()).some(val => val == null || val.length === 0)) {
            validationErrors[`required`] = `error.windowDesignerCatalogDependentOptionsSetDto.required.value.not_empty`;
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return of(true);
    }

    validateDependent(): Observable<boolean> {
        const validationErrors = {};

        if (this.dependentInputSelectedMap == null || this.dependentInputSelectedMap.size === 0) {
            validationErrors[`dependent`] = `error.windowDesignerCatalogDependentOptionsSetDto.dependent.not_empty`;
        } else if (Array.from(this.dependentInputSelectedMap.values()).some(val => val == null || val.length === 0)) {
            validationErrors[`dependent`] = `error.windowDesignerCatalogDependentOptionsSetDto.dependent.value.not_empty`;
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }

        this.prepareItemForRequest();
        return this.itemService.validateDependentOptions(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }
}
