<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">
            {{ configuration.tableHeader | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name" [ngClass]="columnHeaderClasses('name')">
                        <span>{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.NAME' | translate }}</span>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="name"
                               (input)="table.filter(getInputEventValue($event), 'name', 'contains')">
                    </th>
                    <th pSortableColumn="windowSystemName" [ngClass]="columnHeaderClasses('windowSystemName')">
                        <span>{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.WINDOW_SYSTEM' | translate }}</span>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="windowSystemName"
                               (input)="table.filter(getInputEventValue($event), 'windowSystemName', 'contains')">
                    </th>
                    <th pSortableColumn="sortIndex" [ngClass]="columnHeaderClasses('sortIndex')">
                        <span>{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.SORT_INDEX' | translate }}</span>
                    </th>
                    <th pSortableColumn="active" [ngClass]="columnHeaderClasses('active')">
                        <span>{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTIONS_SET.FORM.ACTIVE' | translate }}</span>
                        <div class="app-filter-multi-select"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                             (click)="handleInputFilterClick($event)">
                            <app-select inputId="activeFilter" [options]="filterActiveItems | async"
                                        [ngModel]="filterActiveState"
                                        (ngModelChange)="handleFilterActiveChange($event)">
                                <ng-template pTemplate="label"></ng-template>
                            </app-select>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dependentOption let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex"
                    (dblclick)="doOnRowSelect({data: dependentOption, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    [pSelectableRow]="dependentOption" [pSelectableRowIndex]="rowIndex"
                    [app-table-keyboard-navigation]="dependentOption"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({data: dependentOption, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    class="datatable-data">
                    <td>
                        <div style="white-space: break-spaces;">{{ dependentOption.name }}</div>
                    </td>
                    <td>
                        {{ dependentOption.windowSystemName | multilanguageTranslate }}
                    </td>
                    <td>
                        {{ dependentOption.sortIndex }}
                    </td>
                    <td>
                        {{ (dependentOption.active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F') | translate }}
                    </td>
                    <td style="width: 100px;">
                        <app-simple-button icon="cancel" type="delete" [size]="36" [inlineLabel]="false"
                                           label="{{'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.DELETE' | translate }}"
                                           (onClick)="deleteItem(dependentOption)"></app-simple-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>
<app-wizard *ngIf="newItem && displayDialog"
            header="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA" [validate]="VALIDATORS.DATA" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.STEPS.DATA' | translate }}">
        <app-window-designer-catalog-dependent-option-form [configuration]="configuration" [editPermits]="editPermits" [item]="item"
                                                           [validationErrors]="validationErrors"
                                                           [nextStepsFilled]="0 < ((dependentInputSelectedMap?.size || 0) + (requiredInputSelectedMap?.size || 0))"
                                                           [windowSystems]="windowSystems"></app-window-designer-catalog-dependent-option-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.REQUIRED" [validate]="VALIDATORS.REQUIRED" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.STEPS.REQUIRED' | translate }}"
                     *ngIf="fieldUsage.show(WindowDependentOptionTab.REQUIRED)">
        <app-window-designer-catalog-dependent-option-form-required [inputSelectedMap]="requiredInputSelectedMap"
               [item]="item" [validationErrors]="validationErrors" [options]="optionsSet"
               [fieldType]="inputWindowEditorRequiredField"></app-window-designer-catalog-dependent-option-form-required>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.DEPENDENT" [validate]="VALIDATORS.DEPENDENT" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.STEPS.DEPENDENT' | translate }}"
                     *ngIf="fieldUsage.show(WindowDependentOptionTab.DEPENDENT)">
        <app-window-designer-catalog-dependent-option-form-dependent [inputSelectedMap]="dependentInputSelectedMap"
               [item]="item" [validationErrors]="validationErrors" [options]="optionsSet"
               [fieldType]="inputWindowEditorDependentField"></app-window-designer-catalog-dependent-option-form-dependent>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.EDIT' | translate }}" (onCancel)="cancel()"
                   (onComplete)="submit()" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step [id]="STEPS.DATA" [validate]="VALIDATORS.DATA" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.STEPS.DATA' | translate }}">
        <app-window-designer-catalog-dependent-option-form [configuration]="configuration" [editPermits]="editPermits" [item]="item"
                                                           [validationErrors]="validationErrors"
                                                           [nextStepsFilled]="0 < ((dependentInputSelectedMap?.size || 0) + (requiredInputSelectedMap?.size || 0))"
                                                           [windowSystems]="windowSystems"></app-window-designer-catalog-dependent-option-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.REQUIRED" [validate]="VALIDATORS.REQUIRED" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.STEPS.REQUIRED' | translate }}"
                     *ngIf="fieldUsage.show(WindowDependentOptionTab.REQUIRED)">
        <app-window-designer-catalog-dependent-option-form-required [inputSelectedMap]="requiredInputSelectedMap"
               [item]="item" [validationErrors]="validationErrors" [options]="optionsSet"
               [fieldType]="inputWindowEditorRequiredField"></app-window-designer-catalog-dependent-option-form-required>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.DEPENDENT" [validate]="VALIDATORS.DEPENDENT" label="{{ 'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION.FORM.STEPS.DEPENDENT' | translate }}"
                     *ngIf="fieldUsage.show(WindowDependentOptionTab.DEPENDENT)">
        <app-window-designer-catalog-dependent-option-form-dependent [inputSelectedMap]="dependentInputSelectedMap"
               [item]="item" [validationErrors]="validationErrors" [options]="optionsSet"
               [fieldType]="inputWindowEditorDependentField"></app-window-designer-catalog-dependent-option-form-dependent>
    </app-wizard-step>
</app-wizard-dialog>
