import * as _ from 'underscore';
import {Glazing} from '../../../../window-designer/catalog-data/glazing';
import {WindowSystemType, WindowSystemTypeEnum} from "../../../../window-designer/catalog-data/window-system-interface";
import {AreaSpecification} from '../../../../window-designer/drawing-data/AreaSpecification';
import {DrawingData} from '../../../../window-designer/drawing-data/drawing-data';
import {FillingType} from '../../../../window-designer/drawing-data/FillingType';
import {Grill} from '../../../../window-designer/drawing-data/Grill';
import {SubWindowShape} from '../../../../window-designer/drawing-data/SubWindowShape';
import {WindowDimensionsUtils} from "../../../../window-designer/entities/window-dimensions";
import {GlazingHelper} from '../../../../window-designer/glazing-helper';
import {DecorativeFillingUtils} from '../../../../window-designer/utils/DecorativeFillingUtils';
import {GrillHelper} from '../../../../window-designer/utils/grill-helper';
import {ProfileCompositionUtils} from "../../../../window-designer/utils/profile-composition-utils";
import {WindowCalculator} from '../../../../window-designer/window-calculator';
import {SubWindowTypeCode} from '../../../../window-designer/window-types/subwindow-type-code';
import {ColorType} from '../../../ColorType';
import {MaterialType} from '../../../common/enums/MaterialType';
import {GlassSelectionValidator} from '../../../common/glass-selection/GlassSelectionValidator';
import {GrowlMessage} from '../../../common/growl-message/growl-message';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {DataModificationTarget, isDataModificationMode} from '../../window-system/webshop-charge/WebshopCharge';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {WindowSystemDefinition} from '../../window-system/window-system-definition/window-system-definition';
import {ConfigurableAddonUtils} from './drawing-tool/ConfigurableAddonUtils';
import {EntranceDoorData} from "./roof-window-editor/entrance-door-data";
import {RoofWindowEditorComponent} from './roof-window-editor/roof-window-editor.component';
import {FillingColorType} from './sidebar/FillingColorType';
import {GeneralTabComponent} from './sidebar/general-tab/general-tab.component';
import {SubwindowComponent} from './sidebar/subwindow/subwindow.component';
import {WindowEditorField} from './window-editor-field';

export enum SidebarSection {
    MAIN, COLOR, FILLING, FITTINGS, MUNTINS, MULLIONS, ADDONS, CONFIGURABLE_ADDONS, DESCRIPTION, AREA
}

export class GeneralTabFieldUsage {

    private static sectionFields = new Map<SidebarSection, WindowEditorField[]>([
        [SidebarSection.MAIN, [WindowEditorField.QUANTITY, WindowEditorField.WINDOW_SYSTEM, WindowEditorField.WIDTH,
            WindowEditorField.HEIGHT, WindowEditorField.PROFILE, WindowEditorField.WELD_TYPE, WindowEditorField.FRAME_ENHANCEMENT,
            WindowEditorField.VIEW, WindowEditorField.COVERS, WindowEditorField.MILLINGS, WindowEditorField.MILLINGS_NORWEGIAN,
            WindowEditorField.UNDER_WINDOW_BEAD, WindowEditorField.HANDLES, WindowEditorField.DOORSTEP,
            WindowEditorField.UNDERWINDOW_PROFILE, WindowEditorField.OPENING,
            WindowEditorField.SEALS_EXTERNAL, WindowEditorField.SEALS_INTERNAL, WindowEditorField.WEBSHOP_CHARGE_NAME,
            WindowEditorField.TERRACE_GLAZING_PACKAGE, WindowEditorField.TERRACE_COLOR, WindowEditorField.FITTING_LOCK_TERRACE,
            WindowEditorField.FITTING_LOCK_TERRACE_LOCATION, WindowEditorField.TERRACE_HANDLE, WindowEditorField.TERRACE_HANDLE_LAYOUT,
            WindowEditorField.CHANNEL_SECTION, WindowEditorField.CONSTRUCTIONAL_MULLION, WindowEditorField.MOVABLE_POST]],
        [SidebarSection.COLOR, [WindowEditorField.CORE_COLOR, WindowEditorField.EXTERNAL_COLOR, WindowEditorField.EXTERNAL_COLOR_OTHER_INFO,
            WindowEditorField.INTERNAL_COLOR, WindowEditorField.INTERNAL_COLOR_OTHER_INFO]],
        [SidebarSection.FILLING, [WindowEditorField.FILLING_TYPE, WindowEditorField.FILLING_WIDTH, WindowEditorField.FILLING_NAME_EXTERNAL,
            WindowEditorField.FILLING_NAME_INTERNAL, WindowEditorField.DECORATIVE_FILLING, WindowEditorField.FILLING_EXTERNAL_COLOR,
            WindowEditorField.FILLING_INTERNAL_COLOR, WindowEditorField.GLASS_SELECTOR, WindowEditorField.GLAZING_BEAD,
            WindowEditorField.WEBSHOP_GLAZING_PACKAGE, WindowEditorField.GLAZING_PACKAGE, WindowEditorField.GLAZING_PACKAGE_CATEGORY,
            WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY, WindowEditorField.GLAZING_PACKAGE_QUANTITY]],
        [SidebarSection.FITTINGS, [WindowEditorField.FITTING_BRAKE, WindowEditorField.FITTING_SLIDING, WindowEditorField.FITTING_TYPE,
            WindowEditorField.FITTING_ESPAGNOLETTE_TYPE, WindowEditorField.FITTING_VERANDA, WindowEditorField.FITTING_INSERTION,
            WindowEditorField.FITTING_MAIN_INSERTION, WindowEditorField.FITTING_ADDITIONAL_INSERTION, WindowEditorField.FITTING_LOCK,
            WindowEditorField.FITTING_AUTOMATIC_DRIVE]],
        [SidebarSection.MUNTINS, [WindowEditorField.GRILL]],
        [SidebarSection.MULLIONS, [WindowEditorField.MULLION]],
        [SidebarSection.ADDONS, []],
        [SidebarSection.CONFIGURABLE_ADDONS, []],
        [SidebarSection.DESCRIPTION, []]
    ]);

    fieldToFocusOnError: WindowEditorField;

    constructor(private generalTab: GeneralTabComponent) {
    }

    public show(field: WindowEditorField) {
        if (!this.generalTab) {
            return false;
        }

        const tab = this.generalTab;
        const fieldContent = tab.windowEditorFieldContentProvider;
        switch (field) {
            case WindowEditorField.QUANTITY:
                return this.addToVisible(field, !tab.sidebarOnlyMode) && tab.offerPosition != undefined;
            case WindowEditorField.WIDTH:
            case WindowEditorField.HEIGHT:
                return this.addToVisible(field, !tab.sidebarOnlyMode);
            case WindowEditorField.WELD_TYPE:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.FRAME_ENHANCEMENT:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.COVERS:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)) &&
                    (tab.sidebarOnlyMode || this.nonfixedSubwindowsPresent()));
            case WindowEditorField.MILLINGS:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.MILLINGS_NORWEGIAN:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.UNDER_WINDOW_BEAD:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.HANDLES:
                return this.addToVisible(field, (tab.sidebarOnlyMode ||
                        WindowCalculator.hasSubwindowsAllowingHandles(tab.drawingData.windows))
                        && this.getWindowSystem() && !this.getWindowSystem().fixedOnly && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.UNDERWINDOW_PROFILE:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.FITTING_BRAKE:
            case WindowEditorField.FITTING_SLIDING:
            case WindowEditorField.FITTING_TYPE:
            case WindowEditorField.FITTING_ESPAGNOLETTE_TYPE:
            case WindowEditorField.FITTING_VERANDA:
            case WindowEditorField.FITTING_INSERTION:
            case WindowEditorField.FITTING_MAIN_INSERTION:
            case WindowEditorField.FITTING_ADDITIONAL_INSERTION:
            case WindowEditorField.FITTING_LOCK:
            case WindowEditorField.FITTING_AUTOMATIC_DRIVE:
                return this.addToVisible(field, this.showFittingField(fieldContent.getItems(field)));
            case WindowEditorField.FITTING_LOCK_TERRACE:
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.FITTING_LOCK_TERRACE_LOCATION:
                return this.addToVisible(field, this.show(WindowEditorField.FITTING_LOCK_TERRACE) &&
                    tab.drawingData.specification.fittingLockTerrace != null &&
                    tab.drawingData.specification.fittingLockTerrace.addonId != null);
            case WindowEditorField.DOORSTEP:
            case WindowEditorField.CHANNEL_SECTION: {
                const windowSystem = this.getWindowSystem();
                return this.addToVisible(field, windowSystem != undefined && windowSystem.doors
                    && this.notEmpty(fieldContent.getItems(field)) && (tab.sidebarOnlyMode || windowSystem.doorstepAllowedInFF
                    || this.nonfixedSubwindowsPresent()));
            }
            case WindowEditorField.OPENING: {
                return this.addToVisible(field, this.notEmpty(fieldContent.getItems(field))
                    && (tab.sidebarOnlyMode || this.nonfixedSubwindowsPresent()));
            }
            case WindowEditorField.INTERNAL_COLOR:
            case WindowEditorField.EXTERNAL_COLOR:
                return this.addToVisible(field, isDataModificationMode(tab.webshopChargeMode) ||
                    (tab.drawingData.specification.colorIdCore != undefined
                        && tab.chosenCoreColor != undefined
                        && !tab.chosenCoreColor.cannotBeCovered));
            case WindowEditorField.EXTERNAL_COLOR_OTHER_INFO:
                const externalColor = this.generalTab.colors.find(
                    c => c.id === this.generalTab.drawingData.specification.colorIdExternal);
                return this.addToVisible(field, this.show(WindowEditorField.EXTERNAL_COLOR) && externalColor != undefined
                    && externalColor.type === ColorType.OTHER);
            case WindowEditorField.INTERNAL_COLOR_OTHER_INFO:
                const internalColor = this.generalTab.colors.find(
                    c => c.id === this.generalTab.drawingData.specification.colorIdInternal);
                return this.addToVisible(field, this.show(WindowEditorField.EXTERNAL_COLOR) && internalColor != undefined
                    && internalColor.type === ColorType.OTHER);
            case WindowEditorField.FILLING_WIDTH:
                return this.addToVisible(field, tab.commonData.fillingType === FillingType.NO_FILLING
                    && this.notEmpty(this.generalTab.systemGlazingWidthItems));
            case WindowEditorField.FILLING_NAME:
                return this.addToVisible(field, tab.commonData.fillingType === FillingType.FILLING
                    || tab.commonData.fillingType === FillingType.DECORATIVE_FILLING);
            case WindowEditorField.FILLING_NAME_EXTERNAL:
                return this.addToVisible(field, tab.commonData.fillingType === FillingType.FILLING
                    && tab.drawingData.specification.windowFunction === 'EXTERNAL'
                    && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.FILLING_NAME_INTERNAL:
                return this.addToVisible(field, tab.commonData.fillingType === FillingType.FILLING
                    && tab.drawingData.specification.windowFunction === 'INTERNAL'
                    && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.GLASS_SELECTOR:
                const visibleSelector = (tab.commonData.fillingType === FillingType.GLASS
                        || tab.commonData.fillingType === FillingType.DECORATIVE_FILLING)
                    && (tab.selectedWindowSystem
                    && !WindowSystemType.getByName(tab.selectedWindowSystem.systemType).predefinedGlazing
                    && WindowSystemType.getByName(tab.selectedWindowSystem.systemType).group !== ProductTypeGroup.TERRACE);
                this.addToVisible(WindowEditorField.GLASS, visibleSelector);
                this.addToVisible(WindowEditorField.DISTANCE_FRAME, visibleSelector && tab.commonData.glazingGlassQuantity > 1);
                return this.addToVisible(field, visibleSelector);
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
                return this.addToVisible(field, tab.offerContainsGrills && this.notEmpty(tab.fillingTypesGlassOnly));
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS: {
                const windowSystem = this.getWindowSystem();
                return this.addToVisible(field, !tab.offerContainsGrills && tab.systemDecorativeFillings != undefined
                    && tab.systemDecorativeFillings.length > 0
                    && windowSystem != undefined && windowSystem.decorativeGlazingPackage != undefined
                    && this.notEmpty(tab.fillingTypes));
            }
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS: {
                const windowSystem = this.getWindowSystem();
                return this.addToVisible(field, !tab.offerContainsGrills
                    && (tab.systemDecorativeFillings == undefined || tab.systemDecorativeFillings.length === 0
                        || windowSystem == undefined || windowSystem.decorativeGlazingPackage == undefined)
                    && this.notEmpty(tab.fillingTypesWithoutDecorativeFilling));
            }
            case WindowEditorField.DECORATIVE_FILLING:
                return this.addToVisible(field, tab.commonData.fillingType === FillingType.DECORATIVE_FILLING
                    && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
                return this.addToVisible(field, this.showFillingColor(FillingColorType.EXTERNAL));
            case WindowEditorField.FILLING_INTERNAL_COLOR:
                return this.addToVisible(field, this.showFillingColor(FillingColorType.INTERNAL));
            case WindowEditorField.GRILL:
                return this.addToVisible(field, this.containsMuntins(tab.drawingData));
            case WindowEditorField.MULLION:
                return this.addToVisible(field, this.containsMullions(tab.drawingData));
            case WindowEditorField.VIEW: {
                const windowSystem = this.getWindowSystem();
                return this.addToVisible(field, windowSystem != undefined && windowSystem.material !== MaterialType[MaterialType.PCV]
                    && this.notEmpty(fieldContent.getItems(field)));
            }
            case WindowEditorField.TERRACE_GLAZING_PACKAGE:
                return this.addToVisible(field, this.isTerraceSystem() && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.TERRACE_COLOR: {
                return this.addToVisible(field, this.isTerraceSystem());
            }
            case WindowEditorField.WINDOW_FUNCTION: {
                return this.addToVisible(field, !this.isTerraceSystem() && this.notEmpty(fieldContent.getItems(field)));
            }
            case WindowEditorField.GLAZING_BEAD: {
                const windowSystemType = tab.selectedWindowSystem
                    ? WindowSystemType.getByName(tab.selectedWindowSystem.systemType)
                    : undefined;
                return windowSystemType
                    && this.addToVisible(field, !windowSystemType.predefinedGlazing
                        && this.notEmpty(this.generalTab.filteredGlazingBeads));
            }
            case WindowEditorField.PROFILE:
            case WindowEditorField.WINDOW_SYSTEM:
                return this.addToVisible(field, true);
            case WindowEditorField.CORE_COLOR:
            case WindowEditorField.SEALS_INTERNAL:
            case WindowEditorField.SEALS_EXTERNAL:
                return this.addToVisible(field, !this.isTerraceSystem() && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.WEBSHOP_GLAZING_PACKAGE:
                return this.addToVisible(field, !tab.readOnlyMode && tab.sidebarOnlyMode && tab.modelMode
                    && this.notEmpty(this.generalTab.availableWebshopGlazingPackages));
            case WindowEditorField.WEBSHOP_CHARGE_NAME:
                return this.addToVisible(field, isDataModificationMode(tab.webshopChargeMode));
            case WindowEditorField.TERRACE_HANDLE:
                return this.addToVisible(field, this.isTerraceSystem() && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.TERRACE_HANDLE_LAYOUT:
                return this.addToVisible(field, this.show(WindowEditorField.TERRACE_HANDLE)
                    && tab.drawingData.specification.terraceHandle != undefined
                    && tab.drawingData.specification.terraceHandle.addonId != undefined && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.CONSTRUCTIONAL_MULLION:
                return this.addToVisible(field, (tab.sidebarOnlyMode ||
                        (tab.drawingData.specification.constructionalMullionId == undefined && this.constructionalMullionPresent()))
                    && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.MOVABLE_POST:
                return this.addToVisible(field, (tab.sidebarOnlyMode ||
                        (tab.drawingData.specification.movablePostId == undefined && this.movablePostPresent()))
                    && this.notEmpty(fieldContent.getItems(field)));
            case WindowEditorField.PREDEFINED_GLAZING_PACKAGE: {
                const windowSystemType = tab.selectedWindowSystem
                    ? WindowSystemType.getByName(tab.selectedWindowSystem.systemType)
                    : undefined;
                return windowSystemType && this.addToVisible(field, windowSystemType.predefinedGlazing
                       && tab.commonData.fillingType === FillingType.GLASS);
            }
            case WindowEditorField.GLAZING_PACKAGE: {
                const windowSystemType = tab.selectedWindowSystem
                    ? WindowSystemType.getByName(tab.selectedWindowSystem.systemType)
                    : undefined;
                return windowSystemType && this.addToVisible(field, windowSystemType.predefinedGlazing
                    && tab.commonData.fillingType === FillingType.GLASS);
            }
            default:
                return this.addToVisible(field, true);
        }
    }

    private addToVisible(field: WindowEditorField, visible: boolean): boolean {
        if (visible) {
            if (!this.generalTab.visibleFields.some(f => f === field)) {
                this.generalTab.visibleFields.push(field);
            }
        }
        return visible;
    }

    public isTerraceSystem(): boolean {
        const windowSystem = this.getWindowSystem();
        return windowSystem != null && WindowSystemTypeEnum.TERRACE === windowSystem.systemType;
    }

    public showSection(section: SidebarSection): boolean {
        let tab = this.generalTab;

        switch (section) {
            case SidebarSection.CONFIGURABLE_ADDONS:
                return !tab.sidebarOnlyMode && ConfigurableAddonUtils.addonsPresent(tab.drawingData);
            case SidebarSection.DESCRIPTION:
                return !tab.sidebarOnlyMode;
            case SidebarSection.ADDONS:
                return !tab.readOnlyMode || this.notEmpty(tab.drawingData.addons);
            case SidebarSection.COLOR:
                return this.anyChildFieldShown(section) && tab.webshopChargeMode !== DataModificationTarget.WEBSHOP_CHARGE;
            case SidebarSection.FILLING:
                return this.anyChildFieldShown(section) && !isDataModificationMode(tab.webshopChargeMode);
            default:
                return this.anyChildFieldShown(section);
        }
    }

    public required(field: WindowEditorField, forceCheck?: boolean) {
        if (!this.generalTab || (!forceCheck && !this.generalTab.validateAll)) {
            return false;
        }

        let tab = this.generalTab;
        switch (field) {
            /* Main */
            case WindowEditorField.QUANTITY:
                return tab.offerPosition.quantity == undefined;
            case WindowEditorField.WINDOW_SYSTEM:
                return tab.drawingData.windowSystemId == undefined;
            case WindowEditorField.WIDTH:
                return tab.totalWidth == undefined;
            case WindowEditorField.HEIGHT:
                return tab.totalHeight == undefined;
            case WindowEditorField.PROFILE:
                return tab.drawingData.specification.frameProfileId == undefined;
            case WindowEditorField.CONSTRUCTIONAL_MULLION:
                return tab.drawingData.specification.constructionalMullionId == undefined;
            case WindowEditorField.MOVABLE_POST:
                return tab.drawingData.specification.movablePostId == undefined;
            case WindowEditorField.WELD_TYPE:
                return tab.drawingData.specification.weldType.addonId == undefined;
            case WindowEditorField.FRAME_ENHANCEMENT:
                return tab.drawingData.specification.frameEnhancement.addonId == undefined;
            case WindowEditorField.VIEW:
                return tab.drawingData.view == undefined;
            case WindowEditorField.COVERS:
                return tab.drawingData.specification.cover.addonId == undefined;
            case WindowEditorField.MILLINGS:
            case WindowEditorField.MILLINGS_NORWEGIAN:
            case WindowEditorField.UNDER_WINDOW_BEAD:
                return false;
            case WindowEditorField.HANDLES:
                return tab.drawingData.specification.handleType.addonId == undefined;
            case WindowEditorField.TERRACE_HANDLE:
                return tab.drawingData.specification.terraceHandle.addonId == undefined;
            case WindowEditorField.TERRACE_HANDLE_LAYOUT:
                return tab.drawingData.specification.terraceHandle != undefined
                    && tab.drawingData.specification.terraceHandle.addonId != undefined
                    && tab.drawingData.specification.terraceHandleLayout == undefined;
            case WindowEditorField.DOORSTEP:
                return !this.getWindowSystem().standardDoorstepAvailable && tab.drawingData.specification.doorstepId == undefined;
            case WindowEditorField.UNDERWINDOW_PROFILE:
                return false;
            case WindowEditorField.OPENING:
                return tab.drawingData.specification.opening == undefined;
            case WindowEditorField.SEALS_EXTERNAL:
                return tab.drawingData.specification.sealExternalId == undefined;
            case WindowEditorField.SEALS_INTERNAL:
                return tab.drawingData.specification.sealInternalId == undefined;
            case WindowEditorField.TERRACE_GLAZING_PACKAGE:
                return tab.drawingData.specification.terraceGlazingPackageId == undefined;
            case WindowEditorField.CHANNEL_SECTION:
                return tab.drawingData.specification.channelSectionId == undefined;

            /* Color */
            case WindowEditorField.TERRACE_COLOR:
                return (tab.drawingData.specification.colorIdInternal == undefined || tab.drawingData.specification.colorIdExternal == undefined)
                    && this.isTerraceSystem();
            case WindowEditorField.CORE_COLOR:
                return tab.drawingData.specification.colorIdCore == undefined && !this.isTerraceSystem();
            case WindowEditorField.EXTERNAL_COLOR:
                return tab.drawingData.specification.colorIdExternal == undefined && tab.coreColorRequireCover();
            case WindowEditorField.INTERNAL_COLOR:
                return tab.drawingData.specification.colorIdInternal == undefined && tab.coreColorRequireCover();
            case WindowEditorField.EXTERNAL_COLOR_OTHER_INFO:
                const externalColor = this.generalTab.colors.find(
                    c => c.id === this.generalTab.drawingData.specification.colorIdExternal);
                return externalColor != undefined && externalColor.type === ColorType.OTHER
                    && !tab.drawingData.specification.colorOtherInfoExternal;
            case WindowEditorField.INTERNAL_COLOR_OTHER_INFO:
                const internalColor = this.generalTab.colors.find(
                    c => c.id === this.generalTab.drawingData.specification.colorIdInternal);
                return internalColor != undefined && internalColor.type === ColorType.OTHER
                    && !tab.drawingData.specification.colorOtherInfoInternal;

            /* Filling */
            case WindowEditorField.FILLING_TYPE:
            case WindowEditorField.FILLING_WIDTH:
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
            case WindowEditorField.DECORATIVE_FILLING:
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
            case WindowEditorField.FILLING_INTERNAL_COLOR:
            case WindowEditorField.GLAZING_PACKAGE:
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
                return false;
            case WindowEditorField.GLASS_SELECTOR:
                return tab.modelMode && this.glassSelectorContainsErrors(tab.commonData, tab.glassSelection.currentGlazingWidth, tab.glassSelection.glazingWidths);
            case WindowEditorField.GLAZING_BEAD: {
                const windowSystemType = WindowSystemType.getByName(tab.selectedWindowSystem.systemType);
                return tab.modelMode
                    && tab.commonData.glazingBeadId == undefined
                    && windowSystemType
                    && !windowSystemType.predefinedGlazing;
            }

            /* Grills */
            case WindowEditorField.GRILL:
            case WindowEditorField.GRILL_COLOR:
                return false;

            /* Mullions */
            case WindowEditorField.MULLION:
                return false;

            /* Fittings */
            case WindowEditorField.FITTING_ESPAGNOLETTE_TYPE:
                return this.show(WindowEditorField.FITTING_ESPAGNOLETTE_TYPE)
                    && tab.drawingData.specification.fittingEspagnoletteType.addonId == undefined;
            case WindowEditorField.FITTING_MAIN_INSERTION:
                return this.show(WindowEditorField.FITTING_MAIN_INSERTION)
                    && tab.drawingData.specification.fittingMainInsertion.addonId == undefined;
            case WindowEditorField.FITTING_BRAKE:
            case WindowEditorField.FITTING_SLIDING:
            case WindowEditorField.FITTING_TYPE:
            case WindowEditorField.FITTING_VERANDA:
            case WindowEditorField.FITTING_INSERTION:
            case WindowEditorField.FITTING_ADDITIONAL_INSERTION:
            case WindowEditorField.FITTING_LOCK:
            case WindowEditorField.FITTING_LOCK_TERRACE:
            case WindowEditorField.FITTING_AUTOMATIC_DRIVE:
                return false;
            case WindowEditorField.FITTING_LOCK_TERRACE_LOCATION:
                return tab.drawingData.specification.fittingLockTerrace != null
                    && tab.drawingData.specification.fittingLockTerrace.addonId != null
                    && tab.drawingData.specification.fittingLockTerraceLocation == null;
            case WindowEditorField.WEBSHOP_GLAZING_PACKAGE:
                return tab.modelMode && tab.selectedWebshopGlazingPackageId == undefined;
            default:
                return true;
        }
    }

    public showAndRequire(field: WindowEditorField): boolean {
        return this.show(field) && this.required(field);
    }

    protected showFillingColor(colorType: FillingColorType): boolean {
        if (this.generalTab.commonData.fillingType === FillingType.FILLING ||
            this.generalTab.commonData.fillingType === FillingType.DECORATIVE_FILLING) {
            const coreColor = this.generalTab.getColorsForFilling(FillingColorType.CORE)[0];
            return coreColor != undefined && !coreColor.cannotBeCovered && this.notEmpty(this.generalTab.getColorsForFilling(colorType));
        }
        return false;
    }

    private nonfixedSubwindowsPresent(): boolean {
        return WindowCalculator.hasNonFixedSubwindows(this.generalTab.drawingData);
    }

    private getWindowSystem(): WindowSystemDefinition {
        return this.generalTab.getWindowSystem();
    }

    private showFittingField(availableElements: any[]): boolean {
        return this.notEmpty(availableElements) && (this.nonfixedSubwindowsPresent() || this.generalTab.sidebarOnlyMode);
    }

    public containsMuntins(data: DrawingData): boolean {
        return data.windows.some(w => w.subWindows.some(s => GrillHelper.subwindowContainsGrills(s)));
    }

    public containsMullions(data: DrawingData): boolean {
        return data.windows.some(w => w.subWindows.some(s => s.mullions != undefined && s.mullions.length > 0));
    }

    private constructionalMullionPresent(): boolean {
        return ProfileCompositionUtils.constructionalMullionPresent(this.generalTab.drawingData);
    }

    private movablePostPresent(): boolean {
        return ProfileCompositionUtils.hasMovablePost(this.generalTab.drawingData);
    }

    private notEmpty(availableElements: any[]): boolean {
        return availableElements != undefined && availableElements.length > 0;
    }

    private anyChildFieldShown(section: SidebarSection): boolean {
        return GeneralTabFieldUsage.sectionFields.get(section).some(field => this.show(field));
    }

    sectionHasErrors(section: SidebarSection): boolean {
        if (GeneralTabFieldUsage.sectionFields.get(section).some(field => this.showAndRequire(field))) {
            return true;
        }
        if (isDataModificationMode(this.generalTab.webshopChargeMode)) {
            if (section === SidebarSection.MAIN) {
                return this.generalTab.webshopChargeValidationErrors[`name[${this.generalTab.translate.currentLang}]`] != undefined;
            }
        }
        if (this.generalTab.modelMode) {
            if (section === SidebarSection.FILLING) {
                return this.generalTab.webshopChargeValidationErrors['value.fillingType'] != undefined;
            }
        }
        return false;
    }

    public getAllErrors(): string[] {
        let errors: string[] = [];
        Array.from(GeneralTabFieldUsage.sectionFields.entries()).forEach(entry => {
            entry[1].forEach(field => {
                if (this.showAndRequire(field)) {
                    errors.push('OFFER.TABS.ERROR.NOT_SET.' + WindowEditorField[field]);
                    if (this.fieldToFocusOnError === undefined) {
                        this.fieldToFocusOnError = field;
                    }
                }
            });
        });
        errors.push(...Object.values(this.generalTab.webshopChargeValidationErrors));
        return errors;
    }

    private glassSelectorContainsErrors(commonData: Glazing, currentWidth: number, glazingWidths: string): boolean {
        let glazingErrors = new GlassSelectionValidator().validate(commonData);

        return ValidationErrorsHelper.validationErrorsPresent(glazingErrors)
            || GlazingHelper.isGlazingWithOutOfRange(GlazingHelper.parseGlazingWidths(glazingWidths), currentWidth);
    }
}

export class SectionInfo {
    sections: SidebarSection[];
    fields: WindowEditorField[];
    grillParams: Grill[];
}

export class SubWindowFieldUsage {

    fieldToFocusOnError: string;
    fieldsWithUnavailableValues: string[];

    constructor(private subwindowComponent: SubwindowComponent) {
    }

    private getSectionInfo(section: SidebarSection, area: AreaSpecification): SectionInfo {
        let sections: SidebarSection[] = [];
        let fields: WindowEditorField[] = [];
        let grillParams: Grill[] = [null];

        switch (section) {
            case SidebarSection.MAIN:
                fields = [WindowEditorField.BUSINESS_TYPE,
                    WindowEditorField.DIMENSIONS,
                    WindowEditorField.SHAPE_TYPE_NOT_RECTANGULAR,
                    WindowEditorField.SHAPE_TYPE_ARC,
                    WindowEditorField.SHAPE_TYPE,
                    WindowEditorField.VENTILATOR,
                    WindowEditorField.DRIP,
                    WindowEditorField.COUPLER];
                break;
            case SidebarSection.AREA:
                sections = [SidebarSection.FILLING, SidebarSection.MUNTINS];
                break;
            case SidebarSection.MULLIONS:
                fields = [WindowEditorField.MULLION];
                grillParams = this.subwindowComponent.windowData.mullions;
                break;
            case SidebarSection.FILLING:
                fields = [
                    WindowEditorField.FILLING_TYPE_W_MUNTINS,
                    WindowEditorField.FILLING_TYPE_WO_MUNTINS,
                    WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS,
                    WindowEditorField.FILLING_WIDTH,
                    WindowEditorField.FILLING_NAME_EXTERNAL,
                    WindowEditorField.FILLING_NAME_INTERNAL,
                    WindowEditorField.DECORATIVE_FILLING,
                    WindowEditorField.FILLING_EXTERNAL_COLOR,
                    WindowEditorField.FILLING_INTERNAL_COLOR,
                    WindowEditorField.GLASS_SELECTOR,
                    WindowEditorField.GLAZING_BEAD,
                    WindowEditorField.GLAZING_PACKAGE,
                    WindowEditorField.GLAZING_PACKAGE_CATEGORY,
                    WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY,
                    WindowEditorField.GLAZING_PACKAGE_QUANTITY
                ];
                break;
            case SidebarSection.MUNTINS:
                fields = [WindowEditorField.GRILL];
                grillParams = area.grills;
                break;
        }
        return {sections: sections, fields: fields, grillParams: grillParams};
    }

    public show(field: WindowEditorField, area?: AreaSpecification): boolean {
        if (!this.subwindowComponent) {
            return false;
        }

        let sub = this.subwindowComponent;

        switch (field) {
            /* Main */
            case WindowEditorField.BUSINESS_TYPE:
            case WindowEditorField.DIMENSIONS:
                return this.addToVisible(field, !sub.sidebarOnlyMode);
            case WindowEditorField.SHAPE_TYPE:
                return this.addToVisible(field, this.subwindowComponent.windowData.shape === SubWindowShape.NOT_RECTANGULAR
                    || this.subwindowComponent.windowData.shape === SubWindowShape.ARC);
            case WindowEditorField.SHAPE_TYPE_NOT_RECTANGULAR:
                return this.addToVisible(field, this.subwindowComponent.windowData.shape === SubWindowShape.NOT_RECTANGULAR);
            case WindowEditorField.SHAPE_TYPE_ARC:
                return this.addToVisible(field, this.subwindowComponent.windowData.shape === SubWindowShape.ARC);
            case WindowEditorField.VENTILATOR:
            case WindowEditorField.DRIP:
                return this.addToVisible(field, this.subwindowComponent.windowData.shape !== SubWindowShape.ARC);
            case WindowEditorField.COUPLER:
                return this.addToVisible(field, this.subwindowComponent.windowData.shape !== SubWindowShape.ARC
                    && this.subwindowComponent.windowData.typeCode === SubWindowTypeCode.F);

            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
                return this.addToVisible(field, GrillHelper.areaHasGrills(area) && sub.windowData.areasSpecification != undefined);
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS: {
                const windowSystem = this.subwindowComponent.getWindowSystem();
                return this.addToVisible(field, !GrillHelper.areaHasGrills(area) && sub.windowData.areasSpecification != undefined &&
                    sub.systemDecorativeFillings != undefined && sub.systemDecorativeFillings.length > 0 &&
                    windowSystem != undefined && windowSystem.decorativeGlazingPackage != undefined &&
                    (sub.windowData.mullions != undefined && sub.windowData.mullions.length === 0));
            }
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS: {
                const windowSystem = this.subwindowComponent.getWindowSystem();
                return this.addToVisible(field, !GrillHelper.areaHasGrills(area) && sub.windowData.areasSpecification != undefined &&
                    (sub.systemDecorativeFillings == undefined || sub.systemDecorativeFillings.length === 0 ||
                        windowSystem == undefined || windowSystem.decorativeGlazingPackage == undefined ||
                        (sub.windowData.mullions != undefined && sub.windowData.mullions.length > 0)));
            }
            case WindowEditorField.FILLING_WIDTH:
                return this.addToVisible(field, area.filling.type === FillingType.NO_FILLING);
            case WindowEditorField.FILLING_NAME:
                return this.addToVisible(field, area.filling.type === FillingType.FILLING ||
                    area.filling.type === FillingType.DECORATIVE_FILLING);
            case WindowEditorField.FILLING_NAME_EXTERNAL:
                return this.addToVisible(field, area.filling.type === FillingType.FILLING &&
                    sub.drawingData.specification.windowFunction === 'EXTERNAL');
            case WindowEditorField.FILLING_NAME_INTERNAL:
                return this.addToVisible(field, area.filling.type === FillingType.FILLING &&
                    sub.drawingData.specification.windowFunction === 'INTERNAL');
            case WindowEditorField.DECORATIVE_FILLING:
                return this.addToVisible(field, area.filling.type === FillingType.DECORATIVE_FILLING);
            case WindowEditorField.FILLING_INTERNAL_COLOR:
                if (area.filling.type === FillingType.FILLING || area.filling.type === FillingType.DECORATIVE_FILLING) {
                    const coreColor = this.subwindowComponent.getColorsForFilling(area, FillingColorType.CORE)[0];
                    if (coreColor != undefined) {
                        return this.addToVisible(field, this.showFillingColor(area, FillingColorType.INTERNAL)
                            && !coreColor.cannotBeCovered);
                    }
                }
                return false;
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
                if (area.filling.type === FillingType.FILLING || area.filling.type === FillingType.DECORATIVE_FILLING) {
                    const coreColor = this.subwindowComponent.getColorsForFilling(area, FillingColorType.CORE)[0];
                    if (coreColor != undefined) {
                        return this.addToVisible(field, this.showFillingColor(area, FillingColorType.EXTERNAL)
                            && !coreColor.cannotBeCovered);
                    }
                }
                return false;
            case WindowEditorField.GLASS_SELECTOR: {
                const windowSystemType = WindowSystemType.getByName(sub.windowSystem.systemType);
                const visibleSelector = sub.windowData.areasSpecification
                    && windowSystemType
                    && !windowSystemType.predefinedGlazing
                    && (area.filling.type === FillingType.GLASS || area.filling.type === FillingType.DECORATIVE_FILLING);
                this.addToVisible(WindowEditorField.GLASS, visibleSelector);
                this.addToVisible(WindowEditorField.DISTANCE_FRAME, visibleSelector && area.glazing.glazingGlassQuantity > 1);
                return this.addToVisible(field, visibleSelector);
            }
            case WindowEditorField.DECORATIVE_FILLING_FLIP:
                let filling = (sub.systemDecorativeFillings || []).find(f => f.id === area.filling.decorativeFillingId);
                return this.addToVisible(field, filling == null ? false : DecorativeFillingUtils.typeCanBeFlipped(filling.type));
            case WindowEditorField.PREDEFINED_GLAZING_PACKAGE: {
                const windowSystemType = WindowSystemType.getByName(sub.windowSystem.systemType);
                return windowSystemType
                    && this.addToVisible(field, windowSystemType.predefinedGlazing
                    && area.filling.type === FillingType.GLASS);
            }
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE: {
                const windowSystemType = WindowSystemType.getByName(sub.windowSystem.systemType);
                return windowSystemType && this.addToVisible(field,
                    windowSystemType.predefinedGlazing);
            }
            case WindowEditorField.GLAZING_BEAD: {
                const windowSystemType = WindowSystemType.getByName(sub.windowSystem.systemType);
                return windowSystemType && this.addToVisible(field, !windowSystemType.predefinedGlazing);
            }
            default:
                return this.addToVisible(field, true);
        }
    }

    private addToVisible(field: WindowEditorField, visible: boolean): boolean {
        if (visible) {
            if (!this.subwindowComponent.visibleFields.some(f => f === field)) {
                this.subwindowComponent.visibleFields.push(field);
            }
        }
        return visible;
    }

    public showSection(section: SidebarSection, area: AreaSpecification) {
        if (!this.subwindowComponent) {
            return false;
        }

        switch (section) {
            case SidebarSection.MULLIONS:
                return this.subwindowComponent.windowData.mullions.length > 0;
            case SidebarSection.MUNTINS:
                return GrillHelper.areaHasGrills(area);
            default:
                return this.anyChildFieldShown(section, area);
        }
    }

    public required(field: WindowEditorField, area: AreaSpecification, grill?: Grill): boolean {
        if (!this.subwindowComponent || !this.subwindowComponent.validateAll) {
            return false;
        }
        const windowSystemType = this.subwindowComponent.windowSystem ? WindowSystemType.getByName(this.subwindowComponent.windowSystem.systemType) : null;
        let windowsWithPredefinedGlazings = windowSystemType && this.addToVisible(field, windowSystemType.predefinedGlazing);
        let swData = this.subwindowComponent.windowData;
        switch (field) {
            /* Main */
            case WindowEditorField.BUSINESS_TYPE:
                return swData.typeCode == undefined;
            case WindowEditorField.DIMENSIONS:
                return swData.points == undefined;
            case WindowEditorField.SHAPE_TYPE_NOT_RECTANGULAR:
            case WindowEditorField.SHAPE_TYPE_ARC:
            case WindowEditorField.SHAPE_TYPE:
            case WindowEditorField.VENTILATOR:
            case WindowEditorField.DRIP:
            case WindowEditorField.COUPLER:
                return false;

            /* Mullions */
            case WindowEditorField.MULLION:
                return grill == undefined || grill.id == undefined;

            /* Filling */
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
                return area.filling.type == undefined;
            case WindowEditorField.FILLING_WIDTH:
                return area.filling.width == undefined;
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
                return area.filling.fillingId == undefined;
            case WindowEditorField.DECORATIVE_FILLING:
                return area.filling.decorativeFillingId == undefined;
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
                return area.filling.externalColorId == undefined
                    && (this.fillingColorMustBeCovered(area) || area.filling.internalColorId == undefined);
            case WindowEditorField.FILLING_INTERNAL_COLOR:
                return area.filling.internalColorId == undefined
                    && (this.fillingColorMustBeCovered(area) || area.filling.externalColorId == undefined);
            case WindowEditorField.GLASS_SELECTOR:
                return this.glassSelectorContainsErrors(area);
            case WindowEditorField.GLAZING_BEAD:
                return area.glazingBead.id == undefined;

            /* Grills */
            case WindowEditorField.GRILL:
                return grill != undefined && grill.id == undefined;
            case WindowEditorField.GRILL_COLOR:
                return grill != undefined && grill.colorId == undefined;

            /* Predefined glazing packages */
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
                return windowsWithPredefinedGlazings && area.filling.type === FillingType.GLASS
                    && !area.glazing.glazingGlassQuantity;
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
                return windowsWithPredefinedGlazings && area.filling.type === FillingType.GLASS
                    && !area.glazingCategoryId;
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
                return windowsWithPredefinedGlazings && area.filling.type === FillingType.GLASS
                    && !area.glazingFrameCategoryId;
            case WindowEditorField.GLAZING_PACKAGE:
                return windowsWithPredefinedGlazings && area.filling.type === FillingType.GLASS
                    && !area.glazingPackageId;
            default:
                return true;
        }
    }

    fillingColorMustBeCovered(area: AreaSpecification): boolean {
        if (area.filling.type === FillingType.GLASS || area.filling.type === FillingType.NO_FILLING) {
            return false;
        } else {
            let coreColorId = this.subwindowComponent.drawingData.specification.colorIdCore;

            if (coreColorId == undefined) {
                return false;
            }
            let coreColors = this.subwindowComponent.getColorsForFilling(area, FillingColorType.CORE);
            let usedColor = coreColors.find(c => c.id === coreColorId);
            return usedColor != null && usedColor.mustBeCovered;
        }
    }

    public showAndRequire(field: WindowEditorField, area?: AreaSpecification, grill?: Grill): boolean {
        let show = this.show(field, area);
        let required = this.required(field, area, grill);
        return show && required;
    }

    protected showFillingColor(area: AreaSpecification, colorType: FillingColorType): boolean {
        return this.subwindowComponent.getColorsForFilling(area, colorType).length > 0;
    }

    private glassSelectorContainsErrors(area: AreaSpecification): boolean {
        let glazingErrors = new GlassSelectionValidator().validate(area.glazing);
        return ValidationErrorsHelper.validationErrorsPresent(glazingErrors);
    }

    private anyChildFieldShown(section: SidebarSection, area?: AreaSpecification): boolean {
        let sectionInfo = this.getSectionInfo(section, area);
        return sectionInfo.fields.some(field => this.show(field)) ||
            sectionInfo.sections.some(s => this.anyChildFieldShown(s));
    }

    sectionHasErrors(section: SidebarSection, area?: AreaSpecification): boolean {
        let sectionInfo = this.getSectionInfo(section, area);
        return sectionInfo.fields.some(field => sectionInfo.grillParams.some(grill => this.showAndRequire(field, area, grill))) ||
            sectionInfo.sections.some(s => this.sectionHasErrors(s, area));
    }

    getAllSectionErrors(section: SidebarSection, area?: AreaSpecification): GrowlMessage[] {
        let errors: GrowlMessage[] = [];
        let sectionInfo = this.getSectionInfo(section, area);
        errors.push(..._.flatten(sectionInfo.sections.map(s => this.getAllSectionErrors(s, area))));
        sectionInfo.fields.forEach(
            field => {
                sectionInfo.grillParams.forEach(grill => {
                    if (this.showAndRequire(field, area, grill)) {
                        let message = 'OFFER.TABS.ERROR.NOT_SET_DETAILED.' + WindowEditorField[field];
                        let params = {
                            tabIndex: (this.subwindowComponent.tabIndex + 1).toString(),
                            areaNumber: area == null ? null : area.ordinalNumber.toString(),
                            grillIndex: grill == null ? null :
                                (sectionInfo.grillParams.findIndex(gp => gp === grill) + 1).toString()
                        };
                        errors.push(new GrowlMessage('error', 'GENERAL.ERROR', message, params));
                    }
                });
                if (this.fieldsWithUnavailableValues.some(f => f === field) && this.fieldToFocusOnError === undefined) {
                    this.fieldToFocusOnError = field;
                }
            }
        );
        return errors;
    }

    public getAllErrors(fieldsWithUnavailableValues: string[]): GrowlMessage[] {
        this.fieldsWithUnavailableValues = fieldsWithUnavailableValues;
        let errors: GrowlMessage[] = [];
        errors.push(...this.getAllSectionErrors(SidebarSection.MAIN));
        errors.push(...this.getAllSectionErrors(SidebarSection.MULLIONS));
        errors.push(..._.flatten(this.subwindowComponent.windowData.areasSpecification.map(
            area => this.getAllSectionErrors(SidebarSection.AREA, area))));
        return errors;
    }

    private notEmpty(availableElements: any[]): boolean {
        return availableElements != undefined && availableElements.length > 0;
    }
}

export class RoofWindowFieldUsage {

    constructor(private editor: RoofWindowEditorComponent) {
    }

    public show(field: WindowEditorField): boolean {
        if (!this.editor) {
            return false;
        }

        const fieldContent = this.editor.windowEditorFieldContentProvider;
        switch (field) {
            case WindowEditorField.FLASHING:
                return this.addToVisible(field, this.editor.availableFlashings.length > 0);
            case WindowEditorField.FLASHING_AKP:
                return this.addToVisible(field, this.editor.availableAKPFlashings.length > 0);
            case WindowEditorField.MODEL:
                return this.addToVisible(field, this.editor.isEntrance && this.editor.selectedModel != null);
            case WindowEditorField.GLAZING_PACKAGE:
            case WindowEditorField.PROFILE:
            case WindowEditorField.OPENING:
            case WindowEditorField.EXTERNAL_COLOR:
            case WindowEditorField.INTERNAL_COLOR:
            case WindowEditorField.ENTRANCE_DOOR_FITTING:
                return this.addToVisible(field, this.editor.isEntrance && this.notEmpty(fieldContent.getItems(field)));
            default:
                return this.addToVisible(field, true);
        }
    }

    private addToVisible(field: WindowEditorField, visible: boolean): boolean {
        if (visible) {
            if (!this.editor.visibleFields.some(f => f === field)) {
                this.editor.visibleFields.push(field);
            }
        }
        return visible;
    }

    public required(field: WindowEditorField): boolean {
        if (!this.editor) {
            return false;
        }

        let entranceData: EntranceDoorData;
        if (this.editor.isEntranceData(this.editor.data)) {
            entranceData = <EntranceDoorData> this.editor.data;
        }

        const fieldContent = this.editor.windowEditorFieldContentProvider;
        switch (field) {
            case WindowEditorField.GLAZING_PACKAGE:
                return this.editor.isEntranceData(this.editor.data) ? false : this.editor.data.glazingPackageId == undefined;
            case WindowEditorField.DIMENSIONS:
                return this.editor.data.dimensionsId == undefined;
            case WindowEditorField.WIDTH:
                return entranceData && WindowDimensionsUtils.isOverriden(entranceData.dimensionsId) &&
                    entranceData.width == undefined;
            case WindowEditorField.HEIGHT:
                return entranceData && WindowDimensionsUtils.isOverriden(entranceData.dimensionsId) &&
                    entranceData.height == undefined;
            case WindowEditorField.PROFILE:
                return entranceData && entranceData.frameProfileId == undefined;
            case WindowEditorField.OPENING:
                return entranceData && entranceData.opening == undefined && this.notEmpty(fieldContent.getItems(field));
            case WindowEditorField.INTERNAL_COLOR:
                return entranceData && entranceData.internalColorId == undefined;
            case WindowEditorField.EXTERNAL_COLOR:
                return entranceData && entranceData.externalColorId == undefined;
            case WindowEditorField.ENTRANCE_DOOR_FITTING:
                return entranceData && (entranceData.entranceLock == undefined || entranceData.entranceLock.addonId == undefined);
            default:
                return false;
        }
    }

    public showSection(section: SidebarSection) {
        if (!this.editor) {
            return false;
        }

        switch (section) {
            case SidebarSection.ADDONS:
                return !this.editor.readOnlyMode || this.editor.addedWindowAddons.length > 0;
            default:
                return true;
        }
    }

    private notEmpty(availableElements: any[]): boolean {
        return availableElements != undefined && availableElements.length > 0;
    }
}
