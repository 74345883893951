<div class="new-form-60vw">
    <p-accordion>
        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.OPTIONAL_OFFER_PRINT_HEADER' | translate }}"
                        [(selected)]="optionalOfferPrintHeaderTabSelected">
            <p-tabView>
                <p-tabPanel *ngFor="let lang of supportedLanguages" header="{{ lang.name | translate }}">
                    <p-editor [id]="'optionalOfferPrintHeader_' + lang.code"
                              [disabled]="disabled" [ngModel]="htmlText[lang.code]"
                              (ngModelChange)="handleTextChange(lang.code, $event)"
                              [style]="{'height':'200px', 'border': '1px solid #E8E8E8'}">
                    </p-editor>
                </p-tabPanel>
            </p-tabView>
        </p-accordionTab>
    </p-accordion>
</div>
