import {DateRangeFilter} from "../../../common/date-range-filter";
import {User} from "../../user/user";

export enum UserActivityReportCriteriaVariable {
    LOGIN_COUNT = 'LOGIN_COUNT',
    OFFER_COUNT = 'OFFER_COUNT',
    ORDER_COUNT = 'ORDER_COUNT',
    OFFER_CONVERSION_RATIO = 'OFFER_CONVERSION_RATIO'
}

export enum UserActivityReportCriteriaComparisonOperator {
    EQ = 'EQ',
    NE = 'NE',
    LT = 'LT',
    LE = 'LE',
    GT = 'GT',
    GE = 'GE'
}

export enum UserActivityReportCriteriaLogicOperator {
    AND = 'AND',
    OR = 'OR'
}

export interface UserActivityReportCriteria {
    variable: UserActivityReportCriteriaVariable;
    operator: UserActivityReportCriteriaComparisonOperator;
    value: number;
}

export interface UserActivityReportCriteriaTree {
    parent?: UserActivityReportCriteriaTree;
    logic: UserActivityReportCriteriaLogicOperator;
    children: UserActivityReportCriteriaTree[];
    criteria: UserActivityReportCriteria;
}

export interface UserActivityCustomFilter {
    criteriaTreeDateRange: DateRangeFilter,
    criteriaTree: string;
}

export class UserWithActivityData extends User {
    lastActivityTime: Date;
    lastLoginTime: Date;
}

export class UserWithActivityDataList {
    data: UserWithActivityData[];
    totalRecords: number;
}
