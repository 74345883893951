import {Component, Input, OnInit} from "@angular/core";
import {MessageSeverity, PositionMessage} from '../../offer/offers/message';

@Component({
    selector: 'app-pricing-message',
    templateUrl: './pricing-message.component.html'
})
export class PricingMessageComponent implements OnInit {

    @Input()
    message: PositionMessage;

    @Input()
    ignoreFieldName = false;

    class: string;

    static getFieldNameTranslationsCode(fieldName): string {
        return 'OFFER.TABS.SECTION.' + fieldName;
    }

    static getClassName(severity: MessageSeverity): string {
        switch (severity) {
            case MessageSeverity.BLOCKER:
            case MessageSeverity.ERROR:
                return 'new-form-field-error-message';
            case MessageSeverity.WARNING:
                return 'new-form-field-warning-message';
            case MessageSeverity.INFO:
            case MessageSeverity.CODE:
                return 'new-form-field-info-message';
            default:
                return '';
        }
    }

    ngOnInit(): void {
        this.class = PricingMessageComponent.getClassName(this.message.severity);
    }

    getFieldName(): string {
        return PricingMessageComponent.getFieldNameTranslationsCode(this.message.fieldName);
    }

    getCreatedByRuleTranslationKey(): string {
        return this.message.createdByRule ? 'PRICESHEET.TYPES.' + this.message.createdByRule : '';
    }
}
