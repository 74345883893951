import {Injectable, Type} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {AllAddons} from '../../../../window-designer/all-addons';
import {AddonInterface} from "../../../../window-designer/catalog-data/addon-interface";
import {ProfileType} from '../../../../window-designer/catalog-data/profile-interface';
import {OpeningOption} from '../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData, DrawingDataSpecification} from '../../../../window-designer/drawing-data/drawing-data';
import {FittingTerraceLockLocation} from '../../../../window-designer/drawing-data/FittingTerraceLockLocation';
import {TerraceHandleLayout} from '../../../../window-designer/drawing-data/TerraceHandleLayout';
import {WindowAddon} from '../../../../window-designer/drawing-data/WindowAddon';
import {WindowFunction} from '../../../../window-designer/drawing-data/WindowFunction';
import {WindowView} from '../../../../window-designer/drawing-data/WindowView';
import {WindowDimensions, WindowDimensionsUtils} from "../../../../window-designer/entities/window-dimensions";
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';
import {AddonCategoryEnum} from "../../../../window-designer/enums/AddonCategoryEnum";
import {AddonFor} from "../../../../window-designer/enums/AddonFor";
import {ColorType} from '../../../ColorType';
import {Listing} from "../../../common/crud-common/crudItemList";
import {WindowSystemDefaultsState} from "../../settings/system-defaults/system-default-state";
import {BusinessTypeService} from '../../window-system/business-type/business-type.service';
import {BusinessTypeBasic} from '../../window-system/business-type/BusinessTypeBasic';
import {Color} from '../../window-system/color/color';
import {ConfigSystem} from "../../window-system/config-system/config-system";
import {ConfigSystemService} from "../../window-system/config-system/config-system.service";
import {DecorativeFilling} from '../../window-system/decorative-filling/decorativeFilling';
import {DistanceFrameService} from '../../window-system/distance-frame/distance-frame.service';
import {DistanceFrame} from '../../window-system/distance-frame/distanceFrame';
import {EntranceGlazingPackageService} from "../../window-system/entrance-glazing-package/entrance-glazing-package.service";
import {GlassService} from '../../window-system/glass/glass.service';
import {GlassWithPosition} from '../../window-system/glass/glassWithPositions';
import {GlazingBead} from '../../window-system/glazing-bead/glazing-bead';
import {GlazingPackage} from '../../window-system/glazing-package/glazing-package';
import {GlazingPackageService} from '../../window-system/glazing-package/glazing-package.service';
import {WebshopGlazingPackage} from '../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package';
import {WebshopGlazingPackageService} from '../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package.service';
import {GraspDistanceFrameCategory} from "../../window-system/grasp-distance-frame-category/grasp-distance-frame-category";
import {GraspDistanceFrameCategoryService} from "../../window-system/grasp-distance-frame-category/grasp-distance-frame-category.service";
import {GraspGlazingCategory} from '../../window-system/grasp-glazing-categories/grasp-glazing-category';
import {GraspGlazingCategoryService} from '../../window-system/grasp-glazing-categories/grasp-glazing-category.service';
import {GraspGlazingPackage} from '../../window-system/grasp-glazing-package/grasp-glazing-package';
import {GraspGlazingPackageService} from '../../window-system/grasp-glazing-package/grasp-glazing-package.service';
import {Grill as GrillDto} from '../../window-system/grill/grill';
import {GrillService} from '../../window-system/grill/grill.service';
import {OtherFilling} from '../../window-system/other-filling/otherFilling';
import {Profile} from '../../window-system/profile/profile';
import {Seal} from '../../window-system/seal/Seal';
import {
    WindowDesignerCatalogDependentOption
} from '../../window-system/window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option';
import {
    WindowDesignerCatalogDependentOptionService
} from '../../window-system/window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option.service';
import {WindowDimensionsService} from '../../window-system/window-dimensions/window-dimensions.service';
import {ProductTypeGroup} from "../../window-system/window-system-definition/product-type-group";
import {WindowSystemDefinition} from '../../window-system/window-system-definition/window-system-definition';
import {WindowSystemDefinitionService} from '../../window-system/window-system-definition/window-system-definition.service';
import {WindowSystemModelService} from '../../window-system/window-system-model/window-system-model-service';
import {WindowSystemModel} from '../../window-system/window-system-model/WindowSystemModel';
import {PositionEditorFieldContentProvider} from './position-editor-field-content-provider';
import {EntranceDoorData} from './roof-window-editor/entrance-door-data';
import {GlazingPackageData} from './roof-window-editor/glazing-package-data';
import {RoofWindowData} from "./roof-window-editor/RoofWindowData";
import {SelectItemFormatters} from './sidebar/select-item-formatters';
import {SystemDefaultsService} from './system-defaults.service';
import {WindowEditorField} from './window-editor-field';

export interface CatalogLoadResult {
    colors: Color[];
    seals: Seal[];
    profiles: Profile[];
    addons: AllAddons;
    decorativeFillings: DecorativeFilling[];
    fillings: OtherFilling[];
    grills: GrillDto[];
    windowSystemDefaults: WindowSystemDefaultsState;
    glazingBeads: GlazingBead[];
    glasses: GlassWithPosition[];
    distanceFrames: DistanceFrame[];
    configurableAddonDefinitions: ConfigSystem[];
    windowSystemModel: WindowSystemModel;
    webshopGlazingPackage: WebshopGlazingPackage[];
    terraceGlazingPackage: GlazingPackage[];
    fieldDependencies: WindowDesignerCatalogDependentOption[];
    businessTypes: BusinessTypeBasic[];
    windowSystem?: WindowSystemDefinition;
    graspGlazingCategories: GraspGlazingCategory[];
    graspDistanceFrameCategories: GraspDistanceFrameCategory[];
    graspGlazingPackages: GraspGlazingPackage[];
}

export type CatalogLoadObservables = { [K in keyof CatalogLoadResult]: Observable<CatalogLoadResult[K]>; };

export interface EntranceDoorCatalogLoadResult {
    colors: Color[];
    profiles: Profile[];
    addons: AddonInterface[];
    dimensions: WindowDimensions[];
    glazingPackages: GlazingPackageData[];
    fieldDependencies: WindowDesignerCatalogDependentOption[];
}

export type EntranceDoorCatalogLoadObservables = { [K in keyof EntranceDoorCatalogLoadResult]: Observable<EntranceDoorCatalogLoadResult[K]>; };

export const WINDOW_EDITOR_FIELD_CONTENT_PROVIDER_SERVICES: Type<any>[] = [WindowSystemDefinitionService, GrillService, GlassService,
    DistanceFrameService, ConfigSystemService, SystemDefaultsService, WindowSystemModelService, GlazingPackageService,
    WebshopGlazingPackageService, WindowDesignerCatalogDependentOptionService, GraspGlazingCategoryService,
    GraspDistanceFrameCategoryService, GraspGlazingPackageService, BusinessTypeService, WindowDimensionsService,
    EntranceGlazingPackageService
];

@Injectable()
export class WindowEditorFieldContentProvider extends PositionEditorFieldContentProvider {

    private readonly selectItemFormatters: SelectItemFormatters;

    constructor(private readonly windowSystemDefinitionService: WindowSystemDefinitionService,
                private readonly grillService: GrillService,
                private readonly glassService: GlassService,
                private readonly frameService: DistanceFrameService,
                private readonly configSystemService: ConfigSystemService,
                private readonly windowSystemDefaultsService: SystemDefaultsService,
                private readonly windowSystemModelService: WindowSystemModelService,
                private readonly glazingPackageService: GlazingPackageService,
                private readonly webshopGlazingPackageService: WebshopGlazingPackageService,
                private readonly dependentOptionService: WindowDesignerCatalogDependentOptionService,
                translateService: TranslateService,
                private readonly graspGlazingCategoryService: GraspGlazingCategoryService,
                private readonly graspDistanceFrameCategoryService: GraspDistanceFrameCategoryService,
                private readonly graspGlazingPackageService: GraspGlazingPackageService,
                private readonly businessTypeService: BusinessTypeService,
                private readonly windowDimensionsService: WindowDimensionsService,
                private readonly entranceGlazingPackageService: EntranceGlazingPackageService) {
        super(Object.keys(WindowEditorField));
        this.selectItemFormatters = new SelectItemFormatters(translateService);
    }

    protected requiredValueIsCorrect(currentValue: string, requiredField: WindowEditorField, requiredValue: string): boolean {
        switch (requiredField) {
            case WindowEditorField.WIDTH_GREATER_THAN:
            case WindowEditorField.HEIGHT_GREATER_THAN:
                return +currentValue > +requiredValue;
            case WindowEditorField.WIDTH_LESS_THAN:
            case WindowEditorField.HEIGHT_LESS_THAN:
                return +currentValue < +requiredValue;
            default:
                break;
        }
        return super.requiredValueIsCorrect(currentValue, requiredField, requiredValue);
    }

    getCatalogForWindowSystemDataSources(windowSystemId: number, offerId: number | undefined, modelMode: boolean,
                                         data: DrawingData | WindowSystemDefaultsState, readOnly = false,
                                         setDefaults = false): CatalogLoadObservables {
        if (windowSystemId != undefined) {
            let defaults = setDefaults ? this.getWindowSystemDefaultsData(data) : undefined;
            if (!defaults) {
                defaults = new WindowSystemDefaults();
            }
            const spec = setDefaults ? defaults : this.getDrawingDataSpec(data);
            let extractData = <T>(listingDto: Listing<T>) => listingDto.data;
            let notUndefined = <T>(val: T) => val != undefined;
            const selectedProfiles: number[] = (spec != undefined ? [spec.frameProfileId, spec.constructionalMullionId, spec.doorstepId,
                spec.channelSectionId, spec.movablePostId, ...this.getSelectedMullionId(this.getDrawingData(data), setDefaults)] : [])
                .filter(notUndefined);
            const selectedColors: number[] = this.getSelectedColors(data, setDefaults).filter(notUndefined);
            const selectedSeals: number[] = setDefaults ? [defaults.sealColorInternalId, defaults.sealColorExternalId].filter(notUndefined)
                : (spec != undefined
                    ? [this.getDrawingDataSpec(data).sealInternalId, this.getDrawingDataSpec(data).sealExternalId].filter(notUndefined)
                    : []);
            const selectedAddons: number[] = this.getSelectedAddons(data, setDefaults).filter(notUndefined);
            return {
                colors: this.windowSystemDefinitionService.getSystemsColors(windowSystemId, selectedColors, readOnly)
                    .pipe(map(extractData)),
                seals: this.windowSystemDefinitionService.getSystemsSeals(windowSystemId, selectedSeals, readOnly).pipe(map(extractData)),
                profiles: this.windowSystemDefinitionService.getSystemsProfiles(windowSystemId, selectedProfiles, readOnly)
                    .pipe(map(extractData)),
                addons: this.windowSystemDefinitionService.getSystemsAddOns(windowSystemId, selectedAddons, readOnly).pipe(map(value => {
                    const allAddons = new AllAddons();
                    allAddons.fill(value.data);
                    return allAddons;
                })),
                decorativeFillings: this.windowSystemDefinitionService.getSystemsDecorativeFillings(windowSystemId,
                    this.getSelectedFillingIdByFields('decorativeFillingId', data, setDefaults), readOnly).pipe(map(extractData)),
                fillings: this.windowSystemDefinitionService.getSystemsOtherFillings(windowSystemId,
                    this.getSelectedFillingIdByFields('fillingId', data, setDefaults)).pipe(map(extractData)),
                grills: this.grillService.getGrillsForWindowSystem(windowSystemId, this.getSelectedGrillIds(data, setDefaults)),
                windowSystemDefaults: setDefaults ? of<WindowSystemDefaultsState>(this.getWindowSystemDefaults(data))
                    : this.windowSystemDefaultsService.getDefaultsForWindow(windowSystemId, offerId, modelMode),
                glazingBeads: this.windowSystemDefinitionService.getSystemsGlazingBeads(windowSystemId,
                    this.getSelectedGlazingBeadIds(data, setDefaults), readOnly).pipe(map(extractData)),
                glasses: this.glassService.getGlassesForWindowSystem(windowSystemId,
                    this.getSelectedGlazingIds(data, setDefaults, 'glass'), readOnly),
                distanceFrames: this.frameService.getDistanceFramesForWindowSystem(windowSystemId,
                    this.getSelectedGlazingIds(data, setDefaults, 'frame'), readOnly),
                configurableAddonDefinitions: this.configSystemService.getSystemsConfigs(windowSystemId),
                windowSystemModel: modelMode ?
                    this.windowSystemModelService.getDefaultsFor(windowSystemId) :
                    of<WindowSystemModel>(undefined),
                webshopGlazingPackage: modelMode ?
                    this.webshopGlazingPackageService.getActiveGlazingForCurrentSubsystem(windowSystemId) :
                    of<WebshopGlazingPackage[]>([]),
                terraceGlazingPackage: this.glazingPackageService.getActiveGlazingForTerrace(windowSystemId,
                    spec != undefined ? spec.terraceGlazingPackageId : undefined, readOnly),
                fieldDependencies: this.dependentOptionService.getItems(undefined, undefined, {
                        windowSystemId: {value: windowSystemId},
                        activeSet: {value: 'true'}
                    },
                    undefined, undefined).pipe(map(extractData)),
                businessTypes: this.businessTypeService.getTypesForAddDialog(),
                graspGlazingCategories: this.graspGlazingCategoryService.getActiveItems().pipe(map(extractData)),
                graspDistanceFrameCategories: this.graspDistanceFrameCategoryService.getActiveItems(),
                graspGlazingPackages: this.graspGlazingPackageService.getActiveItems(windowSystemId,
                    this.getSelectedGlazingPackageIds(data, setDefaults, 'glazingPackageId'), readOnly),
            };
        }
        return {
            colors: of<Color[]>([]),
            seals: of<Seal[]>([]),
            profiles: of<Profile[]>([]),
            addons: of<AllAddons>(new AllAddons()),
            decorativeFillings: of<DecorativeFilling[]>([]),
            fillings: of<OtherFilling[]>([]),
            grills: of<GrillDto[]>([]),
            windowSystemDefaults: of<WindowSystemDefaultsState>(undefined),
            glazingBeads: of<GlazingBead[]>([]),
            glasses: of<GlassWithPosition[]>([]),
            distanceFrames: of<DistanceFrame[]>([]),
            configurableAddonDefinitions: of<ConfigSystem[]>([]),
            windowSystemModel: of<WindowSystemModel>(undefined),
            webshopGlazingPackage: of<WebshopGlazingPackage[]>([]),
            terraceGlazingPackage: of<GlazingPackage[]>([]),
            fieldDependencies: of<WindowDesignerCatalogDependentOption[]>([]),
            businessTypes: of<BusinessTypeBasic[]>([]),
            graspGlazingCategories: of<GraspGlazingCategory[]>([]),
            graspDistanceFrameCategories: of<GraspDistanceFrameCategory[]>([]),
            graspGlazingPackages: of<GraspGlazingPackage[]>([])
        };
    }

    getCatalogForEntranceDoorSystemDataSources(windowSystemId: number, data: EntranceDoorData,
                                               readOnly: boolean): EntranceDoorCatalogLoadObservables {
        if (windowSystemId != undefined) {
            let extractData = <T>(listingDto: Listing<T>) => listingDto.data;
            let notUndefined = <T>(val: T) => val != undefined;
            const selectedColors = data != undefined ? [data.externalColorId, data.internalColorId].filter(notUndefined) : [];
            const selectedProfiles = data != undefined ? [data.frameProfileId].filter(notUndefined) : [];
            const selectedAddons = data != undefined ? [this.getAddonId(data.entranceLock)].filter(notUndefined) : [];
            const selectedDimensions = data != undefined ? [data.dimensionsId].filter(id => id != undefined && !WindowDimensionsUtils.isOverriden(id)) : [];
            return {
                colors: this.windowSystemDefinitionService.getSystemsColors(windowSystemId, selectedColors, readOnly)
                    .pipe(map(extractData)),
                profiles: this.windowSystemDefinitionService.getSystemsProfiles(windowSystemId, selectedProfiles, readOnly)
                    .pipe(map(extractData)),
                addons: this.windowSystemDefinitionService.getSystemsAddOns(windowSystemId, selectedAddons, readOnly)
                    .pipe(map(extractData)),
                dimensions: this.windowDimensionsService.forSystem(ProductTypeGroup.ENTRANCE, windowSystemId, selectedDimensions, readOnly),
                glazingPackages: this.entranceGlazingPackageService.getPackagesForModel(data != undefined ? data.entranceModelId : undefined,
                    data != undefined ? data.glazingPackageId : undefined, readOnly),
                fieldDependencies: this.dependentOptionService.getItems(undefined, undefined, {
                        windowSystemId: {value: windowSystemId},
                        activeSet: {value: 'true'}
                    },
                    undefined, undefined).pipe(map(extractData))
            }
        }
        return {
            colors: of([] as Color[]),
            profiles: of([] as Profile[]),
            addons: of([] as AddonInterface[]),
            dimensions: of([] as WindowDimensions[]),
            glazingPackages: of([] as GlazingPackageData[]),
            fieldDependencies: of<WindowDesignerCatalogDependentOption[]>([])
        }
    }

    initCatalog(catalogData: CatalogLoadResult): void {
        this.groupAndStoreColors(catalogData.colors, false, false, null, false);
        this.groupAndStoreSeals(catalogData.seals, false, null, false);
        this.groupAndStoreProfiles(catalogData.profiles, false, null, false);
        this.groupAndStoreAddons(catalogData.addons, false, null, false);
        this.storeDecorativeFillings(catalogData.decorativeFillings, null);
        this.groupAndStoreOtherFillings(catalogData.fillings, null);
        this.groupAndStoreGrills(catalogData.grills, null, false);
        this.storeBusinessTypes(catalogData.businessTypes);
        this.storeGlazingBeads(catalogData.glazingBeads, null, false);
        this.storeGlasses(catalogData.glasses, null, false);
        this.storeDistanceFrames(catalogData.distanceFrames, null, false);
        this.storeTerraceGlazingPackages(catalogData.terraceGlazingPackage, null);
        this.storeFieldDependencies(catalogData.fieldDependencies);
    }

    initEntranceDoorCatalog(catalogData: EntranceDoorCatalogLoadResult): void {
        this.groupAndStoreColors(catalogData.colors, false, false, null, false);
        this.groupAndStoreProfiles(catalogData.profiles, false, null, false);
        this.groupAndStoreEntranceDoorAddons(catalogData.addons, null);
        this.storeWindowDimensions(catalogData.dimensions, null);
        this.storeRoofOrEntranceGlazingPackages(catalogData.glazingPackages, null);
        this.storeFieldDependencies(catalogData.fieldDependencies);
    }

    groupAndStoreColors(colors: Color[], webshopChargeMode: boolean, requireEnabledInWebshop: boolean,
                        data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean,
                        entranceData?: EntranceDoorData) {
        let activeColors = colors.filter(e => !requireEnabledInWebshop || e.webshop);
        const allActiveColors = activeColors;
        const coreColors: Color[] = [];
        const externalColors: Color[] = [];
        const internalColors: Color[] = [];
        const bothSidesColors: Color[] = [];
        const grillColors: Color[] = [];
        const ralExternalColors: Color[] = [];
        const ralInternalColors: Color[] = [];
        const ralBothSidesColors: Color[] = [];
        const ncsExternalColors: Color[] = [];
        const ncsInternalColors: Color[] = [];
        const ncsBothSidesColors: Color[] = [];
        for (let color of activeColors) {
            if (color.core) {
                coreColors.push(color);
            }
            if (color.outside && color.inside) {
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ralBothSidesColors.push(color);
                        }
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ncsBothSidesColors.push(color);
                        }
                        break;
                    default:
                        bothSidesColors.push(color);
                        break;
                }
            }
            if (color.outside) {
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ralExternalColors.push(color);
                        }
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ncsExternalColors.push(color);
                        }
                        break;
                    default:
                        externalColors.push(color);
                        break;
                }
            }
            if (color.inside) {
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ralInternalColors.push(color);
                        }
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        if (!!color.ralHex) {
                            ncsInternalColors.push(color);
                        }
                        break;
                    default:
                        internalColors.push(color);
                        break;
                }
            }
            if (color.grill) {
                grillColors.push(color);
            }
        }

        const spec = setDefaults ? this.getWindowSystemDefaultsData(data) : this.getDrawingDataSpec(data);
        this.setItems(WindowEditorField.CORE_COLOR, this.selectItemFormatters.formatColorIdOptions(coreColors,
                webshopChargeMode), spec != undefined ? [spec.colorIdCore] : []);
        this.setItems(WindowEditorField.TERRACE_COLOR,
            this.selectItemFormatters.formatColorIdOptions(bothSidesColors.concat(ralBothSidesColors, ncsBothSidesColors),
                webshopChargeMode), spec != undefined ? [spec.colorIdExternal] : []);
        this.setItems(WindowEditorField.EXTERNAL_COLOR,
            this.selectItemFormatters.formatColorIdOptions(externalColors.concat(ralExternalColors, ncsExternalColors),
                webshopChargeMode), spec != undefined
                ? [spec.colorIdExternal]
                : (entranceData != undefined
                    ? [entranceData.externalColorId]
                    : []));
        this.setItems(WindowEditorField.INTERNAL_COLOR,
            this.selectItemFormatters.formatColorIdOptions(internalColors.concat(ralInternalColors, ncsInternalColors),
                webshopChargeMode), spec != undefined
                ? [spec.colorIdInternal]
                : (entranceData != undefined
                    ? [entranceData.internalColorId]
                    : []));
        this.setItems(WindowEditorField.GRILL_COLOR,
            this.selectItemFormatters.formatColorIdOptions(grillColors, webshopChargeMode), []);

        return {
            allActiveColors,
            coreColors,
            externalColors,
            internalColors,
            bothSidesColors,
            grillColors,
            ralExternalColors,
            ralInternalColors,
            ralBothSidesColors,
            ncsExternalColors,
            ncsInternalColors,
            ncsBothSidesColors
        };
    }

    groupAndStoreSeals(seals: Seal[], webshopChargeMode: boolean, data: DrawingData | WindowSystemDefaultsState,
                       setDefaults: boolean) {
        const externalSeals: Seal[] = [];
        const internalSeals: Seal[] = [];
        for (let seal of seals) {
            if (seal.outerSeal) {
                externalSeals.push(seal);
            }
            if (seal.innerSeal) {
                internalSeals.push(seal);
            }
        }

        const defaults = this.getWindowSystemDefaultsData(data);
        const spec = this.getDrawingDataSpec(data);
        this.setItems(WindowEditorField.SEALS_EXTERNAL, this.selectItemFormatters.formatSealOptions(externalSeals, webshopChargeMode),
            [setDefaults
                ? (defaults != undefined ? defaults.sealColorExternalId : undefined)
                : (spec != undefined ? spec.sealExternalId : undefined)]);
        this.setItems(WindowEditorField.SEALS_INTERNAL, this.selectItemFormatters.formatSealOptions(internalSeals, webshopChargeMode),
            [setDefaults
                ? (defaults != undefined ? defaults.sealColorInternalId : undefined)
                : (spec != undefined ? spec.sealInternalId : undefined)]);

        return {
            externalSeals,
            internalSeals
        };
    }

    groupAndStoreProfiles(profiles: Profile[], webshopChargeMode: boolean, data: DrawingData | WindowSystemDefaultsState,
                          setDefaults: boolean, entranceData?: EntranceDoorData) {
        let groupedProfiles = _.groupBy(profiles, profile => profile.type);
        const frameProfiles = groupedProfiles[ProfileType.FRAME] || [];
        const decorativeMullions = groupedProfiles[ProfileType.DECORATIVE_MULLION] || [];
        const constructionalMullions = groupedProfiles[ProfileType.CONSTRUCTIONAL_MULLION] || [];
        const mullions = decorativeMullions.concat(constructionalMullions);
        const doorsteps = groupedProfiles[ProfileType.THRESHOLD] || [];
        const channelSections = groupedProfiles[ProfileType.CHANNEL_SECTION] || [];
        const movablePosts = groupedProfiles[ProfileType.MOVABLE_POST] || [];

        const spec = setDefaults ? this.getWindowSystemDefaultsData(data) : this.getDrawingDataSpec(data);
        this.setItems(WindowEditorField.PROFILE, this.selectItemFormatters.formatProfileOptions(frameProfiles, webshopChargeMode),
            spec != undefined
                ? [spec.frameProfileId]
                : (entranceData != undefined
                    ? [entranceData.frameProfileId]
                    : []));
        this.setItems(WindowEditorField.CONSTRUCTIONAL_MULLION,
            this.selectItemFormatters.formatProfileOptions(constructionalMullions, webshopChargeMode),
            spec != undefined ? [spec.constructionalMullionId] : []);
        this.setItems(WindowEditorField.MULLION, this.selectItemFormatters.formatProfileOptions(mullions, webshopChargeMode),
            this.getSelectedMullionId(this.getDrawingData(data), setDefaults));
        this.setItems(WindowEditorField.MULLION_ANGLED,
            this.selectItemFormatters.formatProfileOptions(constructionalMullions, webshopChargeMode),
            this.getSelectedMullionId(this.getDrawingData(data), setDefaults));
        this.setItems(WindowEditorField.DOORSTEP, this.selectItemFormatters.formatProfileOptions(doorsteps, webshopChargeMode),
            spec != undefined ? [spec.doorstepId] : []);
        this.setItems(WindowEditorField.CHANNEL_SECTION,
            this.selectItemFormatters.formatProfileOptions(channelSections, webshopChargeMode),
            spec != undefined ? [spec.channelSectionId] : []);
        this.setItems(WindowEditorField.MOVABLE_POST, this.selectItemFormatters.formatProfileOptions(movablePosts, webshopChargeMode),
            spec != undefined ? [spec.movablePostId] : []);

        return {
            profiles,
            frameProfiles,
            mullions,
            doorsteps,
            channelSections,
            constructionalMullions,
            movablePosts
        };
    }

    groupAndStoreAddons(allAddons: AllAddons, webshopChargeMode: boolean, data: DrawingData | WindowSystemDefaultsState,
                        setDefaults: boolean) {
        const defaultsData = this.getWindowSystemDefaultsData(data);
        const spec = this.getDrawingDataSpec(data);
        this.setItems(WindowEditorField.WELD_TYPE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableWeldTypes, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.weldTypeId : undefined)
                : (spec != undefined && spec.weldType != undefined ? spec.weldType.addonId : undefined)]);
        this.setItems(WindowEditorField.HANDLES,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableHandles, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.handleTypeId : undefined)
                : (spec != undefined && spec.handleType != undefined ? spec.handleType.addonId : undefined)]);
        this.setItems(WindowEditorField.COVERS,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableCovers, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.coverId : undefined)
                : (spec != undefined && spec.cover != undefined ? spec.cover.addonId : undefined)]);
        this.setItems(WindowEditorField.MILLINGS,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableMillings, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.millingId : undefined)
                : (spec != undefined && spec.milling != undefined ? spec.milling.addonId : undefined)]);
        this.setItems(WindowEditorField.MILLINGS_NORWEGIAN,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableMillingsNorwegian, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.millingNorwegianId : undefined)
                : (spec != undefined && spec.millingNorwegian != undefined ? spec.millingNorwegian.addonId : undefined)]);
        this.setItems(WindowEditorField.UNDER_WINDOW_BEAD,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableUnderWindowBeads, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.underWindowBeadId : undefined)
                : (spec != undefined && spec.underWindowBead != undefined ? spec.underWindowBead.addonId : undefined)]);
        this.setItems(WindowEditorField.FRAME_ENHANCEMENT,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableEnhancements, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.frameEnhancementId : undefined)
                : (spec != undefined && spec.frameEnhancement != undefined ? spec.frameEnhancement.addonId : undefined)]);
        this.setItems(WindowEditorField.UNDERWINDOW_PROFILE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableUnderwindowProfiles, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.underwindowProfileId : undefined)
                : (spec != undefined && spec.underwindowProfile != undefined ? spec.underwindowProfile.addonId : undefined)]);
        this.setItems(WindowEditorField.VENTILATOR,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableVentilators, webshopChargeMode));
        this.setItems(WindowEditorField.DRIP,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableDrips, webshopChargeMode));
        this.setItems(WindowEditorField.COUPLER,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableCouplers, webshopChargeMode));
        this.setItems(WindowEditorField.FITTING_BRAKE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingBrakes, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingBrakeId : undefined)
                : (spec != undefined && spec.fittingBrake != undefined ? spec.fittingBrake.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_SLIDING,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingSlidings, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingSlidingId : undefined)
                : (spec != undefined && spec.fittingSliding != undefined ? spec.fittingSliding.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_TYPE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingTypes, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingTypeId : undefined)
                : (spec != undefined && spec.fittingType != undefined ? spec.fittingType.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_ESPAGNOLETTE_TYPE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingEspagnoletteTypes, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingEspagnoletteTypeId : undefined)
                : (spec != undefined && spec.fittingEspagnoletteType != undefined ? spec.fittingEspagnoletteType.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_VERANDA,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingVerandas, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingVerandaId : undefined)
                : (spec != undefined && spec.fittingVeranda != undefined ? spec.fittingVeranda.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_INSERTION,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingInsertions, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingInsertionId : undefined)
                : (spec != undefined && spec.fittingInsertion != undefined ? spec.fittingInsertion.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_MAIN_INSERTION,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingMainInsertions, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingMainInsertionId : undefined)
                : (spec != undefined && spec.fittingMainInsertion != undefined ? spec.fittingMainInsertion.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_ADDITIONAL_INSERTION,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingAdditionalInsertions, webshopChargeMode),
                [setDefaults
                    ? (defaultsData != undefined ? defaultsData.fittingAdditionalInsertionId : undefined)
                    : (spec != undefined && spec.fittingAdditionalInsertion != undefined ? spec.fittingAdditionalInsertion.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_LOCK,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingLocks, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingLockId : undefined)
                : (spec != undefined && spec.fittingLock != undefined ? spec.fittingLock.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_LOCK_TERRACE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingLocksTerrace, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingLockTerraceId : undefined)
                : (spec != undefined && spec.fittingLockTerrace != undefined ? spec.fittingLockTerrace.addonId : undefined)]);
        this.setItems(WindowEditorField.FITTING_AUTOMATIC_DRIVE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableFittingAutomaticDrives, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.fittingAutomaticDriveId : undefined)
                : (spec != undefined && spec.fittingAutomaticDrive != undefined ? spec.fittingAutomaticDrive.addonId : undefined)]);
        this.setItems(WindowEditorField.TERRACE_HANDLE,
            this.selectItemFormatters.formatAddonOptions(allAddons.availableTerraceHandles, webshopChargeMode),
            [setDefaults
                ? (defaultsData != undefined ? defaultsData.terraceHandleId : undefined)
                : (spec != undefined && spec.terraceHandle != undefined ? spec.terraceHandle.addonId : undefined)]);

        return allAddons;
    }

    groupAndStoreEntranceDoorAddons(addons: AddonInterface[], data: EntranceDoorData) {
        let grouped = _.groupBy(addons.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]),
            addon => addon.category);

        this.setItems(WindowEditorField.ENTRANCE_DOOR_FITTING,
            this.selectItemFormatters.formatAddonOptions(grouped[AddonCategoryEnum.ENTRANCE_DOOR_FITTING] || [], false),
            [data != undefined && data.entranceLock != undefined ? data.entranceLock.addonId : undefined]);
        return {
            ungrouped: addons,
            entranceLocks: grouped[AddonCategoryEnum.ENTRANCE_DOOR_FITTING] || []
        }
    }

    storeDecorativeFillings(decorativeFillings: DecorativeFilling[], data: DrawingData | WindowSystemDefaultsState,
                            setDefaults = false) {
        this.setItems(WindowEditorField.DECORATIVE_FILLING,
            decorativeFillings.map(df => this.selectItemFormatters.formatDecorativeFilling(df)),
            this.getSelectedFillingIdByFields('decorativeFillingId', data, setDefaults));
    }

    storeBusinessTypes(businessTypes: BusinessTypeBasic[]) {
        this.setItems(WindowEditorField.BUSINESS_TYPE,
            businessTypes.map(bt => this.selectItemFormatters.formatBusinessType(bt)));
    }

    groupAndStoreOtherFillings(otherFillings: OtherFilling[], data: DrawingData | WindowSystemDefaultsState,
                               setDefaults = false) {
        const externalFillings: OtherFilling[] = [];
        const internalFillings: OtherFilling[] = [];
        for (let otherFilling of otherFillings) {
            if (otherFilling.forOutdoorUse) {
                externalFillings.push(otherFilling);
            }
            if (otherFilling.forIndoorUse) {
                internalFillings.push(otherFilling);
            }
        }

        const selectedFillingIds = this.getSelectedFillingIdByFields('fillingId', data, setDefaults);
        this.setItems(WindowEditorField.FILLING_NAME_EXTERNAL,
            externalFillings.map(exf => this.selectItemFormatters.formatOtherFilling(exf)), selectedFillingIds);
        this.setItems(WindowEditorField.FILLING_NAME_INTERNAL,
            internalFillings.map(inf => this.selectItemFormatters.formatOtherFilling(inf)), selectedFillingIds);

        return {
            externalFillings,
            internalFillings
        };
    }

    groupAndStoreGrills(grillDtos: GrillDto[], data: DrawingData | WindowSystemDefaultsState,
                        setDefaults = false) {
        const grills: GrillDto[] = [];
        const angledGrills: GrillDto[] = [];

        for (let grill of grillDtos) {
            grills.push(grill);
            if (grill.angled) {
                angledGrills.push(grill);
            }
        }

        const selectedIds = this.getSelectedGrillIds(data, setDefaults);
        this.setItems(WindowEditorField.GRILL, grills.map(g => this.selectItemFormatters.formatGrillOption(g)), selectedIds);
        this.setItems(WindowEditorField.GRILL_ANGLED, angledGrills.map(ga => this.selectItemFormatters.formatGrillOption(ga)), selectedIds);

        return {
            grills,
            angledGrills
        };
    }

    storeGlazingBeads(glazingBeads: GlazingBead[], data: DrawingData | WindowSystemDefaultsState,
                      setDefaults = false) {
        this.setItems(WindowEditorField.GLAZING_BEAD, glazingBeads.map(g =>
            this.selectItemFormatters.formatGlazingBeadOption(g)), this.getSelectedGlazingBeadIds(data, setDefaults));

        return glazingBeads;
    }

    storeGlasses(glasses: GlassWithPosition[], data: DrawingData | WindowSystemDefaultsState,
                 setDefaults = false) {
        this.setItems(WindowEditorField.GLASS, glasses.map(g => this.selectItemFormatters.formatGlass(g)),
            this.getSelectedGlazingIds(data, setDefaults, 'glass'));

        return glasses;
    }

    storeDistanceFrames(frames: DistanceFrame[], data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean) {
        this.setItems(WindowEditorField.DISTANCE_FRAME, frames.map(f => this.selectItemFormatters.formatDistanceFrame(f)),
            this.getSelectedGlazingIds(data, setDefaults, 'frame'));

        return frames;
    }

    storeTerraceGlazingPackages(items: GlazingPackage[], selectedId: number) {
        this.setItems(WindowEditorField.TERRACE_GLAZING_PACKAGE,
            items.map(item => this.selectItemFormatters.formatGlazingPackageOption(item)), [selectedId]);

        return items;
    }

    storeWindowViews(views: WindowView[], webshopChargeMode: boolean): void {
        this.setItems(WindowEditorField.VIEW, this.selectItemFormatters.formatViewOptions(views, webshopChargeMode));
    }

    storeWindowFunctions(webshopChargeMode: boolean): void {
        this.setItems(WindowEditorField.WINDOW_FUNCTION,
            this.selectItemFormatters.formatWindowFunctionOptions(Object.keys(WindowFunction), webshopChargeMode));
    }

    storeTerraceLockLocations(fittingTerraceLockLocations: FittingTerraceLockLocation[], webshopChargeMode: boolean): void {
        this.setItems(WindowEditorField.FITTING_LOCK_TERRACE_LOCATION,
            this.selectItemFormatters.formatTerraceLockLocationOptions(fittingTerraceLockLocations, webshopChargeMode));
    }

    storeOpeningOptions(openingOptions: OpeningOption[], webshopChargeMode: boolean): void {
        this.setItems(WindowEditorField.OPENING, this.selectItemFormatters.formatDoorOpeningOptions(openingOptions, webshopChargeMode));
    }

    storeTerraceHandleLayouts(terraceHandleLayouts: TerraceHandleLayout[], webshopChargeMode: boolean): void {
        this.setItems(WindowEditorField.TERRACE_HANDLE_LAYOUT,
            this.selectItemFormatters.formatTerraceHandleLayoutOptions(terraceHandleLayouts, webshopChargeMode));
    }

    storeGlazingPackagesQuantity(items: number[]) {
        this.setItems(WindowEditorField.GLAZING_PACKAGE_QUANTITY,
            items.map(item => this.selectItemFormatters.formatGlazingPackageQuantity(item)));

        return items;
    }

    storeGlazingCategories(quantity: number, glazingCategories: GraspGlazingCategory[]) {
        this.setItems(this.getGlazingPackageCategoryFieldId(quantity),
            glazingCategories.map(g => this.selectItemFormatters.formatGraspGlazingOption(g)));
        return glazingCategories;
    }

    storeGlazingFrameCategories(quantity: number, frameCategories: GraspDistanceFrameCategory[]) {
        this.setItems(this.getGlazingPackageFrameCategoryFieldId(quantity),
            frameCategories.map(g => this.selectItemFormatters.formatGraspGlazingOption(g)));
        return frameCategories;
    }

    storeGlazingPackages(quantity: number, categoryId: number, frameCategoryId: number, glazingPackages: GraspGlazingPackage[]) {
        this.setItems(this.getGlazingPackagesFieldId(quantity, categoryId, frameCategoryId),
            glazingPackages.map(g => this.selectItemFormatters.formatGraspGlazingOption(g)));
        return glazingPackages;
    }

    storeAllGlazingCategories(glazingCategories: GraspGlazingCategory[],
                           data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean) {
        this.setItems(WindowEditorField.GLAZING_PACKAGE_CATEGORY,
            glazingCategories.map(g => this.selectItemFormatters.formatGraspGlazingOption(g)),
            this.getSelectedGlazingPackageIds(data, setDefaults, 'glazingCategoryId'));
        return glazingCategories;
    }

    storeAllGlazingFrameCategories(frameCategories: GraspDistanceFrameCategory[],
                                data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean) {
        this.setItems(WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY,
            frameCategories.map(g => this.selectItemFormatters.formatGraspGlazingOption(g)),
            this.getSelectedGlazingPackageIds(data, setDefaults, 'glazingFrameCategoryId'));
        return frameCategories;
    }

    storeAllGlazingPackages(glazingPackages: GraspGlazingPackage[],
                         data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean) {
        this.setItems(WindowEditorField.GLAZING_PACKAGE,
            glazingPackages.map(g => this.selectItemFormatters.formatGraspGlazingOption(g)),
            this.getSelectedGlazingPackageIds(data, setDefaults, 'glazingPackageId'));
        return glazingPackages;
    }

    getGlazingPackageCategoryFieldId(quantity: number): string {
        return `${quantity}_${WindowEditorField.GLAZING_PACKAGE_CATEGORY}`;
    }

    getGlazingPackageFrameCategoryFieldId(quantity: number): string {
        return `${quantity}_${WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY}`;
    }

    getGlazingPackagesFieldId(quantity: number, categoryId: number, frameCategoryId: number): string {
        return `${quantity}_${categoryId}_${frameCategoryId}_${WindowEditorField.GLAZING_PACKAGE}`;
    }

    storeWindowDimensions(dimensions: WindowDimensions[], data: EntranceDoorData) {
        this.setItems(WindowEditorField.DIMENSIONS, dimensions.map(d => this.selectItemFormatters.formatWindowDimensions(d)),
            [data != undefined ? data.dimensionsId : undefined]);
    }

    storeRoofOrEntranceGlazingPackages(glazingPackages: GlazingPackageData[], data: RoofWindowData | EntranceDoorData): void {
        this.setItems(WindowEditorField.GLAZING_PACKAGE, glazingPackages.map(g => this.selectItemFormatters.formatNameAndActiveOption(g),
            [data != undefined ? data.glazingPackageId : undefined]))
    }

    storeFieldDependencies(dependencies: WindowDesignerCatalogDependentOption[]): void {
        super.storeFieldDependencies(dependencies);

        const addDependentField = (requiredInputId: WindowEditorField, dependentInputId: WindowEditorField) => {
            let dependentFields = this.fieldsThatDependOnField.get(requiredInputId);
            if (dependentFields == undefined) {
                dependentFields = new Set<WindowEditorField>();
                this.fieldsThatDependOnField.set(requiredInputId, dependentFields);
            }
            dependentFields.add(dependentInputId);
        };

        // link color fields to self for RAL/NCS manipulations
        addDependentField(WindowEditorField.EXTERNAL_COLOR, WindowEditorField.EXTERNAL_COLOR);
        addDependentField(WindowEditorField.INTERNAL_COLOR, WindowEditorField.INTERNAL_COLOR);
        addDependentField(WindowEditorField.TERRACE_COLOR, WindowEditorField.TERRACE_COLOR);
    }

    getDrawingDataSpec(data: DrawingData | WindowSystemDefaultsState): DrawingDataSpecification {
        const drawingData = this.getDrawingData(data);
        return drawingData != undefined ? drawingData.specification : undefined;
    }

    getDrawingData(data: DrawingData | WindowSystemDefaultsState): DrawingData {
        return data as DrawingData;
    }

    getWindowSystemDefaultsData(data: DrawingData | WindowSystemDefaultsState): WindowSystemDefaults {
        const windowSystemDefaults = this.getWindowSystemDefaults(data);
        return windowSystemDefaults != undefined ? windowSystemDefaults.value : undefined;
    }

    getWindowSystemDefaults(data: DrawingData | WindowSystemDefaultsState): WindowSystemDefaultsState {
        return data as WindowSystemDefaultsState;
    }

    getSelectedColors(data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): number[] {
        if (setDefaults) {
            const defaults = this.getWindowSystemDefaultsData(data);
            if (defaults == undefined) {
                return [];
            }
            return [defaults.colorIdCore, defaults.colorIdExternal, defaults.colorIdInternal,
                defaults.coreColorId, defaults.externalColorId, defaults.internalColorId];
        }
        const drawingData = this.getDrawingData(data);
        if (drawingData == undefined) {
            return [];
        }
        const selectedColors: Set<number> = new Set<number>();
        selectedColors.add(drawingData.specification.colorIdCore);
        selectedColors.add(drawingData.specification.colorIdExternal);
        selectedColors.add(drawingData.specification.colorIdInternal);
        for (let w of this.getDrawingData(data).windows) {
            for (let sw of w.subWindows) {
                for (let area of sw.areasSpecification) {
                    selectedColors.add(area.filling.coreColorId);
                    selectedColors.add(area.filling.externalColorId);
                    selectedColors.add(area.filling.internalColorId);
                    for (let grill of area.grills) {
                        selectedColors.add(grill.colorId);
                    }
                }
            }
        }
        return Array.from(selectedColors);
    }

    getSelectedAddons(data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): number[] {
        const selectedAddons: number[] = [];
        const windowSystemDefaultsData = this.getWindowSystemDefaultsData(data);
        if (setDefaults && windowSystemDefaultsData == undefined) {
            return [];
        }
        const drawingDataSpec = this.getDrawingDataSpec(data);
        if (!setDefaults && drawingDataSpec == undefined) {
            return [];
        }

        selectedAddons.push(setDefaults ? windowSystemDefaultsData.coverId
            : this.getAddonId(drawingDataSpec.cover));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingBrakeId
            : this.getAddonId(drawingDataSpec.fittingBrake));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingSlidingId
            : this.getAddonId(drawingDataSpec.fittingSliding));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingTypeId
            : this.getAddonId(drawingDataSpec.fittingType));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingEspagnoletteTypeId
            : this.getAddonId(drawingDataSpec.fittingEspagnoletteType));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingVerandaId
            : this.getAddonId(drawingDataSpec.fittingVeranda));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingInsertionId
            : this.getAddonId(drawingDataSpec.fittingInsertion));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingMainInsertionId
            : this.getAddonId(drawingDataSpec.fittingMainInsertion));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingAdditionalInsertionId
            : this.getAddonId(drawingDataSpec.fittingAdditionalInsertion));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingLockId
            : this.getAddonId(drawingDataSpec.fittingLock));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingLockTerraceId
            : this.getAddonId(drawingDataSpec.fittingLockTerrace));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.fittingAutomaticDriveId
            : this.getAddonId(drawingDataSpec.fittingAutomaticDrive));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.frameEnhancementId
            : this.getAddonId(drawingDataSpec.frameEnhancement));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.handleTypeId
            : this.getAddonId(drawingDataSpec.handleType));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.millingId
            : this.getAddonId(drawingDataSpec.milling));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.millingNorwegianId
            : this.getAddonId(drawingDataSpec.millingNorwegian));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.underWindowBeadId
            : this.getAddonId(drawingDataSpec.underWindowBead));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.underwindowProfileId
            : this.getAddonId(drawingDataSpec.underwindowProfile));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.weldTypeId
            : this.getAddonId(drawingDataSpec.weldType));
        selectedAddons.push(setDefaults ? windowSystemDefaultsData.terraceHandleId
            : this.getAddonId(drawingDataSpec.terraceHandle));

        if (!setDefaults) {
            (data as DrawingData).windows.forEach(w => w.subWindows.forEach(sw => {
                selectedAddons.push(this.getAddonId(sw.ventilator));
                selectedAddons.push(this.getAddonId(sw.drip));
                selectedAddons.push(this.getAddonId(sw.coupler));
            }));
        }

        return selectedAddons;
    }

    getAddonId(addon: WindowAddon) {
        return addon && addon.addonId;
    }

    getSelectedFillingIdByFields(field: string, data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): number[] {
        if (setDefaults) {
            const windowSystemDefaultsData = this.getWindowSystemDefaultsData(data);
            if (windowSystemDefaultsData == undefined || !windowSystemDefaultsData.decorativeFillingId == undefined) {
                return [];
            }
            return [windowSystemDefaultsData.decorativeFillingId];
        } else {
            if (data == undefined) {
                return [];
            }
            const fillingIds: number[] = [];
            for (let window of this.getDrawingData(data).windows) {
                for (let sw of window.subWindows) {
                    for (let area of sw.areasSpecification) {
                        if (area.filling[field] != undefined) {
                            fillingIds.push(area.filling[field]);
                        }
                    }
                }
            }
            return fillingIds;
        }
    }

    getSelectedGlazingBeadIds(data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): number[] {
        if (setDefaults) {
            const windowSystemDefaultsData = this.getWindowSystemDefaultsData(data);
            if (windowSystemDefaultsData == undefined || !windowSystemDefaultsData.glazingBeadId == undefined) {
                return [];
            }
            return [windowSystemDefaultsData.glazingBeadId];
        } else {
            if (data == undefined) {
                return [];
            }
            const fillingIds: number[] = [];
            for (let window of this.getDrawingData(data).windows) {
                for (let sw of window.subWindows) {
                    for (let area of sw.areasSpecification) {
                        if (area.glazingBead != undefined && area.glazingBead.id != undefined) {
                            fillingIds.push(area.glazingBead.id);
                        }
                    }
                }
            }
            return fillingIds;
        }
    }

    getSelectedMullionId(data: DrawingData, setDefaults: boolean): number[] {
        if (setDefaults) {
            return [];
        } else {
            if (data == undefined) {
                return [];
            }
            const mullionIds: number[] = [];
            for (let window of data.windows) {
                for (let sw of window.subWindows) {
                    for (let mullion of sw.mullions) {
                        mullionIds.push(mullion.id);
                    }
                }
            }
            return mullionIds;
        }
    }

    getSelectedGrillIds(data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): number[] {
        if (setDefaults) {
            return [];
        } else {
            if (data == undefined) {
                return [];
            }
            const grillIds: number[] = [];
            for (let window of this.getDrawingData(data).windows) {
                for (let sw of window.subWindows) {
                    for (let area of sw.areasSpecification) {
                        for (let grill of area.grills) {
                            grillIds.push(grill.id);
                        }
                    }
                }
            }
            return grillIds;
        }
    }

    getSelectedGlazingIds(data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean, field: string): number[] {
        const glasses: number[] = [];
        if (setDefaults) {
            const windowSystemDefaultsData = this.getWindowSystemDefaultsData(data);
            if (windowSystemDefaultsData != undefined) {
                for (let i = 1; i <= 4; i++) {
                    if (!windowSystemDefaultsData[field + i + 'id'] != undefined) {
                        glasses.push(windowSystemDefaultsData[field + i + 'id']);
                    }
                }
            }
        } else {
            if (data == undefined) {
                return [];
            }
            for (let window of this.getDrawingData(data).windows) {
                for (let sw of window.subWindows) {
                    for (let area of sw.areasSpecification) {
                        for (let i = 1; i <= 4; i++) {
                            if (!area.glazing[field + i + 'id'] != undefined) {
                                glasses.push(area.glazing[field + i + 'id']);
                            }
                        }
                    }
                }
            }
        }
        return glasses;
    }

    getSelectedGlazingPackageIds(data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean,
                                 field: 'glazingPackageId' | 'glazingCategoryId' | 'glazingFrameCategoryId'): number[] {
        const ids: number[] = [];
        if (setDefaults) {
            const windowSystemDefaultsData = this.getWindowSystemDefaultsData(data);
            if (windowSystemDefaultsData != undefined) {
                if (windowSystemDefaultsData[field] != undefined) {
                    ids.push(windowSystemDefaultsData[field]);
                }
            }
        } else {
            if (data == undefined) {
                return [];
            }
            for (let window of this.getDrawingData(data).windows) {
                for (let sw of window.subWindows) {
                    for (let area of sw.areasSpecification) {
                        if (area[field] != undefined) {
                            ids.push(area[field]);
                        }
                    }
                }
            }
        }
        return ids;
    }
}
