<div class="wrapper first-field-wrapper pass-wrapper">
    <input id="newPassword" name="newPassword" type="password" autofocus autocomplete="new-password"
           placeholder="{{ 'LOGIN.FORM.PASSWORD' | translate }}" [(ngModel)]="password" />
</div>
<div class="wrapper pass-wrapper">
    <input id="confirmPassword" name="confirmPassword" type="password" autocomplete="new-password"
           placeholder="{{ 'LOGIN.FORM.CONFIRM_PASSWORD' | translate }}" [(ngModel)]="confirmPassword" />
</div>
<div class="submit-wrapper">
    <div class="button return" [ngClass]="{ disabled: passwordChangeInProgress }" tabindex="0">
        <div (click)="returnClick.emit()">
            {{ 'LOGIN.FORM.RETURN_TO_LOGIN' | translate }}
        </div>
    </div>
    <div class="button submit" [ngClass]="{ disabled: passwordChangeInProgress }" tabindex="0">
        <div (click)="handleChangePasswordClick()">
            {{ 'LOGIN.FORM.CHANGE_PASSWORD_BUTTON' | translate }}
        </div>
    </div>
</div>
