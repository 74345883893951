import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    SimpleChanges
} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {combineLatest, Subscription} from 'rxjs';
import {GrillType} from "../../../../../window-designer/drawing-data/GrillType";
import {HandleDirection} from "../../../../../window-designer/drawing-data/HandleDirection";
import {WindowShapeType} from "../../../../../window-designer/drawing-data/WindowShapeType";
import {ConfigAddonApplication} from "../../../../../window-designer/enums/ConfigAddonApplication";
import {WindowParams} from "../../../../../window-designer/painters/WindowParams";
import {GrillHelper} from "../../../../../window-designer/utils/grill-helper";
import {Tool} from '../../../../../window-designer/window-designer-interface';
import {WindowTypeCode} from "../../../../../window-designer/window-types/window-type-code";
import {ColorType} from '../../../../ColorType';
import {ButtonWithMenuElementSelectedEvent} from "../../../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../../common/button-with-menu/MenuType";
import {SelectItemExtended} from "../../../../form-inputs/inputs/select/select.component";
import {Color} from "../../../window-system/color/color";
import {History} from "../history/history";
import {WindowDesignerComponent} from "../window-designer/window-designer.component";
import {WindowEditorField} from '../window-editor-field';
import {WindowEditorFieldContentProvider} from '../window-editor-field-content-provider';
import {WindowEditorOfferData, WindowEditorProductionOrderData} from '../window-editor-offer-interfaces';

export class GrillParamsSelectedEvent {
    restoreKey: string;
    keys: string[];
}

export enum DrawingToolControlsMode {
    GATE = 'GATE',
    CONFIG = 'CONFIG',
    CONJUNCTION = 'CONJUNCTION',
    WINDOW = 'WINDOW',
    ROOF = 'ROOF',
}

@Component({
    selector: 'app-drawing-tool-controls',
    templateUrl: './drawing-tool-controls.component.html',
    styleUrls: ['./drawing-tool-controls.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawingToolControlsComponent implements DoCheck, OnInit, OnChanges, OnDestroy {

    public readonly INPUT_NUMBER_DEFAULT_MAX_VALUE = 999999;

    @Input() allActiveColors: Color[] = [];
    @Input() designer: WindowDesignerComponent;
    @Input() grillColors: { [grillId: number]: Color[] } = {};
    @Input() grillNcsColors: { [grillId: number]: Color[] } = {};
    @Input() grillRalColors: { [grillId: number]: Color[] } = {};
    @Input() headerSuffix: string;
    @Input() offer: WindowEditorOfferData;
    @Input() productionOrder: WindowEditorProductionOrderData;
    @Input() readOnlyMode = true;
    @Input() requiredFieldFilled: boolean;
    @Input() mode: DrawingToolControlsMode;
    @Input() showPointerPosition: boolean;
    @Input() sidebarOnlyMode: boolean;
    @Input() history: History;
    @Input() canSaveExit: boolean;
    @Input() canDelete: boolean;
    @Input() canChangeCoupler: boolean;

    @Output() onUndoLast = new EventEmitter<void>();
    @Output() onRedoLast = new EventEmitter<void>();
    @Output() onCancelMode = new EventEmitter<void>();
    @Output() onResetSelectedElements = new EventEmitter<void>();
    @Output() onShapeChange = new EventEmitter<string>();
    @Output() onEnableHandleMode = new EventEmitter<HandleDirection>();
    @Output() onAligmentToolUsed = new EventEmitter<ButtonWithMenuElementSelectedEvent>();
    @Output() onMirrorToolUsed = new EventEmitter<void>();
    @Output() onExtendHorizontalGrillsUsed = new EventEmitter<void>();
    @Output() onEnableCutMode = new EventEmitter<void>();
    @Output() onTrimToolUsed = new EventEmitter<void>();
    @Output() onInitGrillMode = new EventEmitter<GrillType>();
    @Output() onDelete = new EventEmitter<void>();
    @Output() onAddConfigAddon = new EventEmitter<ConfigAddonApplication>();
    @Output() onAddStandaloneGlazingPackage = new EventEmitter<void>();
    @Output() onOpenSettings = new EventEmitter<void>();
    @Output() onExit = new EventEmitter<void>();
    @Output() onSaveAndExit = new EventEmitter<void>();
    @Output() onGrillParamsChange = new EventEmitter<GrillParamsSelectedEvent>();
    @Output() onChangeCoupler = new EventEmitter<void>();

    menuType = MenuType;
    GrillType = GrillType;
    DrawingToolControlsMode = DrawingToolControlsMode;

    windowShapeMenuElements: MenuElement[] = [];
    grillGridMenuElements: MenuElement[] = [];
    private grillSelectItemsSubscription?: Subscription;
    handleDirectionMenuElements: MenuElement[] = [];
    aligmentToolMenuElements: MenuElement[] = [];

    showGrillTool: GrillType;
    toolOptionsVisible: boolean;
    grillGridSelected: boolean;
    toolOptionsLabel: string;
    currentMode: any;

    sortedGrillColors = new Map<number, Color[]>();
    sortedGrillRalColors = new Map<number, Color[]>();
    sortedGrillNcsColors = new Map<number, Color[]>();
    colorWithGroupOptionFormatter: (color: Color) => SelectItem;
    colorOptionKey: (colorOrId: Color | number) => number;

    selectingRalColorHeader: string;
    selectingRalColors: Color[];
    private colorBeforeRalSelection: Color;

    private matchingGrillColorDiffer: KeyValueDiffer<string, any>;
    private designerTrackedFieldsDiffer: KeyValueDiffer<string, any>;

    constructor(public changeDetector: ChangeDetectorRef,
                private translate: TranslateService,
                differs: KeyValueDiffers,
                @Optional() private windowEditorFieldContentProvider?: WindowEditorFieldContentProvider,
    ) {
        this.initWindowShapeMenu();
        this.initHandleDirectionMenu();
        this.initAligmentToolMenu();
        this.colorWithGroupOptionFormatter = (color: Color): SelectItemExtended => {
            return {
                label: (!color.group ? '' : color.group + ' - ') + color.names[this.translate.currentLang],
                value: color,
                bold: color.id === this.getMatchingColorIdForExternalColor(),
                singlePositionItem: false
            };
        };
        this.colorOptionKey = (colorOrId: Color | number) => {
            if (colorOrId == undefined) {
                return undefined;
            }
            if (typeof colorOrId === 'number') {
                return colorOrId;
            }
            return colorOrId.id;
        };
        this.matchingGrillColorDiffer = differs.find({}).create();
        this.designerTrackedFieldsDiffer = differs.find({}).create();
    }

    get systemMullionSelectItems() {
        return this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.MULLION);
    }

    get grillSelectItems() {
        return this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.GRILL);
    }

    get angledGrillSelectItems() {
        return this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.GRILL_ANGLED);
    }

    ngDoCheck(): void {
        if (this.designer != undefined) {
            const changes = this.matchingGrillColorDiffer.diff(this.designer.data.specification);
            if (changes != undefined) {
                changes.forEachItem(change => {
                    if (change.key === 'colorIdExternal') {
                        const externalColor = this.allActiveColors.find(c => c.id === change.currentValue);
                        const matchingColorId = externalColor != undefined ? externalColor.matchingColorId : undefined;
                        this.prepareGrillColorSelection(this.grillColors, matchingColorId, this.sortedGrillColors);
                        this.prepareGrillColorSelection(this.grillRalColors, matchingColorId, this.sortedGrillRalColors);
                        this.prepareGrillColorSelection(this.grillNcsColors, matchingColorId, this.sortedGrillNcsColors);
                    }
                });
            }
            const designerChanges = this.designerTrackedFieldsDiffer.diff({ mode: this.designer.mode });
            if (designerChanges != undefined) {
                const isValidGrillTypeForTool = (tool: Tool, grillType: GrillType) => {
                    switch (tool) {
                        case Tool.GRILL:
                            return grillType === GrillType.LINE_GRILL;
                        case Tool.MULLION:
                            return grillType === GrillType.MULLION;
                        case Tool.GRILL_TEMPLATE:
                            return grillType === GrillType.GRID_RHOMBUS
                                || grillType === GrillType.GRID_CROSS_SIMPLE
                                || grillType === GrillType.GRID_CROSS_ANGLED
                                || grillType === GrillType.GRID_STANDARD;
                    }
                    return false;
                }
                designerChanges.forEachItem(change => {
                    if (change.key === 'mode') {
                        if (!isValidGrillTypeForTool(change.currentValue, this.showGrillTool)) {
                            this.cancelMode();
                        }
                    }
                })
                this.changeDetector.markForCheck();
            }
        }
    }

    ngOnInit(): void {
        if (this.windowEditorFieldContentProvider != undefined) {
            this.grillSelectItemsSubscription = combineLatest([
                this.grillSelectItems,
                this.angledGrillSelectItems
            ]).subscribe(() => {
                this.initGrillGridMenu();
                this.changeDetector.markForCheck();
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('grillColors' in changes) {
            const change = changes['grillColors'];
            this.prepareGrillColorSelection(change.currentValue, this.getMatchingColorIdForExternalColor(), this.sortedGrillColors);
        }
        if ('grillRalColors' in changes) {
            const change = changes['grillRalColors'];
            this.prepareGrillColorSelection(change.currentValue, this.getMatchingColorIdForExternalColor(), this.sortedGrillRalColors);
        }
        if ('grillNcsColors' in changes) {
            const change = changes['grillNcsColors'];
            this.prepareGrillColorSelection(change.currentValue, this.getMatchingColorIdForExternalColor(), this.sortedGrillNcsColors);
        }
    }

    ngOnDestroy(): void {
        if (this.grillSelectItemsSubscription != undefined) {
            this.grillSelectItemsSubscription.unsubscribe();
        }
    }

    private prepareGrillColorSelection(data: { [grillId: number]: Color[] }, matchingColorId: number, output: Map<number, Color[]>): void {
        output.clear();
        Object.keys(data).forEach(grillId => {
            this.prepareGrillColorSelectionForGrill(grillId, data, matchingColorId, output);
        });
    }

    private prepareGrillColorSelectionForGrill(grillId: any, data: { [grillId: number]: Color[] }, matchingColorId: number,
                                               output: Map<number, Color[]>): void {
        const colors: Color[] = data[grillId].slice();
        const matchingColorIndex = colors.findIndex(color => color.id === matchingColorId);
        if (matchingColorIndex >= 0) {
            const matchingColor = colors.splice(matchingColorIndex, 1)[0];
            colors.unshift(matchingColor);
        }
        output.set(+grillId, colors);
    }

    private initMenuElement(id: string, translation: string): MenuElement {
        return new MenuElementBuilder().setIdentifier(id).setTranslationKey(translation).build();
    }

    private initMenuElementsFromValues(values: string[], translatePrefix: string): MenuElement[] {
        return values.map(v => this.initMenuElement(v, `${translatePrefix}${v}`));
    }

    private initWindowShapeMenu(): void {
        let enabledShapeTypes = [
            WindowShapeType.RECTANGULAR,
            WindowShapeType.ARCH_ELLIPTICAL,
            WindowShapeType.ARCH_SEGMENTAL,
            WindowShapeType.ARCH_THREE_CENTERED];
        this.windowShapeMenuElements.push(
            ...this.initMenuElementsFromValues(enabledShapeTypes.map(key => WindowShapeType[key]),
                'OFFER.DRAWING.SHAPE.'));
    }

    private initGrillGridMenu(): void {
        this.grillGridMenuElements = [];
        if (this.windowEditorFieldContentProvider.getItems(WindowEditorField.GRILL).length > 0) {
            this.grillGridMenuElements.push(
                this.initMenuElement(GrillType[GrillType.GRID_STANDARD], 'OFFER.DRAWING.GRILL.GRID'),
                this.initMenuElement(GrillType[GrillType.GRID_CROSS_SIMPLE], 'OFFER.DRAWING.GRILL_TYPE.CROSS')
            );
        }
        if (this.windowEditorFieldContentProvider.getItems(WindowEditorField.GRILL_ANGLED).length > 0) {
            this.grillGridMenuElements.push(
                this.initMenuElement(GrillType[GrillType.GRID_CROSS_ANGLED], 'OFFER.DRAWING.GRILL_TYPE.DIAGONAL'),
                this.initMenuElement(GrillType[GrillType.GRID_RHOMBUS], 'OFFER.DRAWING.GRILL_TYPE.DIAMOND')
            );
        }
    }

    private initHandleDirectionMenu(): void {
        this.handleDirectionMenuElements.push(
            ...this.initMenuElementsFromValues(Object.keys(HandleDirection).map(key => HandleDirection[key]),
                'OFFER.DRAWING.HANDLE.'));
    }

    private initAligmentToolMenu(): void {
        this.aligmentToolMenuElements.push(
            this.initMenuElement('alignment-frame', 'OFFER.DRAWING.ALIGNMENT.FRAME'),
            this.initMenuElement('alignment-glass', 'OFFER.DRAWING.ALIGNMENT.GLASS'),
            this.initMenuElement('alignment-frame-vertical', 'OFFER.DRAWING.ALIGNMENT.FRAME_VERTICAL'),
            this.initMenuElement('alignment-glass-vertical', 'OFFER.DRAWING.ALIGNMENT.GLASS_VERTICAL')
        );
    }

    markForCheck() {
        this.changeDetector.markForCheck();
    }

    undoLast() {
        if (this.canUndo()) {
            this.cancelMode();
            this.onUndoLast.emit();
        }
    }

    redoLast() {
        if (this.canRedo()) {
            this.cancelMode();
            this.onRedoLast.emit();
        }
    }

    cancelMode() {
        this.onCancelMode.emit();
        this.toolOptionsVisible = false;
        this.showGrillTool = undefined;
        this.currentMode = undefined;
        this.changeDetector.markForCheck();
    }

    exit() {
        this.onExit.emit();
    }

    saveAndExit() {
        if (this.canSaveAndExit()) {
            this.onSaveAndExit.emit();
        }
    }

    canSaveAndExit(): boolean {
        switch (this.mode) {
            case DrawingToolControlsMode.WINDOW:
                return this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() && !this.readOnlyMode;
            case DrawingToolControlsMode.CONJUNCTION:
                return this.canSaveExit;
            default:
                return !this.readOnlyMode;
        }
    }

    enableCutMode() {
        if (this.canUseCutTool()) {
            this.cancelMode();
            this.onEnableCutMode.emit();
        }
    }

    trimToolUsed() {
        if (this.canUseTrimTool()) {
            this.onTrimToolUsed.emit();
        }
    }

    deleteSelectedItem() {
        if (this.canUseDeleteTool()) {
            this.cancelMode();
            this.onDelete.emit();
        }
    }

    addConfigAddon() {
        let application = this.getConfigAddonApplication();
        if (this.canAddConfigAddon()) {
            this.cancelMode();
            this.onAddConfigAddon.emit(application);
        }
    }

    addStandaloneGlazingPackage() {
        if (this.canAddStandaloneGlazingPackage()) {
            this.cancelMode();
            this.onAddStandaloneGlazingPackage.emit();
        }
    }

    mirrorMode() {
        if (this.canUseMirrorTool()) {
            this.cancelMode();
            this.onMirrorToolUsed.emit();
        }
    }

    extendHorizontalGrills() {
        if (this.canUseExtendHorizontalGrillsTool()) {
            this.cancelMode();
            this.onExtendHorizontalGrillsUsed.emit();
        }
    }

    openSettings() {
        if (this.atLeastOneWindowAddedAndNotAddingAnotherOneNow()) {
            this.onOpenSettings.emit();
        }
    }

    initGrillMode(mode: GrillType) {
        if (this.currentMode === mode) {
            return;
        }
        if (this.canUseGrillTool(mode)) {
            this.cancelMode();
            this.resetSelectedElements();
            this.showGrillTool = mode;
            this.currentMode = mode;
            this.onInitGrillMode.emit(mode);
            if (mode === GrillType.LINE_GRILL || mode === GrillType.MULLION) {
                this.toolOptionsLabel =
                    (mode === GrillType.LINE_GRILL) ? ('OFFER.DRAWING.GRILL.' + mode) : ('OFFER.DRAWING.MULLION.ADD');
                this.grillGridSelected = false;
            } else {
                this.toolOptionsLabel = 'OFFER.DRAWING.GRILL.GRIDS.' + mode;
                this.grillGridSelected = true;
            }
            this.toolOptionsVisible = true;
        }
    }

    canUseTools() {
        return this.designer != null && this.requiredFieldFilled && !this.readOnlyMode;
    }

    atLeastOneWindowAddedAndNotAddingAnotherOneNow(): boolean {
        return this.designer != null && !this.designer.isAddWindowDialogDisplayed() &&
            this.designer.data != null && this.designer.data.windows.length > 0;
    }

    canUndo(): boolean {
        switch (this.mode) {
            case DrawingToolControlsMode.WINDOW:
                return this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() && this.designer.canUndo();
            case DrawingToolControlsMode.CONJUNCTION:
                return this.history.canUndo();
            default:
                throw new Error('DrawingToolControlsComponent - Unsupported mode: ' + this.mode);
        }
    }

    canChangeConnector(): boolean {
        return this.mode === DrawingToolControlsMode.CONJUNCTION && this.canChangeCoupler;
    }

    canRedo(): boolean {
        switch (this.mode) {
            case DrawingToolControlsMode.WINDOW:
                return this.designer != null && !this.designer.isAddWindowDialogDisplayed() && this.designer.canRedo();
            case DrawingToolControlsMode.CONJUNCTION:
                return this.history.canRedo();
            default:
                throw new Error('DrawingToolControlsComponent - Unsupported mode: ' + this.mode);
        }
    }

    canUseCutTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() &&
            this.designer.canUseCutTool();
    }

    canUseTrimTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() &&
            this.designer.canUseTrimTool();
    }

    canUseGrillTool(mode?: GrillType): boolean {
         if (!this.canUseTools()) {
             return false;
         }
         if (!this.atLeastOneWindowAddedAndNotAddingAnotherOneNow()) {
             return false;
         }
         if (!this.designer.canUseGrillTool()) {
             return false;
         }
         switch (mode) {
             case GrillType.MULLION:
                 return true;
             case GrillType.LINE_GRILL:
                 return this.windowEditorFieldContentProvider.getItems(WindowEditorField.GRILL).length > 0;
             default:
                 return this.windowEditorFieldContentProvider.getItems(WindowEditorField.GRILL).length > 0
                     || this.windowEditorFieldContentProvider.getItems(WindowEditorField.GRILL_ANGLED).length > 0;
         }
    }

    canUseExtendHorizontalGrillsTool(): boolean {
        return this.canUseGrillTool() && this.designer.canUseGrillExtendTool();
    }

    canUseMullionTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() &&
            this.designer.canUseMullionTool();
    }

    canChangeWindowShape(): boolean {
        return this.canUseTools() && this.designer.canChangeWindowShape();
    }

    canUseHandleTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow();
    }

    canUseAlignmentTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow();
    }

    canUseDeleteTool(): boolean {
        switch (this.mode) {
            case DrawingToolControlsMode.CONJUNCTION:
                return this.canDelete;
            default:
                return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() &&
                       this.designer.isDeletableItemSelected();
        }
    }

    canUseMirrorTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow();
    }

    canCancelTool(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() &&
            this.designer.mode !== Tool.SELECT;
    }

    getConfigAddonApplication(): ConfigAddonApplication {
        switch (this.designer.clickedSnapElements.type) {
            case WindowParams.FRAME_ELEM:
                return ConfigAddonApplication.SUBWINDOW;
            case WindowParams.WING_ELEM:
                return ConfigAddonApplication.SUBWINDOW;
            case WindowParams.GLASS_ELEM:
                return ConfigAddonApplication.AREA;
            default:
                return;
        }
    }

    canAddConfigAddon(): boolean {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() && this.getConfigAddonApplication() != null;
    }

    canAddStandaloneGlazingPackage() {
        return this.canUseTools() && this.atLeastOneWindowAddedAndNotAddingAnotherOneNow() &&
            this.designer.clickedSnapElements.type === WindowParams.GLASS_ELEM &&
            (this.designer.windowBusinessTypes.some(bt => bt.type === WindowTypeCode.F) ||
                (this.designer.windowSystem != undefined
                    && this.designer.windowSystem.windowSystemForStandaloneGlazingPackageId != undefined));
    }

    resetSelectedElements() {
        this.onResetSelectedElements.emit();
    }

    grillGridMenuSelected(event: ButtonWithMenuElementSelectedEvent) {
        let identifier = GrillType[event.identifier];
        this.initGrillMode(identifier);
    }

    windowShapeMenuSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.onShapeChange.emit(event.identifier);
    }

    handleDirectionMenuSelected(event: ButtonWithMenuElementSelectedEvent) {
        let identifier = HandleDirection[event.identifier];
        this.onEnableHandleMode.emit(identifier);
    }

    aligmentToolMenuSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.onAligmentToolUsed.emit(event);
    }

    grillParamsChange(restoreKey: string,
                      keys: string[]) {
        let event = new GrillParamsSelectedEvent();
        event.restoreKey = restoreKey;
        event.keys = keys;
        this.onGrillParamsChange.emit(event);
    }

    isToolActive(tool: string): boolean {
        return this.designer.mode === Tool[tool];
    }

    private getMatchingColorIdForExternalColor(): number {
        let externalColorId = this.designer.data.specification.colorIdExternal;
        let externalColor = this.allActiveColors.find(c => c.id === externalColorId);
        return externalColor != undefined ? externalColor.matchingColorId : undefined;
    }

    isGrillExternalColorWithMatchingColors(): boolean {
        let matchingColorId = this.getMatchingColorIdForExternalColor();
        let availableColors = this.grillColors[this.designer.pendingGrillData.grill.id];
        return matchingColorId != undefined && availableColors.some(c => c.id === matchingColorId);
    }

    getGrillColorSelectItems(): Color[] {
        return this.sortedGrillColors.get(this.designer.pendingGrillData.grill.id) || [];
    }

    handleGrillColorSelected(color: Color): void {
        if (color != undefined) {
            if (typeof color.id !== 'number') {
                this.selectingRalColorHeader = 'OFFER.TABS.SECTION.COLOR.' + color.type;
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        this.selectingRalColors = this.sortedGrillRalColors.get(this.designer.pendingGrillData.grill.id);
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        this.selectingRalColors = this.sortedGrillNcsColors.get(this.designer.pendingGrillData.grill.id);
                        break;
                }
                this.colorBeforeRalSelection = this.grillColors[this.designer.pendingGrillData.grill.id]
                    .find(c => c.id === this.designer.pendingGrillData.grill.colorId);
            }
            this.designer.pendingGrillData.grill.colorId = color.id;
        } else {
            this.designer.pendingGrillData.grill.colorId = undefined;
        }
    }

    handleGrillRalColorSelected(color: Color): void {
        if (this.grillColors[this.designer.pendingGrillData.grill.id].find(c => c.id === color.id) == undefined) {
            this.grillColors[this.designer.pendingGrillData.grill.id] = [...this.grillColors[this.designer.pendingGrillData.grill.id],
                color];
            this.prepareGrillColorSelectionForGrill(this.designer.pendingGrillData.grill.id, this.grillColors,
                this.getMatchingColorIdForExternalColor(), this.sortedGrillColors);
        }
        this.handleGrillColorSelected(color);
        this.selectingRalColorHeader = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    handleGrillRalColorSelectionCancel(): void {
        this.handleGrillColorSelected(this.colorBeforeRalSelection);
        this.selectingRalColorHeader = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    isGrillGridInputActive(): boolean {
        const activeId = document.activeElement.id;
        return activeId === 'grill-grid-columns' || activeId === 'grill-grid-rows';
    }

    isShapeEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseShape;
    }

    isCutEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseCut;
    }

    isTrimEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseTrim;
    }

    isAddMullionEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAddMullion;
    }

    isMirrorEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseMirror;
    }

    isAlignmentEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAlignment;
    }

    isAddLineGrillEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAddLineGrill;
    }

    isAddGridGrillEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAddGridGrill;
    }

    isExtendHorizontalGrillsEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseExtendHorizontalGrills;
    }

    isAddHandleEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAddHandle;
    }

    isAddConfigAddonEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAddConfigAddon;
    }

    isAddStandaloneGlazingPackageEnabledForWindowSystem(): boolean {
        if (this.designer == null || this.designer.windowSystemDrawingToolsEnabler == null) {
            return true;
        }
        return this.designer.windowSystemDrawingToolsEnabler.canUseAddStandaloneGlazingPackage
            && this.designer.windowSystem.canHaveStandaloneGlazingPackages;
    }

    getDesignerHeader(): string {
        let designerHeader = '';
        if (this.productionOrder != null) {
            designerHeader += this.productionOrder.orderNumber;
        } else {
            if (this.offer != null) {
                designerHeader += this.offer.offerNumber;
                if (this.offer.alternateOfferNumber != null) {
                    designerHeader = `${designerHeader} - ${this.offer.alternateOfferNumber}`;
                }
            }
        }
        if (this.designer != null && this.designer.offerPosition != null && this.designer.offerPosition.printOrder) {
            designerHeader = `${designerHeader} - ${this.designer.offerPosition.printOrder}`;
        }
        if (this.headerSuffix != null) {
            designerHeader = `${designerHeader} - ${this.headerSuffix}`;
        }
        return designerHeader;
    }

    onRhombusGrillSizeChange(size: number): void {
        GrillHelper.setSizeForRhombusGrill(this.designer.pendingGrillData.grill, size);
        this.grillParamsChange(GrillType.GRID_RHOMBUS, ['rhombusWidth']);
        this.grillParamsChange(GrillType.GRID_RHOMBUS, ['rhombusHeight']);
    }
}
