<div class="wrapper first-field-wrapper login-wrapper">
    <input [ngModel]="login" (ngModelChange)="loginChange.emit($event)" type="text"
           placeholder="{{ 'LOGIN.FORM.LOGIN' | translate }}" id="login" autofocus name="username" autocomplete="username" />
</div>
<div class="wrapper pass-wrapper">
    <input [ngModel]="password" (ngModelChange)="passwordChange.emit($event)" type="password"
           placeholder="{{ 'LOGIN.FORM.PASSWORD' | translate }}" id="userpass" name="password" autocomplete="current-password" />
</div>
<div class="submit-wrapper">
    <div class="reset-password info-style">
        <i class="material-icons">keyboard_arrow_right</i>
        <a *ngIf="('LOGIN.PASSWORD_RESET.MAIL_SUBJECT' | translate) !== ''
         && ('LOGIN.PASSWORD_RESET.MAIL_SUBJECT' | translate) !== 'LOGIN.PASSWORD_RESET.MAIL_SUBJECT'
         && ('LOGIN.PASSWORD_RESET.MAIL_BODY' | translate) !== ''
         && ('LOGIN.PASSWORD_RESET.MAIL_BODY' | translate) !== 'LOGIN.PASSWORD_RESET.MAIL_BODY' " href="#" tabindex="1"
           (click)="handleResetPasswordClick()">
            {{ 'LOGIN.FORM.RESET_PASSWORD' | translate }}
        </a>
    </div>

    <div class="submit button" tabindex="0" (click)="submit.emit()">
        {{ 'LOGIN.FORM.SUBMIT_BUTTON' | translate }}
    </div>
</div>
