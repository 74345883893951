import * as _ from 'underscore';
import {AutoAlignOption, OpeningOption, WindowSystemInterface, WindowSystemTypeEnum} from '../../../../window-designer/catalog-data/window-system-interface';
import {CompositionDistances} from "../../../../window-designer/composition-distances";
import {Listing} from '../../../common/crud-common/crudItemList';
import {MaterialType} from '../../../common/enums/MaterialType';
import {MultilanguageField} from "../../../supportedLanguages";
import {PricetableSelectionMode} from "../../price-lists/price-table/pricetable-selection-mode";
import {Supplier} from "../../supplier/supplier";
import {Addon} from "../addons/addon";
import {Color} from "../color/color";
import {DecorativeFilling} from "../decorative-filling/decorativeFilling";
import {GlazingBead} from "../glazing-bead/glazing-bead";
import {GlazingPackage} from '../glazing-package/glazing-package';
import {OtherFilling} from "../other-filling/otherFilling";
import {Profile} from "../profile/profile";
import {Seal} from "../seal/Seal";
import {SubwindowTypeLimitations} from "./SubwindowTypeLimitations";
import {WindowSystemDefinitionDrawingToolsEnabler} from './window-system-definition-drawing-tools-enabler';

export class WindowSystemDefinition implements WindowSystemInterface {
    id: number;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    material: MaterialType;
    maxFrameSize: number;
    minAngleF: number;
    minAngleFW: number;
    minAngleR: number;
    minAngleU: number;
    minSideLength: number;
    glazingWidths: string;
    defaultWidth: number;
    defaultHeight: number;
    active: boolean;
    doors: boolean;
    pcn: string;
    supplier: Supplier;
    hsSystem: boolean;
    wingWidth: CompositionDistances;
    glazingBeadWidth: CompositionDistances;
    wingImpositionWidth: CompositionDistances;
    glazingPackageSeatingDepth: CompositionDistances;
    wingsImpositionWidth: number;
    wingsContactWidth: number;
    frameThickness: number;
    frameWingCompositionThickness: number;
    systemThickness: number;
    glazingPackage: GlazingPackage;
    glazingPackageId: number;
    decorativeGlazingPackage: GlazingPackage;
    decorativeGlazingPackageId: number;
    systemType: WindowSystemTypeEnum;
    canCombineBusinessTypes: boolean;
    splitCutBusinessTypes: boolean;
    allowOnlyEqualSubwindows: boolean;
    allowMullionCrossing = true;
    subwindowTypesLimitations: { [typeCode: string]: SubwindowTypeLimitations };
    sortIndex: number;
    possibleOpenings: OpeningOption[];
    windowSystemDrawingToolsEnabler: WindowSystemDefinitionDrawingToolsEnabler;
    roofSystemSortIndex: number;
    entranceSystemSortIndex: number;
    roofSystemFunction: string;
    standardDoorstepAvailable: boolean;
    roofGlazingPackageId: number;
    uw: number;
    dimensionStep: number;
    autoAlign: AutoAlignOption;
    designerDescription: { [propertyId: number]: number; };
    description: { [propertyId: number]: number; };
    specification: { [propertyId: number]: number; };
    tagIds: number[];
    fixedOnly: boolean;
    doorstepAllowedInFF: boolean;
    glamourDisplayUw: number;
    glamourDisplayUwDescription: MultilanguageField;
    glamourRoofHeaderDescription: MultilanguageField;
    glamourRoofDescription1: MultilanguageField;
    glamourRoofDescription2: MultilanguageField;
    ventilationAlwaysOnFrame: boolean;
    allowFestAtHinges: boolean;
    image?: string;
    pricetableSelectionMode: PricetableSelectionMode;
    windowSystemForStandaloneGlazingPackageId: number;
    unitWeight: number;
    canHaveStandaloneGlazingPackages: boolean;
    optionalOfferPrintHeader: MultilanguageField;

    constructor() {
        this.names = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.supplier = new Supplier();
        this.pcn = undefined;
        this.glazingPackage = undefined;
        this.glazingPackageId = undefined;
        this.decorativeGlazingPackage = undefined;
        this.decorativeGlazingPackageId = undefined;
        this.subwindowTypesLimitations = {};
        this.sortIndex = 1;
        this.windowSystemDrawingToolsEnabler = new WindowSystemDefinitionDrawingToolsEnabler();
        this.roofSystemSortIndex = 1;
        this.entranceSystemSortIndex = 1;
        this.autoAlign = AutoAlignOption.TO_FRAMES;
        this.designerDescription = {};
        this.description = {};
        this.specification = {};
        this.tagIds = [];
        this.glamourDisplayUwDescription = new MultilanguageField();
        this.glamourRoofHeaderDescription = new MultilanguageField();
        this.glamourRoofDescription1 = new MultilanguageField();
        this.glamourRoofDescription2 = new MultilanguageField();
        this.wingWidth = new CompositionDistances();
        this.glazingBeadWidth = new CompositionDistances();
        this.wingImpositionWidth = new CompositionDistances();
        this.glazingPackageSeatingDepth = new CompositionDistances();
        this.dimensionStep = 1;
        this.pricetableSelectionMode = PricetableSelectionMode.WINDOW;
        this.optionalOfferPrintHeader = new MultilanguageField();
    }

    static copy(obj?: WindowSystemDefinition): WindowSystemDefinition {
        let copy: WindowSystemDefinition = new WindowSystemDefinition();
        copy.id = obj.id;
        copy.names = obj.names;
        copy.shortcut = obj.shortcut;
        copy.symbol = obj.symbol;
        copy.material = obj.material;
        copy.maxFrameSize = obj.maxFrameSize;
        copy.minAngleF = obj.minAngleF;
        copy.minAngleFW = obj.minAngleFW;
        copy.minAngleR = obj.minAngleR;
        copy.minAngleU = obj.minAngleU;
        copy.minSideLength = obj.minSideLength;
        copy.glazingWidths = obj.glazingWidths;
        copy.defaultWidth = obj.defaultWidth;
        copy.defaultHeight = obj.defaultHeight;
        copy.active = obj.active;
        copy.doors = obj.doors;
        copy.pcn = obj.pcn;
        copy.supplier = _.clone(obj.supplier);
        copy.hsSystem = obj.hsSystem;
        copy.wingWidth = _.clone(obj.wingWidth);
        copy.glazingBeadWidth = _.clone(obj.glazingBeadWidth);
        copy.wingImpositionWidth = _.clone(obj.wingImpositionWidth);
        copy.glazingPackageSeatingDepth = _.clone(obj.glazingPackageSeatingDepth);
        copy.wingsImpositionWidth = obj.wingsImpositionWidth;
        copy.wingsContactWidth = obj.wingsContactWidth;
        copy.frameThickness = obj.frameThickness;
        copy.frameWingCompositionThickness = obj.frameWingCompositionThickness;
        copy.systemThickness = obj.systemThickness;
        copy.glazingPackage = obj.glazingPackage;
        copy.glazingPackageId = obj.glazingPackageId;
        copy.decorativeGlazingPackage = obj.decorativeGlazingPackage;
        copy.decorativeGlazingPackageId = obj.decorativeGlazingPackageId;
        copy.subwindowTypesLimitations = _.clone(obj.subwindowTypesLimitations);
        copy.systemType = obj.systemType;
        copy.canCombineBusinessTypes = obj.canCombineBusinessTypes;
        copy.splitCutBusinessTypes = obj.splitCutBusinessTypes;
        copy.allowOnlyEqualSubwindows = obj.allowOnlyEqualSubwindows;
        copy.allowMullionCrossing = obj.allowMullionCrossing;
        copy.sortIndex = obj.sortIndex;

        copy.possibleOpenings = obj.possibleOpenings;
        copy.windowSystemDrawingToolsEnabler = _.clone(obj.windowSystemDrawingToolsEnabler);
        copy.standardDoorstepAvailable = obj.standardDoorstepAvailable;

        copy.roofSystemFunction = obj.roofSystemFunction;
        copy.roofSystemSortIndex = obj.roofSystemSortIndex;
        copy.entranceSystemSortIndex = obj.entranceSystemSortIndex;
        copy.roofGlazingPackageId = obj.roofGlazingPackageId;
        copy.autoAlign = obj.autoAlign;
        copy.uw = obj.uw;
        copy.designerDescription = _.clone(obj.designerDescription);
        copy.description = _.clone(obj.description);
        copy.specification = _.clone(obj.specification);
        copy.tagIds = [...obj.tagIds];
        copy.fixedOnly = obj.fixedOnly;
        copy.doorstepAllowedInFF = obj.doorstepAllowedInFF;

        copy.glamourDisplayUw = obj.glamourDisplayUw;
        copy.glamourDisplayUwDescription = obj.glamourDisplayUwDescription || new MultilanguageField();
        copy.glamourRoofHeaderDescription = obj.glamourRoofHeaderDescription || new MultilanguageField();
        copy.glamourRoofDescription1 = obj.glamourRoofDescription1 || new MultilanguageField();
        copy.glamourRoofDescription2 = obj.glamourRoofDescription2 || new MultilanguageField();

        copy.ventilationAlwaysOnFrame = obj.ventilationAlwaysOnFrame;
        copy.dimensionStep = obj.dimensionStep;
        copy.allowFestAtHinges = obj.allowFestAtHinges;

        copy.pricetableSelectionMode = obj.pricetableSelectionMode;
        copy.windowSystemForStandaloneGlazingPackageId = obj.windowSystemForStandaloneGlazingPackageId;
        copy.unitWeight = obj.unitWeight;
        copy.canHaveStandaloneGlazingPackages = obj.canHaveStandaloneGlazingPackages;
        copy.optionalOfferPrintHeader = obj.optionalOfferPrintHeader
            ? MultilanguageField.fromJSON(obj.optionalOfferPrintHeader)
            : new MultilanguageField();
        return copy;
    }
}

export type WindowSystemDefinitionList = Listing<WindowSystemDefinition>;

export type WindowSystemsColorsDefinitionList = Listing<Color>;
export type WindowSystemsDecorativeFillingsDefinitionList = Listing<DecorativeFilling>;
export type WindowSystemsOtherFillingsDefinitionList = Listing<OtherFilling>;
export type WindowSystemsSealsDefinitionList = Listing<Seal>;
export type WindowSystemsFrameProfilesList = Listing<Profile>;
export type WindowSystemsAddOnsList = Listing<Addon>;
export type WindowSystemsGlazingBeadsList = Listing<GlazingBead>;

export interface WindowSystemName {
    id: number;
    names: MultilanguageField;
}

export enum RoofSystemFunction {
    CENTRE_PIVOT_WINDOWS = 'CENTRE_PIVOT_WINDOWS',
    TOP_HUNG_WINDOWS = 'TOP_HUNG_WINDOWS',
    HIGH_PIVOT_WINDOWS = 'HIGH_PIVOT_WINDOWS',
    FLAT_ROOF_WINDOWS = 'FLAT_ROOF_WINDOWS',
    WINDOWS_WITH_ELECTRIC_MOTOR = 'WINDOWS_WITH_ELECTRIC_MOTOR',
    L_SHAPED_WINDOWS = 'L_SHAPED_WINDOWS',
    FIXED = 'FIXED'
}
