import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {map} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WindowDimensions} from '../../../../window-designer/entities/window-dimensions';
import {SingleSystemCheckboxCrudService} from "../single-system-checkbox-crud/single-system-checkbox-crud.service";
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {WindowDimensionsWithMaterials} from "./window-dimensions-with-materials";

@Injectable()
export class WindowDimensionsService extends SingleSystemCheckboxCrudService<WindowDimensions> {

    private static readonly API_URL = 'windowDimensions';

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string,
             sortOrder: number): Observable<Listing<WindowDimensionsWithMaterials>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<WindowDimensionsWithMaterials>>(WindowDimensionsService.API_URL, {params: params});
    }

    forSystem(type: ProductTypeGroup, systemId: number, selectedIds: number[], readOnly: boolean): Observable<WindowDimensions[]> {
        const params = new HttpParams();
        if (selectedIds != undefined) {
            params.set('selectedIds', selectedIds.join())
        }
        if (readOnly != undefined) {
            params.set('readOnly', readOnly)
        }
        return this.http.get<WindowDimensions[]>(
            `${WindowDimensionsService.API_URL}/${type === ProductTypeGroup.ROOF ? 'forRoofSystem' :
                'forEntranceSystem'}/${systemId}`, {params: params});
    }

    addItem(item: WindowDimensions): Observable<number> {
        return this.saveItem(item);
    }

    editItem(itemId: number, item: WindowDimensions): Observable<number> {
        return this.saveItem(item);
    }

    saveItem(item: WindowDimensions): Observable<number> {
        return this.http.post(WindowDimensionsService.API_URL, item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(WindowDimensionsService.API_URL, response)));
    }

    getItem(itemId: number): Observable<WindowDimensions> {
        return this.http.get<WindowDimensions>(`${WindowDimensionsService.API_URL}/${itemId}`);
    }

    copy(id: number, item: WindowDimensions): Observable<number> {
        return this.http.post(`${WindowDimensionsService.API_URL}/${id}/copy`, item, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(WindowDimensionsService.API_URL, response)));
    }

    validate(item: WindowDimensions): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${WindowDimensionsService.API_URL}/validate`, item));
    }
}
