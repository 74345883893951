import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DropDownExtraOptions} from '../../../../../shared/drop-down-extra-options';
import {DropDownExtraOptionsLabels, DropDownExtraOptionsProvider} from '../../../../../shared/DropDownExtraOptionsProvider';
import {AddonInterface} from '../../../../../window-designer/catalog-data/addon-interface';
import {ColorInterface} from '../../../../../window-designer/catalog-data/color-interface';
import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {OpeningOption} from '../../../../../window-designer/catalog-data/window-system-interface';
import {FittingTerraceLockLocation} from '../../../../../window-designer/drawing-data/FittingTerraceLockLocation';
import {TerraceHandleLayout} from '../../../../../window-designer/drawing-data/TerraceHandleLayout';
import {WindowView} from '../../../../../window-designer/drawing-data/WindowView';
import {WindowDimensions, WindowDimensionsUtils} from "../../../../../window-designer/entities/window-dimensions";
import {CrudItem} from '../../../../common/crud-common/crudItem';
import {SelectItemImpl} from '../../../../common/service/select.item.impl';
import {SelectItemExtended} from "../../../../form-inputs/inputs/select/select.component";
import {CatalogOption} from "../../../catalog-creator/catalog-option/catalog-option";
import {BusinessTypeBasic} from "../../../window-system/business-type/BusinessTypeBasic";
import {DecorativeFilling} from '../../../window-system/decorative-filling/decorativeFilling';
import {DistanceFrame} from '../../../window-system/distance-frame/distanceFrame';
import {Glass} from '../../../window-system/glass/glass';
import {GlazingBead} from "../../../window-system/glazing-bead/glazing-bead";
import {GlazingPackage} from '../../../window-system/glazing-package/glazing-package';
import {GraspDistanceFrameCategory} from '../../../window-system/grasp-distance-frame-category/grasp-distance-frame-category';
import {GraspGlazingCategory} from "../../../window-system/grasp-glazing-categories/grasp-glazing-category";
import {GraspGlazingPackage} from "../../../window-system/grasp-glazing-package/grasp-glazing-package";
import {Grill as GrillDto} from "../../../window-system/grill/grill";
import {OtherFilling} from '../../../window-system/other-filling/otherFilling';
import {Profile} from "../../../window-system/profile/profile";
import {Seal} from "../../../window-system/seal/Seal";
import {GlazingPackageData} from '../roof-window-editor/glazing-package-data';
import {WindowEditorWindowSystemInterface} from '../window-editor-window-system-interface';
import {CatalogItemNameAndActive} from "../../../../common/crud-common/catalog-item-name-and-active";

export class SelectItemFormatters {

    windowSystemsOptionFormatter: (windowSystem: WindowEditorWindowSystemInterface) => SelectItem;
    profileOptionFormatter: (profile: Profile) => SelectItem;
    addonOptionFormatter: (addon: AddonInterface) => SelectItem;
    windowFunctionOptionFormatter: (view: string) => SelectItem;
    colorOptionFormatter: (color: ColorInterface) => SelectItem;
    colorWithGroupOptionFormatter: (color: ColorInterface) => SelectItem;
    colorOptionKey: (colorOrId: ColorInterface | number) => number | string;
    fillingTypeOptionFormatter: (fillingType: string) => SelectItem;
    otherFillingWithColorsFormatter: (filling: OtherFilling) => SelectItem;
    decorativeFillingWithColorsFormatter: (filling: DecorativeFilling) => SelectItem;
    glazingBeadOptionFormatter: (glazingBead: GlazingBead) => SelectItem;
    grillOptionFormatter: (grill: GrillDto) => SelectItem;
    glazingPackageOptionFormatter: (glazing: GlazingPackage) => SelectItem;
    catalogOptionFormatter: (option: CatalogOption) => SelectItem;
    idAndNameFormatter: (item: CrudItem & ({ name: MultilanguageFieldInterface } | { names: MultilanguageFieldInterface })) => SelectItem;
    doorOpeningFormatter: (doorOpening: OpeningOption) => SelectItem;

    dropDownExtraOptionsProvider: DropDownExtraOptionsProvider;
    translate: TranslateService;

    constructor(translate: TranslateService) {
        this.translate = translate;
        this.dropDownExtraOptionsProvider = new DropDownExtraOptionsProvider(translate);

        this.windowSystemsOptionFormatter =
            (windowSystem: WindowEditorWindowSystemInterface) => this.formatWindowSystemOption(windowSystem);
        this.profileOptionFormatter = (profile: Profile) => this.formatProfileOption(profile);
        this.addonOptionFormatter = (addon: AddonInterface) => this.formatAddonOption(addon);
        this.windowFunctionOptionFormatter = (windowFunction: string) => this.formatWindowFunctionOption(windowFunction);
        this.colorOptionFormatter = (color: ColorInterface) => this.formatColorOption(color);
        this.fillingTypeOptionFormatter = (fillingType: string) => this.formatFillingTypeOption(fillingType);
        this.otherFillingWithColorsFormatter = (filling: OtherFilling) => this.formatOtherFilling(filling);
        this.decorativeFillingWithColorsFormatter =
            (filling: DecorativeFilling) => this.formatDecorativeFilling(filling);
        this.colorWithGroupOptionFormatter = (color: ColorInterface) => this.formatColorWithGroupOption(color);
        this.colorOptionKey = (colorOrId: ColorInterface | number | string) => {
            if (colorOrId == undefined) {
                return undefined;
            }
            if (typeof colorOrId === 'number' || typeof colorOrId === 'string') {
                return colorOrId;
            }
            return colorOrId.id;
        };
        this.glazingBeadOptionFormatter = (glazingBead: GlazingBead) => this.formatGlazingBeadOption(glazingBead);
        this.grillOptionFormatter = (grill: GrillDto) => this.formatGrillOption(grill);
        this.glazingPackageOptionFormatter = (glazing: GlazingPackage) => this.formatGlazingPackageOption(glazing);
        this.catalogOptionFormatter = (option: CatalogOption) => this.formatCatalogOption(option);
        this.idAndNameFormatter = (item) => this.formatIdAndNameOption(item);
        this.doorOpeningFormatter = (doorOpening: OpeningOption) => this.formatDoorOpeningEnumOption(doorOpening);
    }

    formatWindowSystemOption(windowSystem: WindowEditorWindowSystemInterface): SelectItem {
        return {
            label: windowSystem.names[this.translate.currentLang],
            value: windowSystem.id,
            available: true
        };
    }

    formatProfileOptions(profiles: Profile[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = profiles ? profiles.map(profile => this.formatProfileOption(profile)) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE));
        }

        return selectItems;
    }

    formatProfileOption(profile: Profile): SelectItem {
        return {
            label: profile.names[this.translate.currentLang],
            value: profile.id,
            available: profile.active
        };
    }

    formatAddonOptions(addons: AddonInterface[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = addons ? addons.map(addon => this.formatAddonOption(addon)) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE));
        }

        return selectItems;
    }

    formatAddonOption(addon: AddonInterface): SelectItem {
        const item: SelectItem = {
            label: addon.name[this.translate.currentLang],
            value: addon.id,
            available: addon.active
        };
        (item as SelectItemExtended).singlePositionItem = addon.singlePositionAddon;
        return item;
    }

    formatColorIdOptions(colors: ColorInterface[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = colors ? colors.map(color => this.formatColorIdWithGroupOption(color)) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE));
        }

        return selectItems;
    }

    formatColorOption(color: ColorInterface): SelectItem {
        return {
            label: color.names[this.translate.currentLang],
            value: color.id,
            available: color.active
        };
    }

    formatNameAndActiveOption(item: CatalogItemNameAndActive): SelectItem {
        return {
            label: item.name[this.translate.currentLang],
            value: item.id,
            available: item.active
        };
    }

    formatViewOptions(views: WindowView[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = views ? views.map(view => this.formatViewOption(view)) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE));
        }

        return selectItems;
    }

    formatViewOption(view: WindowView): SelectItem {
        return {
            label: 'OFFER.WINDOW_VIEW.' + WindowView[view],
            value: view,
            available: true
        };
    }

    formatTerraceLockLocationOptions(terraceLockLocations: FittingTerraceLockLocation[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = terraceLockLocations
            ? terraceLockLocations.map(terraceLockLocation => this.formatTerraceLockLocationOption(terraceLockLocation))
            : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE));
        }

        return selectItems;
    }

    formatTerraceLockLocationOption(lockLocation: FittingTerraceLockLocation): SelectItem {
        return {
            label: 'TERRACE_LOCK_LOCATION.' + lockLocation,
            value: lockLocation,
            available: true
        };
    }

    formatWindowFunctionOptions(windowFunctions: string[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = windowFunctions ? windowFunctions.map(windowFunction => this.formatWindowFunctionOption(windowFunction)) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE));
        }

        return selectItems;
    }

    formatWindowFunctionOption(windowFunction: string): SelectItem {
        return {
            label: 'OFFER.FUNCTION.' + windowFunction,
            value: windowFunction,
            available: true
        };
    }

    formatDoorOpeningOptions(doorOpenings: OpeningOption[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = doorOpenings ? doorOpenings.map(doorOpening => this.formatDoorOpeningOption(doorOpening.toString())) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE));
        }

        return selectItems;
    }

    formatDoorOpeningOption(doorOpening: string): SelectItem {
        return {
            label: 'OFFER.OPENING.' + doorOpening,
            value: doorOpening,
            available: true
        };
    }

    formatDoorOpeningEnumOption(doorOpening: OpeningOption): SelectItem {
        return {
            label: 'OFFER.OPENING.' + doorOpening.toString(),
            value: doorOpening,
            available: true
        };
    }

    formatSealOptions(seals: Seal[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = seals ? seals.map(seal => this.formatSealOption(seal)) : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE));
        }

        return selectItems;
    }

    formatSealOption(seal: Seal): SelectItem {
        return {
            label: seal.names[this.translate.currentLang],
            value: seal.id,
            available: seal.active
        };
    }

    formatColorWithGroupOption(color: ColorInterface): SelectItem {
        return {
            label: (!color.group ? '' : color.group + ' - ') + color.names[this.translate.currentLang],
            value: color,
            available: color.active
        };
    }

    formatColorIdWithGroupOption(color: ColorInterface): SelectItem {
        return {
            label: (!color.group ? '' : color.group + ' - ') + color.names[this.translate.currentLang],
            value: color.id,
            available: color.active
        };
    }

    formatFillingTypeOption(fillingType: string): SelectItem {
        return {
            label: 'OFFER.FILLING_TYPE.' + fillingType,
            value: fillingType,
            available: true
        };
    }

    formatOtherFilling(filling: OtherFilling): SelectItem {
        return {
            label: filling.name[this.translate.currentLang],
            value: filling.id,
            available: filling.active
        };
    }

    formatDecorativeFilling(filling: DecorativeFilling): SelectItem {
        return {
            label: filling.names[this.translate.currentLang],
            value: filling.id,
            available: filling.active
        };
    }

    formatBusinessType(businessType: BusinessTypeBasic): SelectItem {
        return {
            label: businessType.name[this.translate.currentLang],
            value: businessType.type,
            available: businessType.active
        };
    }

    formatGlass(glass: Glass): SelectItem {
        return {
            label: glass.name[this.translate.currentLang],
            value: glass.id,
            available: glass.active
        };
    }

    formatDistanceFrame(frame: DistanceFrame): SelectItem {
        return {
            label: frame.names[this.translate.currentLang],
            value: frame.id,
            available: frame.active
        };
    }

    formatGlazingBeadOption(glazingBead: GlazingBead): SelectItem {
        return {
            label: glazingBead.names[this.translate.currentLang],
            value: glazingBead.id,
            available: glazingBead.active
        };
    }

    formatGrillOption(grill: GrillDto): SelectItem {
        return {
            label: grill.name[this.translate.currentLang],
            value: grill.id,
            available: grill.active
        };
    }

    formatGlazingPackageOption(glazing: GlazingPackage): SelectItem {
        return {
            label: glazing.names[this.translate.currentLang],
            value: glazing.id,
            available: glazing.active
        };
    }

    formatCatalogOption(option: CatalogOption): SelectItem {
        return {
            label: option.symbol,
            value: option.id,
            available: option.active
        };
    }

    formatWindowDimensions(dimensions: WindowDimensions): SelectItem {
        return {
            label: WindowDimensionsUtils.isOverriden(dimensions.id) ?
                this.translate.instant('WINDOW_DIMENSIONS.OVERRIDE') : `${dimensions.width}x${dimensions.height}mm`,
            value: dimensions.id,
            available: dimensions.active
        };
    }

    formatTerraceHandleLayoutOptions(terraceHandleLayouts: TerraceHandleLayout[], webshopChargeMode: boolean): SelectItem[] {
        let selectItems = terraceHandleLayouts
            ? terraceHandleLayouts.map(terraceHandleLayout => this.formatTerraceHandleLayoutOption(terraceHandleLayout))
            : [];

        if (webshopChargeMode) {
            selectItems.unshift(this.getExtraOptionSelectItem(DropDownExtraOptionsLabels.DO_NOT_CHANGE, DropDownExtraOptions.DO_NOT_CHANGE));
        }

        return selectItems;
    }

    formatTerraceHandleLayoutOption(terraceHandleLayout: TerraceHandleLayout): SelectItem {
        return {
            label: `TERRACE_HANDLE_LAYOUT.${terraceHandleLayout}`,
            value: terraceHandleLayout,
            available: true
        };
    }

    getExtraOptionSelectItem(label: DropDownExtraOptionsLabels, value: DropDownExtraOptions): SelectItemImpl {
        return new SelectItemImpl(this.dropDownExtraOptionsProvider.getLabel(label), value);
    }

    formatIdAndNameOption(item: CrudItem & ({ name: MultilanguageFieldInterface } | { names: MultilanguageFieldInterface })): SelectItem {
        let name = item['name'] == null ? item['names'] : item['name'];
        return {
            label: name[this.translate.currentLang],
            value: item.id,
            available: true
        };
    }

    formatGlazingPackageQuantity(quantity: number): SelectItem {
        return {
            label: quantity.toString(),
            value: quantity,
            available: true
        };
    }

    formatGraspGlazingOption(glazingItem: GraspGlazingCategory | GraspDistanceFrameCategory | GraspGlazingPackage): SelectItem {
        return {
            label: glazingItem.name[this.translate.currentLang],
            value: glazingItem.id,
            available: glazingItem.active
        };
    }
}
