import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import * as moment from 'moment/moment';
import {SelectItem} from 'primeng/api/selectitem';
import {LocaleSettings} from 'primeng/calendar';
import {Observable, Subscription} from 'rxjs';
import {ApplicationFeatures} from '../../../application-info/application-features';
import {Permissions} from '../../../auth/permission.service';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {Currencies} from '../../../currencies';
import {CalendarLocaleProvider} from '../../../form-inputs/inputs/calendar/calendar-locale-provider';
import {User} from '../../user/user';
import {Subsystem, SubsystemType, TransportType} from '../subsystem';

@Component({
    selector: 'app-subsystem-details-form',
    templateUrl: './subsystem-details-form.component.html',
    providers: [TranslatedSelectItemService]
})
export class SubsystemDetailsFormComponent implements OnInit, OnDestroy, OnChanges {

    @Input() subsystem: Subsystem;
    @Input() validationErrors: any;
    @Input() availableManagers: User[];
    @Input() subsystemGroups: SelectItem[];
    selectCurrencies: Observable<SelectItem[]>;
    subsystemTypes: Observable<SelectItem[]>;
    @Input() disabledFields = false;
    transportTypeOptions: Observable<SelectItem[]>;
    TransportType = TransportType;
    webshopAlreadyActive = false;
    availableSubManagers: User[];

    @Output() readonly onSubsystemGroupChanged = new EventEmitter<number>();

    calendarLocale: LocaleSettings;
    private langChangeSubscription: Subscription;

    constructor(public readonly permissions: Permissions,
                private readonly translate: TranslateService,
                private readonly translatedSelectItemService: TranslatedSelectItemService,
                private readonly route: ActivatedRoute,
                private readonly calendarLocaleProvider: CalendarLocaleProvider) {
        this.calendarLocale = this.calendarLocaleProvider.getPrimengCalendarSettings(this.translate.currentLang);
    }

    ngOnInit() {
        this.selectCurrencies = this.translatedSelectItemService.buildSortedDropdown(Currencies, '', undefined);
        this.subsystemTypes = this.translatedSelectItemService.buildUnsortedDropdown(SubsystemType, 'SUBSYSTEM_TYPE.', undefined);
        this.transportTypeOptions = this.translatedSelectItemService.buildSortedDropdown(TransportType,
            'SUBSYSTEM-DETAILS.FORM.TRANSPORT_TYPE.', undefined);
        this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.calendarLocale = this.calendarLocaleProvider.getPrimengCalendarSettings(event.lang);
            if (this.subsystem.subsystemExpirationDate != undefined) {
                this.subsystem.subsystemExpirationDate = new Date(this.subsystem.subsystemExpirationDate.getTime());
            }
        });
    }

    ngOnDestroy(): void {
        if (this.langChangeSubscription != undefined) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    clientManagerFormatter(user: User): SelectItem {
        return {
            label: user.login,
            value: user,
            available: true
        };
    }

    clientManagerKey(user: User): string {
        return user != undefined ? user.login : undefined;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('subsystem' in changes) {
            let subsystem = changes['subsystem'].currentValue as Subsystem;
            if (subsystem != null) {
                this.webshopAlreadyActive = subsystem.webShopEnabled;
                if (subsystem.clientManager != null) {
                    this.clientManagerChanged(subsystem.clientManager);
                }
            }
        }
    }

    calculateNonEuPercent(): void {
        if (this.subsystem.euTransportPercent != null && this.subsystem.euTransportPercent >= 0 &&
            this.subsystem.euTransportPercent <= 100) {
            this.subsystem.nonEuTransportPercent = 100 - this.subsystem.euTransportPercent;
        } else {
            this.subsystem.nonEuTransportPercent = undefined;
        }
    }

    subsystemGroupChanged(event: number): void {
        this.subsystem.subsystemGroupId = event;
        this.onSubsystemGroupChanged.emit(this.subsystem.subsystemGroupId);
    }

    clientManagerChanged(selectedManager: User): void {
        this.availableSubManagers = this.availableManagers
            ? this.availableManagers.filter(manager => manager.id !== selectedManager.id)
            : [];
    }

    handleSubsystemExpirationDateChange(date: Date): void {
        this.subsystem.subsystemExpirationDate = moment(date).endOf('day').toDate();
        this.validationErrors['subsystemExpirationDate'] = undefined;
    }

    get enableSalesTargets(): boolean {
        return (this.route.snapshot.data['features'] as ApplicationFeatures).enableSalesTargets;
    }
}
