import {
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    createComponent,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {AbstractWindowDesigner} from '../../../../../../../window-designer/abstract-window-designer';
import {CachingWindowDesignerDataService} from '../../../../../../../window-designer/caching-window-designer-data-service';
import {ProfileInterface} from '../../../../../../../window-designer/catalog-data/profile-interface';
import {WindowSystemInterface} from '../../../../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData} from '../../../../../../../window-designer/drawing-data/drawing-data';
import {FillingType} from '../../../../../../../window-designer/drawing-data/FillingType';
import {ProfilesCompositionDistances} from '../../../../../../../window-designer/profiles-composition-distances';
import {WindowDesignerDataServiceInterface} from '../../../../../../../window-designer/window-designer-data.service-interface';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../../../common/CommonErrorHandler';
import {WindowSystemDefaultsState} from "../../../../../settings/system-defaults/system-default-state";
import {WindowSystemDefinition} from "../../../../../window-system/window-system-definition/window-system-definition";
import {WindowSystemDefinitionService} from '../../../../../window-system/window-system-definition/window-system-definition.service';
import {Pricing} from '../../../../window-editor/sidebar/pricing/Pricing';
import {PricingService} from '../../../../window-editor/sidebar/pricing/pricing.service';
import {SystemDefaultsService} from '../../../../window-editor/system-defaults.service';
import {WindowDesignerDataService} from '../../../../window-editor/window-designer/window-designer-data.service';
import {WindowDesignerComponent} from '../../../../window-editor/window-designer/window-designer.component';
import {MessageSeverity, PositionMessage} from '../../../message';
import {PositionService} from '../../position.service';
import {
    standaloneGlazingPackageWindowDesignerVisibilitySettings
} from '../add-standalone-glazing-package/standalone-glazing-package-window-designer-visibility-settings';
import {Position, PositionFactory} from '../position';
import {AddStandaloneGlazingPackagesGloballyDialogData} from '../position-list-dialogs';

@Component({
    selector: 'app-add-standalone-glazing-packages-globally',
    templateUrl: './add-standalone-glazing-packages-globally.component.html',
    styleUrls: ['../bulk-change-confirmation/bulk-change-confirmation.component.css'],
    providers: [SystemDefaultsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddStandaloneGlazingPackagesGloballyComponent implements OnInit {

    private static readonly BLOCK_SOURCE_ID = 'AddStandaloneGlazingPackagesGloballyComponent';

    @Input()
    dialogData: AddStandaloneGlazingPackagesGloballyDialogData;

    @Output()
    readonly saved = new EventEmitter<void>();

    @Output()
    readonly closed = new EventEmitter<void>();

    visible = false;
    readonly resultsByPosition: { [offerPositionId: number]: { pricing: Pricing; messages: PositionMessage[] } } = {};

    private packagePositions: Position[] = [];

    private readonly windowDesignerDataService: WindowDesignerDataServiceInterface;

    constructor(private readonly windowSystemService: WindowSystemDefinitionService,
                private readonly windowSystemDefaultsService: SystemDefaultsService,
                windowDesignerDataService: WindowDesignerDataService,
                private readonly positionService: PositionService,
                private readonly pricingService: PricingService,
                private readonly errors: CommonErrorHandler,
                private readonly changeDetector: ChangeDetectorRef,
                private readonly application: ApplicationRef,
                private readonly blockUiController: BlockUiController,
                private readonly injector: Injector) {
        this.windowDesignerDataService = new CachingWindowDesignerDataService(windowDesignerDataService);
    }

    ngOnInit() {
        this.blockUiController.block(AddStandaloneGlazingPackagesGloballyComponent.BLOCK_SOURCE_ID);
        for (let position of this.dialogData.positions) {
            this.resultsByPosition[position.id] = {pricing: undefined, messages: []};
        }
        this.createGlazingPackagePositions().pipe(
            tap(packages => this.packagePositions = packages),
            mergeMap(packages => {
                if (packages.length === 0) {
                    return of<Pricing[]>([]);
                }
                const pricingObservables = packages.map((packagePosition) => {
                    return this.windowDesignerDataService.getWindowSystem(packagePosition.windowSystemId).pipe(
                        mergeMap(windowSystem => {
                            const drawingData = JSON.parse(packagePosition.data);
                            // PricingUtils.enhanceForPricing already done in createGlazingPackagePositions
                            return this.pricingService.evaluate(false, drawingData, null, packagePosition.offerId, undefined, windowSystem,
                                false, true).pipe(tap(pricing => {
                                    this.resultsByPosition[packagePosition.parentOfferPositionId].pricing = pricing;
                                    for (let message of Pricing.getAllMessages(pricing)) {
                                        const alreadyContainsMessage = this.resultsByPosition[packagePosition.parentOfferPositionId].messages.findIndex(existing => {
                                            return message.fieldName === existing.fieldName && message.messageCode === existing.messageCode;
                                        }) >= 0;
                                        if (!alreadyContainsMessage) {
                                            this.resultsByPosition[packagePosition.parentOfferPositionId].messages.push(message);
                                        }
                                    }
                                })
                            );
                        })
                    );
                });
                return forkJoin(pricingObservables);
            })
        ).subscribe(() => {
            this.visible = true;
            this.changeDetector.markForCheck();
            this.blockUiController.unblock(AddStandaloneGlazingPackagesGloballyComponent.BLOCK_SOURCE_ID);
        });
    }

    positionContainErrors(offerPosition: Position): boolean {
        return this.positionWithIdContainErrors(offerPosition.id);
    }

    somePositionsContainErrors(): boolean {
        return this.dialogData.positions.some(position => this.positionContainErrors(position));
    }

    allPositionsContainErrors(): boolean {
        return this.dialogData.positions.every(position => this.positionContainErrors(position));
    }

    private createGlazingPackagePositions(): Observable<Position[]> {
        type DataForWindowSystem = {
            system: WindowSystemInterface,
            defaults: WindowSystemDefaultsState,
            profiles: ProfileInterface[]
        };
        const observables: { [windowSystemId: number]: Observable<DataForWindowSystem>; } = {};
        this.dialogData.positions.reduce((agg, position) => {
            agg[position.windowSystemId] = this.windowSystemService.getWindowSystemToUseForStandaloneGlazingPackage(position.windowSystemId)
                .pipe(mergeMap(windowSystemId => forkJoin({
                    system: this.windowDesignerDataService.getWindowSystem(windowSystemId),
                    defaults: this.windowSystemDefaultsService.getDefaultsForWindow(windowSystemId, position.offerId, false, true),
                    profiles: this.windowDesignerDataService.getProfiles(windowSystemId).pipe(map(listing => listing.data))
                })));
            return agg;
        }, observables);

        return forkJoin(observables).pipe(map((catalogData: {
            [windowSystemId: number]: DataForWindowSystem
        }) => {
            const positions: Position[] = [];
            for (const windowPosition of this.dialogData.positions) {
                const dataForWindowSystem = catalogData[windowPosition.windowSystemId];
                if (!(dataForWindowSystem.system as WindowSystemDefinition).canHaveStandaloneGlazingPackages) {
                    const message: PositionMessage = {
                        fieldName: "MAIN.WINDOW_SYSTEM",
                        messageCode: "error.offerPosition.bulkChange.dataConsistency.standaloneGlazingPackage",
                        messageCodeParams: {},
                        severity: MessageSeverity.ERROR,
                        validationMessage: false,
                        createdByRule: undefined
                    };
                    const pricing = new Pricing();
                    pricing.validationMessages.push(message);
                    this.resultsByPosition[windowPosition.id].pricing = pricing;
                    this.resultsByPosition[windowPosition.id].messages.push(message);
                    continue;
                }
                const windowDrawingData: DrawingData = JSON.parse(windowPosition.data);
                for (let window of windowDrawingData.windows) {
                    for (let subWindow of window.subWindows) {
                        for (let area of subWindow.areasSpecification) {
                            if (!area.rectangularShape || area.filling.type !== FillingType.GLASS) {
                                // has cuts or filling is not glass, ignore
                                continue;
                            }

                            const profileCompositionDistances = new ProfilesCompositionDistances();
                            profileCompositionDistances.prepareSystem(dataForWindowSystem.system);
                            profileCompositionDistances.prepareProfileDistances(dataForWindowSystem.profiles,
                                dataForWindowSystem.defaults.value);
                            const drawingData = AbstractWindowDesigner.createDrawingDataForFWindowForGlazingPackageFromArea(
                                area.realPackageWidth, area.realPackageHeight, dataForWindowSystem.system, profileCompositionDistances,
                                dataForWindowSystem.defaults.value, windowDrawingData.specification.sealInternalId,
                                windowDrawingData.specification.sealExternalId, area);

                            const position = PositionFactory.standaloneGlazingPackage(windowPosition.offerId);
                            position.data = JSON.stringify(drawingData);
                            position.parentOfferPositionId = windowPosition.id;
                            position.windowSystemId = drawingData.windowSystemId;
                            position.dimensions = `${area.realPackageWidth}x${area.realPackageHeight}`;
                            position.otherInfo = `Pakiet szybowy o wymiarze ${area.realPackageWidth}x${area.realPackageHeight}`;
                            positions.push(position);
                        }
                    }
                }
            }
            return positions;
        }));
    }

    submit(accepted: boolean): void {
        if (!accepted) {
            this.visible = false;
            this.closed.emit();
            return;
        }
        this.blockUiController.block(AddStandaloneGlazingPackagesGloballyComponent.BLOCK_SOURCE_ID);
        const windowDesignerComponentRef = createComponent(WindowDesignerComponent, {
            environmentInjector: this.application.injector,
            elementInjector: this.injector
        });
        const windowDesigner = windowDesignerComponentRef.instance;
        windowDesigner.redrawPreviewMode = true;
        windowDesigner.visibilitySettings = standaloneGlazingPackageWindowDesignerVisibilitySettings;
        windowDesignerComponentRef.changeDetectorRef.detectChanges();
        windowDesigner.ngOnInit();
        of(this.packagePositions).pipe(mergeMap(positions => {
            if (positions.length === 0) {
                return of<void>(undefined);
            }
            const catalogDataCache = new CachingWindowDesignerDataService(this.windowDesignerDataService);
            let saveChain = windowDesigner.getSvgsForRedrawing(positions[0].data, catalogDataCache)
                .pipe(mergeMap(svgs => this.positionService.saveItem(positions[0], svgs)));
            for (let i = 1; i < positions.length; ++i) {
                saveChain = saveChain.pipe(mergeMap(() => {
                    return windowDesigner.getSvgsForRedrawing(positions[i].data, catalogDataCache)
                        .pipe(mergeMap(svgs => this.positionService.saveItem(positions[i], svgs)));
                }));
            }
            return saveChain;
        })).subscribe({
            complete: () => {
                this.blockUiController.unblock(AddStandaloneGlazingPackagesGloballyComponent.BLOCK_SOURCE_ID);
                this.visible = false;
                this.changeDetector.markForCheck();
                windowDesignerComponentRef.destroy();
                this.saved.emit();
            },
            error: error => {
                this.blockUiController.unblock(AddStandaloneGlazingPackagesGloballyComponent.BLOCK_SOURCE_ID);
                windowDesignerComponentRef.destroy();
                this.errors.handle(error);
                this.changeDetector.markForCheck();
            }
        });
    }

    private positionWithIdContainErrors(positionId: number): boolean {
        if (this.resultsByPosition[positionId].pricing == undefined) {
            return false;
        }
        return Pricing.containsBlockers(this.resultsByPosition[positionId].pricing)
            || Pricing.containsErrors(this.resultsByPosition[positionId].pricing);
    }
}
